<template>
    <div>
        <header>
            <template>
                <div class="left">
                    <img class="logo" src="@/assets/mobile/logo.png" v-if="!showBack" @click="$router.push('/m')" />
                    <i class="iconfont icon-back" v-if="showBack" @click="backUrl ? $router.push(backUrl) : $router.back()">返回</i>
                </div>
                <div class="mid">
                    <!-- <slot name="mid"></slot> -->
                </div>
                <i class="iconfont icon-menu" v-if="!showBack && showMenu" @click="showNavHandle"></i
            ></template>
        </header>
        <Nav ref="nav" @OnClose="showMenu = true" @OnShow="showMenu = false"></Nav>
    </div>
</template>
<script>
import Nav from "./nav";
export default {
    name: "mobile-header",
    components: { Nav },
    props: {
        showBack: {
            type: Boolean,
            default: false,
        },
        backUrl: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            showMenu: true,
        };
    },
    methods: {
        showNavHandle() {
            this.$refs["nav"].showNav();
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
header {
    background: #fff;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99999;
}
.icon-back {
    font-size: 1.6rem;
}
header .icon-menu {
    padding: 10px 15px;
    font-size: 2.3rem;
}
.logo {
    height: 60%;
}
header .left {
    min-width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
}
header .mid {
    font-size: 18px;
    font-weight: 600;
}
</style>
