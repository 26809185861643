<template>
    <div class="pc-video-wrap">
        <div>
            <video class="video-js" ref="videoPlayer" :src="src" autoplay muted @ended="$emit('Close')"></video>
            <div class="close-btn" v-if="!close" @click="CloseHandle">
                进入浙文
            </div>
        </div>
    </div>
</template>
<script>
// import videojs from "video.js";
export default {
    name: "pc-home-video",
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    components: {},
    data() {
        return {
            close: false,
            videoOptions: {
                autoplay: true,
                controls: true,
                liveui: false,
                controlBar: {
                    // liveDisplay: true,
                    progressControl: true,
                    remainingTimeDisplay: false,
                    pictureInPictureToggle: false,
                },
                sources: [
                    {
                        src: this.src,
                        type: "video/mp4",
                    },
                ],
            },
        };
    },
    mounted() {
        // this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {});
    },
    methods: {
        CloseHandle() {
            this.$refs["videoPlayer"].pause();
            this.$emit("Close");
            this.close = true;
        },
    },
};
</script>
<style scoped>
/* @import url("../../../node_modules/video.js/dist/video-js.min.css"); */
.pc-video-wrap {
    width: 100%;
    height: 100%;
    background: #fff;
    /* animation: showVideo 0.3s ease-in forwards;
    transform: scale(0.3); */
}
.pc-video-wrap > div {
    position: relative;
    width: 100%;
    height: 100%;
}
.close-btn {
    font-size: 2rem;
    color: #222;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999;
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: 1px #222 solid;
}
@keyframes showVideo {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.video-js {
    object-fit: cover;
}
</style>
