<template>
    <div class="container notice">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/invest/p1_bg.jpg"> </Row>
        <Row bgColor="#f5f6f8">
            <div class="pos2">
                <div style="width:100%; height:100px;"></div>
                <InvestRow bg="#f5f6f8" :showMore="false" margin="70px 100px">
                    <template v-slot:title><span class="back" @click="$router.back(-1)">投资者关系</span> <i class="iconfont icon-next" style="font-size:2rem; line-height:2rem; width:3rem;text-indent:0;margin-right:5px;"></i>公司公告</template>
                    <div class="content" v-html="notice"></div>
                </InvestRow>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
import InvestRow from "@/components/pc/InvestRow.vue";
export default {
    name: "pc-invest-notice",
    components: { Nav, Footer, Row, InvestRow },
    data() {
        return {
            notice: "",
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData(1);
    },
    methods: {
        LoadData() {
            this.$http.get(`/api/invest/notice`).then((res) => {
                this.notice = res.data.notice;
            });
        },
    },
};
</script>
<style scoped>
.back {
    cursor: pointer;
}
.back:hover {
    text-decoration: underline;
}
.content {
    font-size: 1.5rem;
    line-height: 2.4rem;
}
</style>
