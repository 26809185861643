<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:80px;"></div>
        <Row height="720" bg="assets/pc/service/content/p1_bg.jpg"> </Row>
        <Row height="900" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/service/content/pos2_banner.png" />
                <h1>激活算力潜能&nbsp;&nbsp;&nbsp;筑造数字经济新基石</h1>
                <p>浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座，充分发挥算力生产力在文化数字化领域的重要作用。</p>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-service-digital_culture",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
/* .pos2 p {
    font-size: 1.8rem;
    line-height: 4.5rem;
} */
@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 100px;
        /* width: calc(600px * var(--scale)); */
        height: 200px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 100px 0 60px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: left;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 130px;
        /* width: 600px; */
        height: 250px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 140px 0 80px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: center;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
