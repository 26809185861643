module.exports = [{
        id: 1,
        name: "广告"
    }, {
        id: 2,
        name: "宣传片"
    }, {
        id: 3,
        name: "动画"
    },
    // {
    //     id: 4,
    //     name: "三维"
    // },
    {
        id: 5,
        name: "短视频"
    }, {
        id: 6,
        name: "纪录片"
    }, {
        id: 7,
        name: "MV"
    }, {
        id: 8,
        name: "活动"
    }
];