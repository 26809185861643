<template>
    <div class="container">
        <Header></Header>
        <Row bg="assets/mobile/invest/pos1-bg.jpg"> </Row>
        <div class="pos2">
            <InvestRow v-if="report && report.notice" url="/invest/notice">
                <template v-slot:title>公司公告</template>
                <div class="notice" v-html="report.notice.desc"></div>
                <router-link to="/m/invest/notice" class="more-link">查看更多</router-link>
            </InvestRow>
            <InvestRow v-if="report && report.periodicReport">
                <template v-slot:title>定期报告</template>
                <ul class="list">
                    <li v-for="(item, idx) in report.periodicReport" :key="idx">
                        <a :href="resourceBaseUrl + item.pdf" target="_blank">
                            <div>{{ item.title }}</div>
                            <span>{{ item.date }} </span>
                        </a>
                    </li>
                </ul>
                <router-link to="/m/invest/list?t=1" class="more-link">查看更多</router-link>
            </InvestRow>
            <InvestRow v-if="report && report.researchReport">
                <template v-slot:title>公司研报</template>
                <ul class="list">
                    <li v-for="(item, idx) in report.researchReport" :key="idx">
                        <a :href="resourceBaseUrl + item.pdf" target="_blank"
                            ><div>{{ item.title }}</div>
                            <span>{{ item.date }}</span></a
                        >
                    </li>
                </ul>
                <router-link to="/m/invest/list?t=2" class="more-link">查看更多</router-link>
            </InvestRow>
            <InvestRow :showMore="false">
                <template v-slot:title>股票行情</template>
                <div class="kline">
                    <KLine></KLine>
                </div>
            </InvestRow>
            <InvestRow :showMore="false" margin="50px 60px">
                <template v-slot:title>投资者联系方式</template>
                <ul class="contact">
                    <li>
                        <div>证券部联系电话<br />010-87835799</div>
                    </li>
                    <li>
                        <div>证券部电子邮箱<br />info@zwhlgroup.com</div>
                    </li>
                    <li>
                        <a href="http://sns.sseinfo.com/company.do?uid=986" target="_blank"
                            ><div>投资者互动<br />在线互动</div></a
                        >
                    </li>
                </ul>
            </InvestRow>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/mobile/header.vue";
import Footer from "@/components/mobile/footer.vue";
import Row from "@/components/mobile/Row.vue";
import InvestRow from "@/components/mobile/InvestRow.vue";
import KLine from "@/components/KLine.vue";
import settings from "@/settings";
export default {
    name: "mobile-invest",
    components: { Footer, Row, InvestRow, KLine, Header },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            report: null,
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$http.get("/api/invest/newest").then((res) => {
                this.report = res.data;
            });
        },
    },
};
</script>
<style scoped>
.container {
    background: #f5f6f8;
}
.more-link {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px 0;
}
.contact {
    margin: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact li {
    width: 32%;
    height: 70px;
    background: #1a3a85;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: #fff;
    line-height: 2.2rem;
    font-weight: normal;
}
.contact li div {
    transform: scale(0.8);
}
.kline {
    height: 300px;
}
.list {
    width: 100%;
}
.list li {
    font-size: 1.5rem;
    font-weight: normal;
    color: #4d4d4d;
    margin: 20px 15px;
    border-bottom: 1px #eaeaea solid;
    padding-bottom: 20px;
}
.list li a {
    display: inline-block;
    width: 100%;
    text-align: left;
}
.list li a div {
    margin: 10 0;
    color: #666666;
    font-size: 1.2rem;
}
.list li a span {
    width: 100%;
    display: inline-block;
    font-weight: normal;
    font-size: 1.2rem;
    color: #666666;
}
.notice {
    margin: 15px 15px;
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: left;
    color: #666666;
}
.pos2 {
    margin: 40px 0 30px 0;
    text-align: center;
}
.pos2 img {
    margin-top: 130px;
}
.pos2 h1 {
    font-size: 3rem;
    margin: 200px 0 80px 0;
}
.pos2 p {
    font-size: 2.3rem;
    line-height: 5rem;
}
</style>
