<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:80px;"></div>
        <Row height="720" bg="assets/pc/about/brand/bg4.jpg"> </Row>
        <Row height="800" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/about/brand/content-ylmf.png" />
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-about-ylmf",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
.pos2 p {
    font-size: 1.8rem;
    line-height: 4.5rem;
}
@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 170px;
        width: calc(450px * var(--scale));
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 80px 0 60px 0;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 200px;
        width: 450px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 140px 0 80px 0;
    }
}
</style>
