<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:80px;"></div>
        <Row height="720" bg="assets/pc/contact/p1_bg.jpg"> </Row>
        <div class="tel">
            <ul>
                <li>
                    <div>商务合作</div>
                    <p>010-87835788</p>
                    <p>INFO@ZWHLGROUP.COM</p>
                </li>
                <li>
                    <div>媒体垂询</div>
                    <p>010-87835799</p>
                    <p>INFO@ZWHLGROUP.COM</p>
                </li>
                <li>
                    <div>投资者关系</div>
                    <p>010-87835799</p>
                    <p>INFO@ZWHLGROUP.COM</p>
                </li>
                <li>
                    <div>人才招聘</div>
                    <p>010-87835788</p>
                    <p>INFO@ZWHLGROUP.COM</p>
                </li>
            </ul>
        </div>
        <div class="map">
            <img :src="require('@/assets/pc/contact/map' + map + '.jpg')" />
            <div class="tab">
                <ul>
                    <li :class="map == 0 ? 'active' : ''" @click="map = 0">北京</li>
                    <li :class="map == 1 ? 'active' : ''" @click="map = 1">杭州</li>
                    <li :class="map == 2 ? 'active' : ''" @click="map = 2">上海</li>
                    <li :class="map == 3 ? 'active' : ''" @click="map = 3">广州</li>
                    <li :class="map == 4 ? 'active' : ''" @click="map = 4">重庆</li>
                </ul>
                <div>
                    <img :src="require('@/assets/pc/contact/icon' + map + '.png')" />
                </div>
                <p v-html="contact[map]"></p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-contact",
    components: { Nav, Footer, Row },
    data() {
        return {
            titles: ["", "定期报告", "公司研报"],
            map: 0,
            contact: ["北京市朝阳区高碑店乡高井文化园路8号<br>东亿国际传媒产业园区二期元君书苑F1号楼<br>010-87835788", "浙江省杭州市拱墅区天水街道体育场路<br>370号浙江文化大厦17层<br>0571-81028147", "上海市长宁区威宁路369号6楼<br>021-23560062", "广州市天河区临江大道5号2601-2603房、<br>2608房<br>020-38686413", "重庆市江北区融景中心B座12-1<br>023-67663467"],
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        OnChangePageHandle() {},
    },
};
</script>
<style scoped>
.map .tab ul {
    margin: 20px 20px;
    display: flex;
    align-items: center;
}

.map .tab > div {
    margin: 30px 70px;
    text-align: center;
}
.map .tab > div img {
    height: 150px;
}

@media only screen and (max-width: 2048px) {
    .map .tab ul li.active {
        border-bottom: 2px #f9b924 solid;
    }
    .map .tab {
        width: 500px;
        height: 370px;
        position: absolute;
        right: 20%;
        top: 50%;
        transform: translateX(-50%);
        transform: translateY(-50%);
        background: #fff;
        box-shadow: #777 0 0 10px;
    }
    .map {
        width: 100%;
        min-width: calc(1920px * var(--scale));
        position: relative;
    }
    .tel {
        width: 100%;
        text-align: center;
        min-width: calc(1920px * var(--scale));
        background: #f9b924;
    }
    .tel ul {
        width: calc(1600px * var(--scale));
        margin: 0 auto;
        height: calc(180px * var(--scale));
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .tel ul li {
        font-size: 1.4rem;
        text-align: left;
    }
    .tel ul li div {
        font-size: 1.8rem;
        font-weight: normal;
        border-left: 1px #222 solid;
        line-height: 2rem;
        padding-left: 20px;
        width: 100%;
        margin-bottom: 10px;
        display: inline-block;
    }
    .tel ul li p {
        text-indent: 21px;
        margin: 10px 0 0 0;
    }
    .map .tab p {
        font-size: 1.4rem;
        color: #333;
        line-height: 3rem;
        text-align: center;
        margin-top: 40px 40px 0 40px;
    }
    .map .tab ul li {
        font-size: 1.4rem;
        cursor: pointer;
        flex: 1;
        margin: 0 5px;
        border-bottom: 2px #cacaca solid;
        text-align: center;
        padding-bottom: 5px;
    }
}

@media only screen and (min-width: 2049px) {
    .map .tab ul li.active {
        border-bottom: 3px #f9b924 solid;
    }
    .map .tab {
        width: 500px;
        height: 370px;
        position: absolute;
        right: 20%;
        top: 50%;
        transform: translateX(-50%);
        transform: translateY(-50%);
        background: #fff;
        box-shadow: #777 0 0 10px;
    }
    .map {
        width: 100%;
        min-width: 1920px;
        position: relative;
    }
    .tel {
        width: 100%;
        text-align: center;
        min-width: 1920px;
        background: #f9b924;
    }
    .tel ul {
        width: 1600px;
        margin: 0 auto;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .tel ul li {
        font-size: 1.4rem;
        text-align: left;
    }
    .tel ul li div {
        font-size: 2rem;
        font-weight: normal;
        border-left: 1px #222 solid;
        line-height: 2rem;
        padding-left: 20px;
        width: 100%;
        margin-bottom: 10px;
        display: inline-block;
    }
    .tel ul li p {
        text-indent: 21px;
        margin: 10px 0 0 0;
    }
    .map .tab p {
        font-size: 1.5rem;
        color: #333;
        line-height: 3rem;
        text-align: center;
        margin-top: 40px 40px 0 40px;
    }
    .map .tab ul li {
        font-size: 1.5rem;
        cursor: pointer;
        flex: 1;
        margin: 0 5px;
        border-bottom: 3px #cacaca solid;
        text-align: center;
        padding-bottom: 5px;
    }
}
</style>
