<template>
    <Row height="720" bg="assets/pc/news/p1_bg.jpg" bgPos="top center" styles="overflow: hidden;">
        <div class="pos1">
            <img src="@/assets/pc/home/hand1.png" class="hand hand1" @animationend="pos6ShowAnimEnd = true" />
            <img src="@/assets/pc/home/hand2.png" class="hand hand2" />
            <img src="@/assets/pc/home/hand3.png" class="hand hand3" />
            <img src="@/assets/pc/home/light.png" class="hand light" :class="pos6ShowAnimEnd ? 'end' : ''" />
            <div class="desc">
                <img src="@/assets/pc/news/pos1_banner.png" />
            </div>
        </div>
    </Row>
</template>
<script>
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-news-top",
    components: { Row },
    data() {
        return {
            pos6ShowAnimEnd: false,
        };
    },
    activated() {
        this.pos6ShowAnimEnd = false;
    },
};
</script>
<style scoped>
.pos1 {
    position: relative;
    height: 100%;
    width: 100%;
}
.hands {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.pos1 .end {
    animation: hand_end_anim 0.4s ease-in forwards;
}

@media only screen and (max-width: 2048px) {
    .pos1 .desc {
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;
        width: calc(906px * var(--scale));
        text-align: right;
        background: url("~@/assets/pc/news/b.png");
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
    }
    .pos1 .desc img {
        width: 70%;
    }
    .pos1 .hand {
        position: absolute;
    }
    .pos1 .hand1 {
        width: calc(944px * 0.64 * var(--scale));
        left: calc(-560px * 0.64 * var(--scale));
        top: calc(40px * 0.64 * var(--scale));
        transform: translate(-200px, -150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .hand2 {
        width: calc(693px * 0.64 * var(--scale));
        left: calc(-300px * 0.64 * var(--scale));
        bottom: calc(-200px * 0.64 * var(--scale));
        transform: translate(-200px, 150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .hand3 {
        width: calc(521px * 0.64 * var(--scale));
        left: calc(410px * 0.64 * var(--scale));
        bottom: calc(-215px * 0.64 * var(--scale));
        transform: translate(200px, 150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .light {
        width: calc(531px * 0.64 * var(--scale));
        position: absolute;
        left: calc(140px * 0.64 * var(--scale));
        top: calc(475px * 0.64 * var(--scale));
        opacity: 0;
        transform: scale(0.3);
        transform: rotate(15deg);
    }
}

@media only screen and (min-width: 2049px) {
    .pos1 .desc {
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;
        width: 906px;
        text-align: right;
        background: url("~@/assets/pc/news/b.png");
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
    }
    .pos1 .desc img {
        width: 70%;
    }
    .pos1 .hand {
        position: absolute;
    }
    .pos1 .hand1 {
        width: calc(944px * 0.64);
        left: calc(-560px * 0.64);
        top: calc(40px * 0.64);
        transform: translate(-200px, -150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .hand2 {
        width: calc(693px * 0.64);
        left: calc(-300px * 0.64);
        bottom: calc(-200px * 0.64);
        transform: translate(-200px, 150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .hand3 {
        width: calc(521px * 0.64);
        left: calc(410px * 0.64);
        bottom: calc(-215px * 0.64);
        transform: translate(200px, 150px);
        animation: hand_anim 1s ease-in 0.5s forwards;
    }
    .pos1 .light {
        width: calc(531px * 0.64);
        position: absolute;
        left: calc(140px * 0.64);
        top: calc(475px * 0.64);
        opacity: 0;
        transform: scale(0.3);
        transform: rotate(15deg);
    }
}

@keyframes hand_anim {
    100% {
        transform: translate(0, 0);
    }
}
@keyframes hand_end_anim {
    100% {
        transform: scale(1);
        opacity: 1;
        transform: rotate(0deg);
    }
}
</style>
