<template>
    <router-link :to="url" class="more-btn" :style="'background:' + bg + ';color:' + color + ';width:' + width + 'px;'">
        <slot></slot>
    </router-link>
</template>
<script>
export default {
    name: "more-btn",
    props: {
        bg: {
            type: String,
            default: "#fff",
        },
        color: {
            type: String,
            default: "#007aff",
        },
        url: {
            type: String,
            default: "",
        },
        width: {
            type: Number,
            default: 110,
        },
    },
};
</script>

<style scoped>
.more-btn {
    display: inline-flex;
    height: 32px;
    /* width: 110px; */
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: normal;
}
</style>
