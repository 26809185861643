<template>
    <div class="container home">
        <template v-if="!closedVideo && !$route.query.fm && !isMobile">
            <div class="video-mask" :class="closeVideoAnim ? 'close-video' : ''" @animationend="closedVideo = true">
                <HomeVideo
                    v-if="true"
                    :src="resourceBaseUrl + '/media/home.ai.mp4'"
                    @Close="
                        closeVideoAnim = true;
                        showHome = true;
                    "
                ></HomeVideo>
            </div>
        </template>

        <Nav></Nav>
        <Row height="1106" bg="assets/pc/home/p1_bg.jpg" class="pos1">
            <div class="pos1_anim_wrap">
                <div>
                    <img src="@/assets/pc/home/pos1/txt.png" class="txt" />
                    <!-- <img src="@/assets/pc/home/pos1/ball1.png" class="ball1" />
                    <img src="@/assets/pc/home/pos1/ball2.png" class="ball2" />
                    <img src="@/assets/pc/home/pos1/huan.png" class="huan" /> -->
                </div>
            </div>
            <div class="pos1_wrap">
                <div class="b1">
                    <!-- <img src="@/assets/pc/home/more-blue2.png" class="pc-more" @click="$router.push('/about')" /> -->
                    <div class="pc-blue-button" @click="$router.push('/about')" style="margin-right: 30px;">MORE</div>
                    <img src="@/assets/pc/home/video-btn-dark.png" @click="showVideo = true" />
                </div>
                <div class="b2">
                    <div class="slider">
                        <!-- <div class="num">0{{ pos1Idx + 1 }}/ 06</div>
                    <div class="pg">
                        <div class="line"></div>
                        <div class="bar" :style="pos1Idx == 0 ? ' background:#fff' : ''"></div>
                        <div class="bar" :style="pos1Idx == 1 ? ' background:#fff' : ''"></div>
                        <div class="bar" :style="pos1Idx == 2 ? ' background:#fff' : ''"></div>
                        <div class="bar" :style="pos1Idx == 3 ? ' background:#fff' : ''"></div>
                        <div class="bar" :style="pos1Idx == 4 ? ' background:#fff' : ''"></div>
                        <div class="bar" :style="pos1Idx == 5 ? ' background:#fff' : ''"></div>
                    </div> -->
                    </div>
                </div>
            </div>
        </Row>
        <Row height="1055" bgPos="bottom center" bg="assets/pc/home/p2_bg.jpg" :bgScale="0.61">
            <!-- <template v-slot:bg>
                <div class="pos2_bg"><div></div></div>
            </template> -->

            <div class="pos2 row-main">
                <div class="desc">
                    <div class="txt"><HomeText title="Z 是缜密" subTitle="思路周全 稳中求进" desc="AI+营销&nbsp;&nbsp;&nbsp;&nbsp;构建全链路智慧营销矩阵"></HomeText></div>
                    <div class="pc-blue-button pc-more" @click="$router.push('/service/marketing')">智慧营销</div>

                    <!-- <img src="@/assets/pc/home/btn-1.png" class="pc-more" @click="$router.push('/service/marketing')" /> -->
                </div>
                <!-- <div class="banner"><img src="@/assets/pc/home/pos2_banner.png" /></div> -->
            </div>
        </Row>

        <Row height="900" bgColor="#f3f3f3">
            <div class="pos3 row-main">
                <div class="desc">
                    <div class="desc-wrap">
                        <div class="txt">
                            <HomeText title="Z 是智慧" subTitle="洞见世事 顺势而为" desc="全方位构建品牌传播体系&nbsp;&nbsp;&nbsp;&nbsp;提升品牌商业价值" color="#333"></HomeText>
                        </div>
                        <!-- <img class="pc-more" src="@/assets/pc/home/btn-2.png" @click="$router.push('/service/spread')" /> -->
                        <div class="pc-blue-button pc-more" @click="$router.push('/service/spread')">公关传播与品牌管理</div>
                    </div>
                    <div><img class="banner" src="@/assets/pc/home/pos3_banner.jpg" /></div>
                </div>

                <!-- <ul class="category">
                    <li>品牌管理与咨询</li>
                    <li>公关传播</li>
                    <li>社会化媒体传播</li>
                    <li>事件营销</li>
                </ul> -->
            </div>
        </Row>

        <Row height="1650" bg="assets/pc/home/p4_bg.jpg" bgPos="bottom center" :bgScale="0.27">
            <div class="pos3 row-main">
                <div class="desc">
                    <div class="desc-wrap">
                        <div class="txt">
                            <HomeText title="Z 是卓越" subTitle="数字新境 文化新生" desc="AI与文化碰撞&nbsp;&nbsp;&nbsp;&nbsp;引领数字文化新生态" color="#333"></HomeText>
                        </div>
                        <!-- <img class="pc-more" src="@/assets/pc/home/btn-7.png" @click="$router.push('/service/digital_culture')" /> -->
                        <div class="pc-blue-button pc-more" @click="$router.push('/service/digital_culture')">数字文化</div>
                    </div>
                    <div><img class="banner" src="@/assets/pc/home/pos7_banner.jpg" /></div>
                </div>
            </div>
            <div class="pos4 row-main" style="height:auto;">
                <div class="desc">
                    <div class="txt">
                        <HomeText title="Z 是整合" subTitle=" 运筹帷幄 调度有方" desc="激活算力潜能&nbsp;&nbsp;&nbsp;&nbsp;筑造数字经济基石" color="#333"></HomeText>
                    </div>
                </div>
                <!-- <img class="pc-more" src="@/assets/pc/home/btn-3.png" @click="$router.push('/service/content')" /> -->
                <div class="pc-blue-button pc-more" @click="$router.push('/service/content')">算力运营</div>
                <!-- <div class="banner"><img src="@/assets/pc/home/pos4_banner.png" /></div> -->
            </div>
        </Row>
        <Row height="678" bgColor="#f3f3f3">
            <div class="pos5 row-main">
                <div class="desc">
                    <div class="desc-wrap">
                        <div class="txt"><HomeText title="Z 是再启程" subTitle="文创新机 数字未来" desc="多元业态布局&nbsp;&nbsp;&nbsp;&nbsp;开辟文化产业新篇章" color="#333"></HomeText></div>
                        <!-- <img class="pc-more" src="@/assets/pc/home/btn-4.png" @click="$router.push('/service/innovation')" /> -->
                        <div class="pc-blue-button pc-more" @click="$router.push('/service/innovation')">文化创新</div>
                    </div>
                    <div class="swiper_container">
                        <div class="wrap">
                            <swiper :options="pos5SwiperOption" ref="pos5Swiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" @slideChangeTransitionStart="pos5SwiperChanged">
                                <swiper-slide>
                                    <div class="slide-item">
                                        <img src="@/assets/pc/home/pos5_s1.png" />
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-item">
                                        <img src="@/assets/pc/home/pos5_s2.png" />
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="slide-item">
                                        <img src="@/assets/pc/home/pos5_s3.png" />
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <ul>
                            <li @click="pos5ClickSwitch(0)" :class="pos5SwiperIdx == 0 ? 'active' : ''">
                                01
                            </li>
                            <li @click="pos5ClickSwitch(1)" :class="pos5SwiperIdx == 1 ? 'active' : ''">
                                02
                            </li>
                            <li @click="pos5ClickSwitch(2)" :class="pos5SwiperIdx == 2 ? 'active' : ''">
                                03
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="banner"><img src="@/assets/pc/home/pos5_banner.png" /></div> -->
            </div>
        </Row>
        <!-- <div style="width:100%;height:200px;"></div> -->
        <Row height="1105" bg="assets/pc/home/p6_bg.jpg" bgPos="top center" styles="overflow: hidden;">
            <div class="pos6 row-main" ref="pos6">
                <img src="@/assets/pc/home/hand1.png" class="hand hand1" :class="pos6ShowAnim ? 'started' : ''" @animationend="pos6ShowAnimEnd = true" />
                <img src="@/assets/pc/home/hand2.png" class="hand hand2" :class="pos6ShowAnim ? 'started' : ''" />
                <img src="@/assets/pc/home/hand3.png" class="hand hand3" :class="pos6ShowAnim ? 'started' : ''" />
                <img src="@/assets/pc/home/light.png" class="hand light" :class="pos6ShowAnimEnd ? 'end' : ''" />
                <div class="desc">
                    <div class="txt" style="width: 100%;">
                        <HomeText title="行业洞察" subTitle="Industry Insights" desc="洞悉行业前沿趋势&nbsp;&nbsp;&nbsp;&nbsp;探索行业发展新机遇" color="#333" :border="false"></HomeText>
                    </div>
                    <!-- <img class="pc-more" src="@/assets/pc/home/more-blue.png" @click="$router.push('/news')" /> -->
                    <div class="pc-blue-button pc-more" @click="$router.push('/news')">MORE</div>
                </div>
                <div class="news" v-if="news && news.length > 0">
                    <!-- <swiper :options="newsSwiperOption" ref="newsSwiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" v-if="news && news.length > 0" @slideChangeTransitionStart="newsSwiperChanged">
                        <template v-for="page in Math.ceil(news.length / 3)">
                            <swiper-slide :key="page">
                                <ul class="slide-item">
                                    <template v-for="idx in 3">
                                        <li :key="idx">
                                            <template v-if="(page - 1) * 3 + idx - 1 < news.length">
                                                <router-link :to="'/news/detail?id=' + news[(page - 1) * 3 + idx - 1].id">
                                                    <div class="cover">
                                                        <img :data-idx="(page - 1) * 3 + idx - 1" :src="resourceBaseUrl + news[(page - 1) * 3 + idx - 1].cover + '?x-oss-process=image/resize,m_fill,w_340,h_474'" />
                                                        <div class="title">
                                                            {{ news[(page - 1) * 3 + idx - 1].title }}
                                                        </div>
                                                    </div>

                                                    <div class="date">{{ news[(page - 1) * 3 + idx - 1].date }}</div>
                                                </router-link>
                                            </template>
                                        </li>
                                    </template>
                                </ul>
                            </swiper-slide>
                        </template>
                    </swiper> -->
                    <span class="iconfont icon-back pre" @click="newSwiperPrev"></span>
                    <carousel-3d ref="mycarousel" :perspective="0" :autoplay="false" :startIndex="0" :width="650" :height="350" :space="300" :border="0" :display="3" :controlsVisible="false" controlsNextHtml="" controlsPrevHtml="" @after-slide-change="newsSwiperChanged">
                        <slide v-for="(item, idx) in news" :key="idx" :index="idx">
                            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                                <router-link :to="'/news/detail?id=' + item.id">
                                    <div class="swiper-item news-item" :class="{ current: isCurrent, onLeft: leftIndex >= 0, onRight: rightIndex >= 0 }">
                                        <img :data-index="index" :data-is-current="isCurrent" :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_650,h_270'" />
                                        <h3>{{ item.title }}</h3>
                                        <span>{{ item.date }}</span>
                                    </div>
                                </router-link>
                            </template>
                        </slide>
                    </carousel-3d>
                    <span class="iconfont icon-back next" @click="newSwiperNext"></span>
                    <div class="pager">
                        <!-- <div>
                            
                           
                        </div> -->
                        <!-- <div>0{{ (newsSwiperIdx >= 0 ? newsSwiperIdx : 0) + 1 }} — 0{{ Math.ceil(news.length / 3) }}</div> -->
                        <div class="pager-wrap">
                            <div v-for="(item, idx) in news" :key="item.id" :class="{ active: idx == newsSwiperIdx }"></div>
                        </div>
                    </div>
                </div>

                <!-- <ul class="pager">
                    <li v-for="(item, idx) in leader" :key="idx" :class="idx == leaderSwiperIdx ? 'active' : ''"></li>
                </ul> -->
            </div>
        </Row>
        <Row height="1350" bg="assets/pc/home/p7_bg.jpg" bgPos="top center">
            <div class="pos7 row-main">
                <div class="desc">
                    <div class="desc-wrap">
                        <div>
                            <h1>关于浙文</h1>
                            <img class="icon" src="@/assets/pc/home/about.png" />
                        </div>
                        <p>
                            浙文互联集团股份有限公司于2004年在上海证券交易所上市，股票代码600986.SH，2021年浙江省文化产业投资集团有限公司成为控股股东，公司正式更名为浙文互联集团股份有限公司。浙文互联连续数年营收逾百亿，位居行业三甲之列，业务涉及汽车营销、精准营销、直播电商与跨境电商、出海业务、智算业务、智慧内容生态、数字文化创新等多个领域。<br />
                            在数字营销领域，浙文互联长期稳居汽车营销行业NO.1，全面推进“AI+营销”智慧营销业务实践，深入品牌战略咨询与管理、营销与传播策略制定、执行，媒介投放、公关策略制定与传播管理、品牌与领导人声誉管理、精准营销与效果投放、直播电商与跨境电商、社群营销、体验营销等多个核心板块；在数字文化领域，浙文互联积极响应国家文化数字化战略，持续建设“AI+文化”，探索技术路线和实施路径，推进垂直领域AIGC应用开发、星声代数字人智枢平台、轻量数字人应用—数眸、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断积累文化领域数字资产，构建AI智慧内容生态；在算力运营领域，浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座与汽车品牌智算中心，充分发挥算力生产力的重要作用。<br />
                            目前，浙文互联旗下拥有百孚思、浙文天杰、派瑞威行、同立传播、智阅网络、华邑、好奇莫斯、浙文智算、浙文米塔、浙安果合、开心宇宙等多个子品牌。战略投资LGD电竞俱乐部、豆神教育、数文交所等数字文化创新领域。职场覆盖北京、杭州、上海、广州、重庆，全国员工规模逾千人。
                        </p>
                        <!-- <img class="pc-more" src="@/assets/pc/home/more-blue2.png" @click="$router.push('/about')" /> -->
                        <section class="pc-blue-button pc-more" @click="$router.push('/about')">MORE</section>
                    </div>
                </div>
                <img class="banner1" src="@/assets/pc/home/pos7_banner1.png" />
                <div class="brand">
                    <img class="banner2" src="@/assets/pc/home/pos7_banner2.png" />
                    <ul>
                        <li>
                            <a target="_blank" href="http://www.iforce-media.com/"><img src="@/assets/pc/home/brand/iforce.png"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="http://www.amaxz.com.cn/"><img src="@/assets/pc/home/brand/amaxz.png" style="height:22px;"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="http://www.aspiration-cn.com"><img src="@/assets/pc/home/brand/pairui.png"/></a>
                        </li>

                        <li>
                            <router-link to="/about/tongli"><img src="@/assets/pc/home/brand/tongli.png" /> </router-link>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.qctt.cn"><img src="@/assets/pc/home/brand/zywl.png"/></a>
                        </li>
                        <li>
                            <router-link to="/about/huayi"><img src="@/assets/pc/home/brand/huayi.png" style="height:30px;max-height:30px;"/></router-link>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.hqmoss.com"><img src="@/assets/pc/home/brand/hqms.png" style="height:34px;max-height:34px;"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/home/brand/zwzs.png" style="height:37px;max-height:37px;"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://mita.com"><img src="@/assets/pc/home/brand/mt.png"/></a>
                        </li>

                        <li>
                            <a target="_blank" href="https://guohekeji.xinpianchang.com"><img src="@/assets/pc/home/brand/zagh.png" style="height:20px;max-height:20px;"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/home/brand/ss.png" style="height:37px;max-height:37px;"/></a>
                        </li>

                        <li>
                            <a target="_blank"><img src="@/assets/pc/home/brand/lgd.png" style="height:37px;max-height:37px;"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/home/brand/kxyz.png" style="height:18px;max-height:18px;"/></a>
                        </li>
                    </ul>
                </div>
            </div>
        </Row>

        <Footer></Footer>

        <Video :src="resourceBaseUrl + '/media/home.ai.mp4'" v-if="showVideo" @closed="showVideo = false"></Video>
    </div>
</template>

<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
import Row from "@/components/pc/Row.vue";
import ScrollMagic from "scrollmagic";
import Video from "@/components/pc/Video.vue";
import HomeVideo from "@/components/pc/HomeVideo.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import HomeText from "@/components/pc/HomeText.vue";
export default {
    name: "PC-Home",
    components: { Nav, Footer, Row, Video, HomeVideo, Carousel3d, Slide, HomeText },
    data() {
        return {
            isMobile: false,
            showHome: false,
            closeVideoAnim: false,
            closedVideo: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            pos1Idx: 0,
            pos1Page: 1,
            pos5SwiperIdx: 0,
            pos5SwiperOption: {
                width: 458,
                height: 263,
                autoplay: true,
                direction: "vertical",
                loop: true,
            },
            pos6ShowAnim: false,
            pos6ShowAnimEnd: false,
            news: [],
            newsSwiperOption: {
                width: 1035,
                height: 600,
                // autoplay: true,
                // direction: "vertical",
                loop: true,
            },
            newsSwiperIdx: 0,
            showVideo: false,
            pos6OffsetTop: 4920,
        };
    },
    mounted() {
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
            // alert("移动端");
            this.isMobile = true;
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.$nextTick(() => {
            this.InitScrollMagic();
        });
        this.LoadNews();
        var self = this;
        var mediaQuery = window.matchMedia("only screen and (max-width: 2048px)");
        if (mediaQuery.matches) {
            self.pos6OffsetTop = 3750;
        }

        mediaQuery.addEventListener("change", function(e) {
            self.pos6OffsetTop = e.matches ? 3750 : 4920;
        });

        // window.onscroll = function() {
        //     console.log(document.documentElement.scrollTop, self.$refs.pos6.offsetTop);
        // };
    },
    methods: {
        newSwiperPrev() {
            // const swiper = this.$refs.newsSwiper.$swiper;
            // if (swiper.realIndex <= 0) return;
            // swiper.slideTo(swiper.realIndex, 500, false);
            // this.newsSwiperIdx--;
            var idx = this.newsSwiperIdx;
            if (idx <= 0) idx = this.news.length - 1;
            else idx--;

            this.$refs.mycarousel.goSlide(idx);
        },
        newSwiperNext() {
            // var swiper = this.$refs.newsSwiper.$swiper;
            // var pages = swiper.slides.length / 2;
            // if (swiper.realIndex >= pages - 1) return;
            // swiper.slideTo(swiper.realIndex + 2, 500, false);
            // this.newsSwiperIdx++;

            var idx = this.newsSwiperIdx;
            if (idx >= this.news.length - 1) idx = 0;
            else idx++;

            this.$refs.mycarousel.goSlide(idx);
        },

        newsSwiperChanged(e) {
            this.newsSwiperIdx = e; //this.$refs["newsSwiper"].$swiper.realIndex;
        },
        LoadNews() {
            this.$http.get("/api/news/home").then((res) => {
                this.news = res.data;
            });
        },
        InitScrollMagic() {
            var controller = new ScrollMagic.Controller();
            this.controller = controller;
            if (!this.$refs.pos6) return;
            var offsetTop = this.pos6OffsetTop; //this.$refs.pos6.offsetTop;
            var windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
            if (windowWidth < 1900) offsetTop = offsetTop * 0.8;
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            new ScrollMagic.Scene({
                // triggerElement: ".pos6",
                offset: offsetTop - windowHeight / 2,
                triggerHook: 0.5,
                duration: 400,
            })
                .addTo(controller)
                .on("start", () => {
                    // console.log("pos6 start");
                    this.pos6ShowAnim = true;
                });
        },
        pos5ClickSwitch(idx) {
            this.$refs.pos5Swiper.$swiper.slideTo(idx + 1, 500, false);
            this.pos5SwiperIdx = idx;
        },
        pos5SwiperChanged() {
            this.pos5SwiperIdx = this.$refs.pos5Swiper.$swiper.realIndex;
        },
        Pos1HoverHandle(idx) {
            this.pos1Idx = idx;
        },
        PageHandle() {
            if (this.pos1Page == 1) this.pos1Page = 2;
            else this.pos1Page = 1;
        },
        ChanePosHandle(pos) {
            document.querySelector(`.${pos}`).scrollIntoView();
        },
    },
};
</script>
<style scoped>
.pos6 .news {
    position: relative;
}

.pos6 .news .icon-back {
    position: absolute;
    top: 50%;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: -15px;
}

.pos6 .news .icon-back:hover {
    background: #063a8a;
    color: #fff;
}

.pos6 .pager {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pos6 .pager-wrap {
    width: 500px;
    background: #fff;
    height: 2px;
    display: flex;
}

.pos6 .pager-wrap div {
    flex: 1;
    height: 6px;
    margin-top: -2px;
}

.pos6 .pager-wrap .active {
    background: #063a8a;
}

.pos6 .swiper {
    width: 960px;
}

.news-item {
    display: flex;
    flex-direction: column;
    background: #fff;
    height: 100%;
    width: 100%;
}

.news-item img {
    width: 650px;
    height: 270px;
}

.news-item h3 {
    font-size: 1.5rem;
    margin: 15px 15px;
    color: #222;
}

.news-item span {
    font-size: 1.2rem;
    margin: 0 15px;
    color: #888;
}

.video-mask {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background: #222;
    z-index: 99999;
}

.close-video {
    animation: close-video 0.8s linear forwards;
}

@keyframes close-video {
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.pos6 {
    position: relative;
    height: 100%;
    width: 100%;
}

.pos6 .started {
    animation: hand_anim 1s ease-in forwards;
}

.pos6 .end {
    animation: hand_end_anim 0.4s ease-in forwards;
}

@keyframes hand_anim {
    100% {
        transform: translate(0, 0);
    }
}

@keyframes hand_end_anim {
    100% {
        transform: scale(1);
        opacity: 1;
        transform: rotate(0deg);
    }
}

.pos5 {
    position: relative;
    height: 100%;
    width: 100%;
}

.pos4 {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}

.pos2 {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.b2 .swiper .wrap .page-wrap .page {
    flex: 1;
}

.b2 .swiper .wrap .page-wrap .page:hover img {
    height: 65%;
    transition: all 0.4s;
}

@keyframes pos1_move {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc((100% / 4) * 2 * -1));
    }
}

@keyframes pos1_pre {
    0% {
        transform: translateX(calc((100% / 4) * 2 * -1));
    }

    100% {
        transform: translateX(0);
    }
}

.b2 .swiper .wrap .page {
    width: calc(100% / 4);
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 2048px) {
    .pos6 .news .pre {
        left: -60px;
        z-index: 9999;
    }

    .pos6 .news .next {
        right: -60px;
        z-index: 9999;
        transform: rotate(180deg);
    }

    .pos7 .brand {
        position: relative;
    }

    .pos7 .brand ul {
        position: absolute;
        left: 0;
        bottom: -12%;
        right: 0;
        display: flex;
        align-items: center;

        flex-wrap: wrap;
    }

    .pos7 .brand ul li {
        margin-bottom: 30px;
    }

    .pos7 .brand ul img {
        max-height: 25px;
        margin-right: 60px;
    }

    .pos7 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: 50px;
    }

    .pos7 .desc .desc-wrap {
        flex: 1;
    }
    .pos7 .desc .desc-wrap > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .pos7 .desc h1 {
        font-size: 4rem;
        color: #222;
        /* margin: 0 70px 20px 0; */
        font-weight: 500;
    }

    .pos7 .desc p {
        font-weight: normal;
        font-size: 1.5rem;
        color: #838587;
        margin: 10px 0 10px 0;
        line-height: 30px;
        text-align: justify;
    }

    .pos7 .desc .pc-more {
        cursor: pointer;
        margin-top: 25px;
    }

    .pos7 .banner1 {
        margin-top: 50px;
        width: calc(1163px * var(--scale));
    }

    .pos7 .banner2 {
        margin-top: 50px;
        width: calc(1538px * var(--scale));
    }

    .pos7 .icon {
        width: calc(450px * var(--scale));
    }

    .hands {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .pos6 .hand {
        position: absolute;
    }

    .pos6 .hand1 {
        left: calc(-690px * var(--scale));
        top: calc(40px * var(--scale));
        transform: translate(-200px, -150px);
        width: calc(944px * var(--scale));
    }

    .pos6 .hand2 {
        left: calc(-420px * var(--scale));
        bottom: calc(-200px * var(--scale));
        width: calc(693px * var(--scale));
        transform: translate(-200px, 150px);
    }

    .pos6 .hand3 {
        left: calc(280px * var(--scale));
        bottom: calc(-215px * var(--scale));
        width: calc(521px * var(--scale));
        transform: translate(200px, 150px);
    }

    .pos6 .light {
        position: absolute;
        left: calc(10px * var(--scale));
        top: calc(465px * var(--scale));
        opacity: 0;
        transform: scale(0.3);
        transform: rotate(15deg);
        width: calc(531px * var(--scale));
    }

    .pos6 .desc {
        width: 600px;
        margin-top: 80px;
        position: absolute;
        left: 0;
        top: 0;
    }

    /* .pos6 .pager {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: calc(50px * var(--scale));
        margin-left: 20px;
    }
    .pos6 .pager div:first-child span {
        font-size: 1.5rem;
        color: #063a8a;
        width: calc(40px * var(--scale));
        height: calc(40px * var(--scale));
        border-radius: 50%;
        display: inline-block;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
        margin-right: 30px;
    }
    .pos6 .pager div:first-child span:hover {
        background: #063a8a;
        color: #fff;
    }
    .pos6 .pager div:first-child span:last-child {
        transform: rotate(180deg);
    }
    .pos6 .pager div:last-child {
        margin-right: 70px;
        color: #063a8a;
        font-size: 2.5rem;
    } */
    .pos6 .date {
        margin: 20px 20px;
        font-size: 1.5rem;
        border-top: 1px #222 solid;
        padding-top: 8px;
        color: #222;
    }

    .pos6 .news .slide-item {
        width: calc(1035px * var(--scale));
        height: calc(600px * var(--scale));
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .pos6 .news .slide-item li {
        cursor: pointer;
    }

    .pos6 .news .slide-item li .cover {
        width: calc(312px * var(--scale));
        height: calc(474px * var(--scale));
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
    }

    .pos6 .news .slide-item li:hover .cover img {
        width: 100%;
        transition: all 0.5s;
    }

    .pos6 .news .slide-item li:hover .title {
        transform: scale(1);
        transition: all 0.5s;
    }

    .pos6 .news .slide-item li .cover img {
        width: 90%;
        border-radius: 30px;
        transition: all 0.5s;
    }

    .pos6 .news .slide-item li .title {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(9, 99, 218, 0.2);
        font-size: 1.5rem;
        padding: 20px 36px;
        line-height: 3rem;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        color: #fff;
        transform: scale(0.9);
        transform-origin: bottom;
        transition: all 0.5s;
    }

    .pos6 .news {
        position: absolute;
        right: calc(-160px * var(--scale));
        top: calc(250px * var(--scale));
        width: calc(1035px * var(--scale));
    }

    .pos6 .desc .desc-wrap {
        flex: 1;
    }

    .pos6 .desc .txt {
        width: 320px;
    }

    .pos6 .desc .pc-more {
        cursor: pointer;
        margin-top: 40px;
    }

    .pos5 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: calc(150px * var(--scale));
    }

    .pos5 .desc .desc-wrap {
        flex: 1;
    }

    .pos5 .desc .txt {
        width: 360px;
    }

    .pos5 .desc .pc-more {
        cursor: pointer;
        margin-top: 50px;
    }

    .pos5 .banner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -70px;
        width: calc(1603px * var(--scale));
    }

    .pos5 .swiper_container {
        display: flex;
        align-items: center;
    }

    .pos5 .swiper_container .wrap {
        width: calc(458px * var(--scale));
        height: calc(263px * var(--scale));
        overflow: hidden;
    }

    .pos5 .swiper_container ul {
        width: 90px;
        height: calc(262px * var(--scale));
        border-bottom: 1px #222 solid;
        margin-left: 23px;
    }

    .pos5 .swiper_container ul li {
        font-size: calc(2.6rem * var(--scale));
        padding: 10px 0;
        cursor: pointer;
    }

    .pos5 .swiper_container ul .active {
        font-size: calc(4rem * var(--scale));
        font-weight: bold;
    }

    .pos4 .desc {
        margin-top: 240px;
        display: inline-block;
    }

    /* .pos4 .desc .txt {
        width: 270px;
    } */

    .pos4 .pc-more {
        position: absolute;
        top: 476px;
        left: 0;
        cursor: pointer;
    }

    .pos4 .banner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(904px * var(--scale));
    }

    .pos3 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: 170px;
    }

    .pos3 .desc .desc-wrap {
        flex: 1;
    }

    /* .pos3 .desc .txt {
        width: calc(450px * var(--scale));
    } */

    .pos3 .desc .pc-more {
        cursor: pointer;
        margin-top: 60px;
    }

    .pos3 .banner {
        width: calc(502px * var(--scale));
    }

    .pos2_bg {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
    }

    .pos2_bg div {
        width: 100%;
        height: 60%;
        background: url("~@/assets/pc/home/p2_bg_2.jpg") no-repeat;
        background-size: auto calc(920px * var(--scale));
        display: inline-block;
        animation: pos2-bg-bounce 2s linear infinite;
        background-position: center 65%;
    }

    .pos2 .desc {
        background: rgba(73, 183, 233, 0.7);
        width: 790px;
        height: calc(364px * var(--scale));
        margin-top: calc(140px * var(--scale));
        display: inline-block;
        position: relative;
    }

    .pos2 .desc .txt {
        width: 380px;
        margin: 40px 0 0 74px;
    }

    .pos2 .desc .pc-more {
        position: absolute;
        bottom: -20px;
        left: 70px;
        cursor: pointer;
    }

    .pos2 .banner {
        position: absolute;
        left: 0;
        bottom: 0;
        height: calc(203px * var(--scale));
    }

    .pos2 .banner img {
        height: 100%;
    }

    .pos3 .category {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 70px;
    }

    .pos3 .category li {
        width: calc(357px * var(--scale));
        height: calc(140px * var(--scale));
        background: #f8c54d;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
    }

    .pos1_wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .pos1_anim_wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .pos1_anim_wrap > div {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .pos1_anim_wrap > div .txt {
        position: absolute;
        left: 0;
        top: calc(290px * var(--scale));
        width: calc(500px * var(--scale));
        animation: fade-in-leftToRight 0.3s linear forwards;
    }

    .pos1_anim_wrap > div .huan {
        position: absolute;
        left: calc(910px * var(--scale));
        top: calc(255px * var(--scale));
        width: calc(732px * var(--scale));
        animation: bounce 3s linear forwards infinite;
    }

    .pos1_anim_wrap > div .ball1 {
        position: absolute;
        left: calc(1268px * var(--scale));
        top: calc(150px * var(--scale));
        width: calc(86px * var(--scale));
        animation: bounce-2 3s linear forwards infinite;
    }

    .pos1_anim_wrap > div .ball2 {
        position: absolute;
        left: calc(758px * var(--scale));
        top: calc(755px * var(--scale));
        width: calc(142px * var(--scale));
        animation: bounce-2 3s linear forwards infinite;
    }

    .b1 {
        margin-top: calc(740px * var(--scale));
        display: inline-block;
    }

    .b1 img {
        cursor: pointer;
        margin-right: 30px;
    }

    .b1 img:last-child {
        width: 35px;
    }

    .b2 {
        height: calc(200px * var(--scale));
        margin-top: 53px;
        display: inline-flex;
        color: #fff;
        width: 100%;
        white-space: nowrap;
    }

    .b2 .slider {
        display: flex;
        align-items: center;
        width: calc(436px * var(--scale));
        height: 100%;
    }

    .b2 .swiper {
        width: calc(1164px * var(--scale));
        height: 100%;
    }

    .b2 .swiper .wrap {
        width: calc(1190px * var(--scale));
        height: 100%;
        margin-left: calc(88px * var(--scale));
        display: flex;
    }

    .b2 .swiper .wrap .pager {
        width: calc(145px * var(--scale));
        background: #54bcea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .b2 .swiper .wrap .pager .tran {
        transform: rotate(180deg);
    }

    .b2 .swiper .wrap .page-wrap {
        flex: 1;
        background: rgba(218, 233, 241, 0.5);
        display: flex;
        overflow: hidden;
    }

    .b2 .swiper .wrap .page-wrap > div {
        white-space: nowrap;
        width: 100%;
        display: flex;
    }

    .b2 .swiper .wrap .page-wrap .page img {
        height: 60%;
        transition: all 0.4s;
    }

    .b2 .swiper .wrap .page-wrap .move_prev {
        transform: translateX(calc((100% / 4) * 2 * -1));
        animation: pos1_pre 0.2s linear forwards;
    }

    .b2 .swiper .wrap .page-wrap > .move_next {
        animation: pos1_move 0.2s linear forwards;
    }
}

@media only screen and (min-width: 2049px) {
    .pos6 .news .pre {
        left: 0;
        z-index: 9999;
    }

    .pos6 .news .next {
        right: 0;
        transform: rotate(180deg);
        z-index: 9999;
    }

    .pos7 .brand {
        position: relative;
    }

    .pos7 .brand ul {
        position: absolute;
        left: 0;
        bottom: -4%;
        right: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .pos7 .brand ul li {
        margin-bottom: 30px;
        display: block;
    }

    .pos7 .brand ul img {
        max-height: 40px;
        margin-right: 100px;
    }

    .pos7 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: 90px;
    }

    .pos7 .desc .desc-wrap {
        flex: 1;
    }

    .pos7 .desc h1 {
        font-size: 4rem;
        color: #222;
        margin: 0 70px 40px 0;
        font-weight: normal;
    }

    .pos7 .desc p {
        font-weight: normal;
        font-size: 1.7rem;
        color: #838587;
        margin: 20px 0 20px 0;
        line-height: 30px;
        text-align: justify;
    }

    .pos7 .desc .pc-more {
        cursor: pointer;
        margin-top: 30px;
    }

    .pos7 .banner1 {
        margin-top: 80px;
    }

    .pos7 .banner2 {
        margin-top: 90px;
    }

    .pos7 .icon {
        width: 450px;
    }

    .hands {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .pos6 .hand {
        position: absolute;
    }

    .pos6 .hand1 {
        left: -690px;
        top: 40px;
        transform: translate(-200px, -150px);
    }

    .pos6 .hand2 {
        left: -420px;
        bottom: -200px;
        transform: translate(-200px, 150px);
    }

    .pos6 .hand3 {
        left: 280px;
        bottom: -215px;
        transform: translate(200px, 150px);
    }

    .pos6 .light {
        position: absolute;
        left: 10px;
        top: 465px;
        opacity: 0;
        transform: scale(0.3);
        transform: rotate(15deg);
    }

    /* .pos6 .pager {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        margin-left: 20px;
    }
    .pos6 .pager div:first-child span {
        font-size: 1.5rem;
        color: #063a8a;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
        margin-right: 30px;
    }
    .pos6 .pager div:first-child span:hover {
        background: #063a8a;
        color: #fff;
    }
    .pos6 .pager div:first-child span:last-child {
        transform: rotate(180deg);
    }
    .pos6 .pager div:last-child {
        margin-right: 100px;
        color: #063a8a;
        font-size: 2.5rem;
    } */
    .pos6 .date {
        margin: 20px 20px;
        font-size: 1.5rem;
        border-top: 1px #222 solid;
        padding-top: 8px;
        color: #222;
    }

    .pos6 .news .slide-item {
        width: 1035px;
        height: 600px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .pos6 .news .slide-item li {
        cursor: pointer;
    }

    .pos6 .news .slide-item li .cover {
        width: 312px;
        height: 474px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
    }

    .pos6 .news .slide-item li:hover .cover img {
        width: 100%;
        transition: all 0.5s;
    }

    .pos6 .news .slide-item li:hover .title {
        transition: all 0.5s;
        transform: scale(1);
    }

    .pos6 .news .slide-item li .cover img {
        width: 90%;
        border-radius: 30px;
        transition: all 0.5s;
    }

    .pos6 .news .slide-item li .title {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(9, 99, 218, 0.2);
        font-size: 1.7rem;
        padding: 30px 50px;
        line-height: 3rem;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        color: #fff;
        transform: scale(0.9);
        transform-origin: bottom;
        transition: all 0.5s;
    }

    .pos6 .news {
        position: absolute;
        right: -160px;
        top: 350px;
        width: 1035px;
    }

    .pos6 .desc {
        width: 600px;
        margin-top: 100px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .pos6 .desc .desc-wrap {
        flex: 1;
    }

    .pos6 .desc .txt {
        width: 390px;
    }

    .pos6 .desc .pc-more {
        cursor: pointer;
        margin-top: 50px;
    }

    .pos5 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: 150px;
    }

    .pos5 .desc .desc-wrap {
        flex: 1;
    }

    .pos5 .desc .txt {
        width: 460px;
    }

    .pos5 .desc .pc-more {
        cursor: pointer;
        margin-top: 50px;
    }

    .pos5 .banner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -99px;
    }

    .pos5 .swiper_container {
        display: flex;
        align-items: center;
    }

    .pos5 .swiper_container .wrap {
        width: 458px;
        height: 263px;
        overflow: hidden;
    }

    .pos5 .swiper_container ul {
        width: 100px;
        height: 262px;
        border-bottom: 1px #222 solid;
        margin-left: 23px;
    }

    .pos5 .swiper_container ul li {
        font-size: 2.6rem;
        padding: 10px 0;
        cursor: pointer;
    }

    .pos5 .swiper_container ul .active {
        font-size: 4rem;
        font-weight: bold;
    }

    .pos4 .desc {
        margin-top: 350px;
        display: inline-block;
    }

    .pos4 .desc .txt {
        width: 340px;
    }

    .pos4 .pc-more {
        position: absolute;
        top: 659px;
        left: 0;
        cursor: pointer;
    }

    .pos4 .banner {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .pos3 .desc {
        width: 100%;
        display: inline-flex;
        margin-top: 170px;
    }

    .pos3 .desc .desc-wrap {
        flex: 1;
    }
    .pos7 .desc .desc-wrap > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .pos3 .desc .txt {
        width: 450px;
    }

    .pos3 .desc .pc-more {
        cursor: pointer;
        margin-top: 70px;
    }

    .pos3 .category {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 150px;
    }

    .pos3 .category li {
        width: 357px;
        height: 140px;
        background: #f8c54d;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
    }

    .pos2_bg {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
    }

    .pos2_bg div {
        width: 100%;
        height: 60%;
        background: url("~@/assets/pc/home/p2_bg_2.jpg") no-repeat;
        background-size: auto 920px;
        display: inline-block;
        animation: pos2-bg-bounce 2s linear infinite;
        background-position: center 65%;
    }

    .pos2 .desc {
        background: rgba(73, 183, 233, 0.7);
        width: 1053px;
        height: 364px;
        margin-top: 145px;
        display: inline-block;
        position: relative;
    }

    .pos2 .desc .txt {
        width: 410px;
        margin: 70px 0 0 74px;
    }

    .pos2 .desc .pc-more {
        position: absolute;
        bottom: -25px;
        left: 70px;
        cursor: pointer;
    }

    .pos2 .banner {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .pos1_wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .pos1_anim_wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .pos1_anim_wrap > div {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .pos1_anim_wrap > div .txt {
        position: absolute;
        left: 0;
        top: 290px;
        width: 500px;
        animation: fade-in-leftToRight 0.3s linear forwards;
    }

    .pos1_anim_wrap > div .huan {
        position: absolute;
        left: 910px;
        top: 255px;
        width: 732px;
        animation: bounce 3s linear forwards infinite;
    }

    .pos1_anim_wrap > div .ball1 {
        position: absolute;
        left: 1268px;
        top: 150px;
        width: 86px;
        animation: bounce-2 3s linear forwards infinite;
    }

    .pos1_anim_wrap > div .ball2 {
        position: absolute;
        left: 758px;
        top: 765px;
        width: 142px;
        animation: bounce-2 3s linear forwards infinite;
    }

    .b1 {
        margin-top: 740px;
        display: inline-block;
    }

    .b1 img {
        cursor: pointer;
        margin-right: 30px;
    }

    .b1 img:last-child {
        width: 40px;
    }

    .b2 {
        height: 200px;
        margin-top: 81px;
        display: inline-flex;
        color: #fff;
        width: 100%;
        white-space: nowrap;
    }

    .b2 .slider {
        display: flex;
        align-items: center;
        width: 436px;
        height: 100%;
    }

    .b2 .swiper {
        width: 1164px;
        height: 100%;
    }

    .b2 .swiper .wrap {
        width: 1190px;
        height: 100%;
        margin-left: 88px;
        display: flex;
    }

    .b2 .swiper .wrap .pager {
        width: 145px;
        background: #54bcea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .b2 .swiper .wrap .page img {
        height: 60%;
        transition: all 0.4s;
    }

    .b2 .swiper .wrap .pager .tran {
        transform: rotate(180deg);
    }

    .b2 .swiper .wrap .page-wrap {
        flex: 1;
        background: rgba(218, 233, 241, 0.5);
        display: flex;
        overflow: hidden;
    }

    .b2 .swiper .wrap .page-wrap > div {
        white-space: nowrap;
        width: 100%;
        display: flex;
    }

    .b2 .swiper .wrap .page-wrap .move_prev {
        transform: translateX(calc((100% / 4) * 2 * -1));
        animation: pos1_pre 0.2s linear forwards;
    }

    .b2 .swiper .wrap .page-wrap > .move_next {
        animation: pos1_move 0.2s linear forwards;
    }
}
</style>
