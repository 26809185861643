<template>
    <div>
        <Header> </Header>
        <Row bg="assets/mobile/news/pos1-bg.jpg"></Row>
        <Row className="pos2">
            <div class="tab">
                <div class="item" :class="tabIdx == 1 ? 'active' : ''" @click="TabHandle(1)">
                    <div>集团动态</div>
                    <span></span>
                </div>
                <div class="item" :class="tabIdx == 0 ? 'active' : ''" @click="TabHandle(0)">
                    <div>行业洞察</div>
                    <span></span>
                </div>
            </div>
            <ul v-show="tabIdx == 0">
                <li v-for="item in news[0].data" :key="'tab0' + item.id">
                    <router-link :to="'/m/news/detail?id=' + item.id">
                        <div class="item">
                            <div class="cover">
                                <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_248,h_159'" />
                            </div>
                            <div class="info">
                                <h2>{{ item.title }}</h2>
                                <p v-html="item.desc"></p>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <ul v-show="tabIdx == 1">
                <li v-for="item in news[1].data" :key="'tab2' + item.id">
                    <router-link :to="'/m/news/detail?id=' + item.id">
                        <div class="item">
                            <div class="cover">
                                <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_248,h_159'" />
                            </div>
                            <div class="info">
                                <h2>{{ item.title }}</h2>
                                <p v-html="item.desc"></p>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <div to="/m/news" class="more" v-if="tabIdx == 0 && !news[0].ended" @click="NextPageHandle">
                查看更多
            </div>
            <div to="/m/news" class="more" v-if="tabIdx == 1 && !news[1].ended" @click="NextPageHandle">
                查看更多
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "../../components/mobile/Row.vue";
import settings from "@/settings";
export default {
    name: "mobile-news",
    components: { Header, Footer, Row },
    data() {
        return {
            loading: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            news: [
                {
                    page: 0,
                    pages: 0,
                    total: 0,
                    data: [],
                    ended: false,
                },
                { page: 0, pages: 0, total: 0, data: [], ended: false },
            ],
            page: 0,

            tabIdx: 1,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        NextPageHandle() {
            // if (this.news[idx].ended) return;
            this.loadData();
        },
        OnChangePageHandle(e) {
            this.loadData(e.page);
        },
        TabHandle(idx) {
            this.tabIdx = idx;
            var data = this.news[idx];
            if (data.ended || data.data.length > 0) return;
            this.loadData(1);
        },

        loadData(page) {
            this.$showLoading("加载中...");
            this.$http.get(`/api/news/list?pageSize=6&type=${this.tabIdx + 1}&page=${page ? page : this.news[this.tabIdx].page + 1}`).then((res) => {
                this.news[this.tabIdx].page = page ? page : this.news[this.tabIdx].page + 1;
                this.news[this.tabIdx].pages = parseInt(res.data.totalPage);
                this.news[this.tabIdx].total = parseInt(res.data.totalCount);
                this.news[this.tabIdx].data = this.news[this.tabIdx].data.concat(res.data.contents);
                this.news[this.tabIdx].ended = res.data.contents.length < 6;
                this.$forceUpdate();
            });
        },
    },
};
</script>
<style scoped>
.pos2 .more {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px 0;
}
.pos2 ul {
    margin: 0 15px;
}
.pos2 ul li {
    padding: 20px 0;
    border-bottom: 1px #eaeaea solid;
}
.pos2 ul li .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pos2 ul li .item .cover {
    width: 33%;
    display: inline-block;
}
.pos2 ul li .item .info {
    width: 62%;
    display: inline-block;
    margin-left: 5%;
}
.pos2 ul li .item .info h2 {
    font-size: 1.4rem;
    color: #333333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.pos2 ul li .item .info p {
    font-size: 1.2rem;
    color: #333333;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
}

.pos2 .tab {
    margin: 30px 15px 20px 15px;
    display: block;
    border-bottom: 1px #c1c1c1 solid;
}
.pos2 .tab .item {
    width: 50%;
    display: inline-flex;
    color: #333333;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pos2 .tab .item div {
    width: 70%;
    border-bottom: 2px transparent solid;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 0;
}
.pos2 .tab .item span {
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 3px;
    height: 3px;
    background: #333333;
    transform: rotate(45deg);
    margin-left: -2px;
    display: none;
}

.pos2 .tab .active div {
    font-weight: bold;
    border-bottom: 2px #333333 solid;
}
.pos2 .tab .active span {
    display: inline-block;
}
</style>
