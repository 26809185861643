<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/invest/p1_bg.jpg"> </Row>
        <Row bgColor="#f5f6f8">
            <div class="pos2">
                <div style="width:100%; height:100px;"></div>
                <InvestRow bg="#f5f6f8" :showMore="false" margin="70px 100px">
                    <template v-slot:title><span class="back" @click="$router.back(-1)">投资者关系</span> <i class="iconfont icon-next" style="font-size:2rem; line-height:2rem; width:3rem;text-indent:0;margin-right:5px;"></i>{{ titles[$route.query.t] }}</template>
                    <div class="list">
                        <li v-for="item in data" :key="item.pdf">
                            <a :href="resourceBaseUrl + item.pdf" target="_blank"
                                ><span>{{ item.date }}</span
                                >{{ item.title }}</a
                            >
                        </li>
                    </div>
                    <div class="pager">
                        <Pager :pageSize="10" :pageNumber="page" :totalCount="total" @changePage="OnChangePageHandle"></Pager>
                    </div>
                </InvestRow>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
import InvestRow from "@/components/pc/InvestRow.vue";
import Pager from "@/components/pc/Pager.vue";
import settings from "@/settings";
export default {
    name: "pc-invest-list",
    components: { Nav, Footer, Row, InvestRow, Pager },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            titles: ["", "定期报告", "公司研报"],
            page: 1,
            pages: 0,
            total: 0,
            data: [],
        };
    },
    watch: {
        $route: {
            handler: function() {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                this.LoadData(1);
            },
        },
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData(1);
    },

    methods: {
        LoadData(page) {
            this.$http.get(`/api/invest/list?type=${this.$route.query.t}&page=${page}`).then((res) => {
                this.pages = parseInt(res.data.totalPage);
                this.total = parseInt(res.data.totalCount);
                this.data = res.data.contents;
            });
        },
        OnChangePageHandle(e) {
            this.LoadData(e.page);
        },
    },
};
</script>
<style scoped>
.pager {
    width: 100%;
    display: inline-block;
    margin-top: 100px;
}
.list {
    width: 100%;
}
.back {
    cursor: pointer;
}
.back:hover {
    text-decoration: underline;
}
@media only screen and (max-width: 2048px) {
    .list li {
        font-size: 1.6rem;
        font-weight: normal;
        color: #4d4d4d;
        padding: 50px 0;
        border-bottom: 1px #ccc dashed;
    }

    .list li a {
        font-weight: normal;
        font-size: 1.6rem;
        width: 100%;
        color: #000;
    }
    .list li a span {
        width: 140px;
        display: inline-block;
    }
}

@media only screen and (min-width: 2049px) {
    .list li {
        font-size: 1.7rem;
        font-weight: normal;
        color: #4d4d4d;
        padding: 50px 0;
        border-bottom: 1px #ccc dashed;
    }

    .list li a {
        font-weight: normal;
        font-size: 1.7rem;
        width: 100%;
        color: #000;
    }
    .list li a span {
        width: 140px;
        display: inline-block;
    }
}
</style>
