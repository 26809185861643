<template>
    <div class="pc-row" :style="`background-size:auto ${bgScale * 100}%;height:${h}px;${bgImg}background-color:${bgColor};${styles};`">
        <!-- <div class="anim-wrap">
            <slot name="bg"></slot>
        </div>
        <div class="row-wrap"> -->
        <div class="out-wrap" :style="scale < 1 ? `width:${1920 * scale}px;` : ''">
            <div class="in-wrap">
                <slot></slot>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>
<script>
export default {
    name: "row",
    props: {
        height: {
            type: String,
            default: "auto",
        },
        bg: {
            type: String,
            default: "",
        },
        bgRepeat: {
            type: String,
            default: "no-repeat",
        },
        bgColor: {
            type: String,
            default: "#fff",
        },
        bgPos: {
            type: String,
            default: "top center",
        },
        bgScale: {
            type: Number, //0-1,背景图的显示高度比例
            default: 1,
        },
        styles: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            bgImg: "",
            h: this.height,
            scale: 1,
        };
    },
    mounted() {
        const _scale = 0.75;
        if (this.bg) this.bgImg = "background-image:url(" + require("@/" + this.bg) + "); " + `background-repeat:${this.bgRepeat}` + ";background-position: " + this.bgPos + ";";
        var mediaQuery = window.matchMedia("only screen and (max-width: 2048px)");
        var self = this;
        if (mediaQuery.matches) {
            this.ScaleHandle(_scale);
        }

        mediaQuery.addEventListener("change", function(e) {
            self.ScaleHandle(e.matches ? _scale : 1);
        });
    },
    methods: {
        ScaleHandle(scale) {
            this.scale = scale;
            this.h = this.height * scale;
        },
    },
};
</script>
<style scoped>
.pc-row {
    position: relative;
    display: block;
}
.pc-row .row-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: normal;
    display: block;
}
.anim-wrap {
    height: 100%;
}
@media only screen and (max-width: 2048px) {
    .pc-row,
    .anim-wrap {
        width: 100%;
        text-align: center;
        min-width: calc(1920px * var(--scale));
        display: block;
        letter-spacing: normal;
    }
    .out-wrap {
        width: calc(1920px * var(--scale));
        height: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .in-wrap {
        width: calc(1600px * var(--scale));
        height: 100%;
        margin: 0 auto;
        text-align: left;
        display: block;
        position: relative;
    }
    /* .in-wrap {
        -moz-transform: scale(0.8);
        -moz-transform-origin: center top;
    } */
}

@media only screen and (min-width: 2049px) {
    .pc-row,
    .anim-wrap {
        width: 100%;
        text-align: center;
        min-width: 1920px;
        display: block;
        letter-spacing: normal;
    }
    .out-wrap {
        width: 1920px;
        height: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .in-wrap {
        width: 1600px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
        display: block;
        position: relative;
    }
    /* .in-wrap {
        -moz-transform: scale(0.8);
        -moz-transform-origin: center top;
    } */
}
</style>
