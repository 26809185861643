<template>
    <div class="container marketing">
        <Nav></Nav>
        <div style="width: 100%; height: 70px"></div>
        <Row height="720" bg="assets/pc/job/p1_bg.jpg"> </Row>
        <Row height="600" bgColor="#fff">
            <div class="pos2">
                <h3>敬请期待</h3>
                <a href="javascript:;" @click="$router.back()">返回</a>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-job-none",
    components: {
        Nav,
        Footer,
        Row,
    },
    watch: {
        $route: {
            handler: function() {
                if (this.$route.query.t) {
                    this.tabIdx = this.$route.query.t - 1;
                }
            },
        },
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (this.$route.query.t) {
            this.tabIdx = this.$route.query.t - 1;
        }
    },
    data() {
        return {
            tabIdx: 0,
        };
    },
};
</script>
<style scoped>
.pos2 {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.pos2 h3 {
    font-size: 1.8rem;
    text-align: center;
    margin: 150px 0;
}
.pos2 a {
    border: 1px #ccc solid;
    font-size: 1.4rem;
    color: #222;
    padding: 10px 50px;
    border-radius: 2rem;
}
</style>
