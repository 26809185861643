<template>
    <div class="container marketing">
        <Nav></Nav>
        <div style="width: 100%; height: 70px"></div>
        <Row height="720" bg="assets/pc/job/p1_bg.jpg"> </Row>
        <Row height="600" bgColor="#fff">
            <div class="pos2">
                <ul class="tab">
                    <li :class="tabIdx == 0 ? 'active' : ''" @click="tabIdx = 0">
                        社会招聘
                    </li>
                    <li :class="tabIdx == 1 ? 'active' : ''" @click="tabIdx = 1">
                        校园招聘
                    </li>
                </ul>
                <ul class="list" v-if="tabIdx == 0">
                    <li><a target="_blank" href="http://www.aspiration-cn.com/talents">派瑞威行</a></li>
                    <li><a target="_blank" href="http://www.iforce-media.com/careers.html">百孚思</a></li>
                    <li><a target="_blank" href="http://www.itrax.cn/recruitment">爱&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创</a></li>
                    <li><router-link to="/job/none">同立传播</router-link></li>
                    <li><router-link to="/job/none">华&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邑</router-link></li>
                    <li><router-link to="/job/none">雨林木风</router-link></li>
                    <li><router-link to="/job/none">智阅网络</router-link></li>
                    <li><router-link to="/job/none">杭州浙文科技</router-link></li>
                </ul>
                <ul class="list" v-if="tabIdx == 1" style="align-items: center; justify-content: center">
                    <li><a href="javascript:;">敬请期待</a></li>
                </ul>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-job",
    components: {
        Nav,
        Footer,
        Row,
    },
    watch: {
        $route: {
            handler: function() {
                if (this.$route.query.t) {
                    this.tabIdx = this.$route.query.t - 1;
                }
            },
        },
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (this.$route.query.t) {
            this.tabIdx = this.$route.query.t - 1;
        }
    },
    data() {
        return {
            tabIdx: 0,
        };
    },
};
</script>
<style scoped>
.list li:hover {
    background: #57afec;
    border-color: #57afec;
}

.list li:hover a {
    color: #fff;
}

.tab {
    display: flex;
    margin: 90px 0;
}

.tab li:first-child {
    border-right: 2px #9a9a9a solid;
}

.tab .active {
    color: #222;
}

.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
@media only screen and (max-width: 2048px) {
    .tab li {
        padding-right: 20px;
        margin-right: 20px;
        color: #9a9a9a;
        font-size: 2.3rem;
        line-height: 2.3rem;
        cursor: pointer;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: calc(150px * var(--scale)) calc(200px * var(--scale));
    }

    .list li {
        width: calc(200px * var(--scale));
        height: calc(45px * var(--scale));
        margin: 20px 20px;
        border: 1px #707070 solid;
        border-radius: 38px;
    }
    .list li a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #707070;
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 2049px) {
    .tab li {
        padding-right: 20px;
        margin-right: 20px;
        color: #9a9a9a;
        font-size: 2.3rem;
        line-height: 2.3rem;
        cursor: pointer;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 150px 200px;
    }

    .list li {
        width: 200px;
        height: 45px;
        margin: 20px 20px;
        border: 1px #707070 solid;
        border-radius: 38px;
    }
    .list li a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #707070;
        font-size: 1.6rem;
    }
}
</style>
