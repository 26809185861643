<template>
    <div class="container about">
        <Nav></Nav>
        <Row height="956" bg="assets/pc/about/p1_bg.jpg" bgPos="bottom center">
            <img class="pos1" src="@/assets/pc/home/video-btn.png" @click="showVideo = true" />
        </Row>
        <Row height="850" bgColor="#f3f3f3">
            <div class="pos2">
                <!-- <img class="banner1" src="@/assets/pc/about/pos2_banner1.png" /> -->
                <div class="desc">
                    <img class="banner" src="@/assets/pc/about/pos2_banner2.png" />
                    <p>
                        浙文互联集团股份有限公司2004年在上海证券交易所上市，股票代码600986.SH，2021年浙江省文化产业投资集团有限公司成为控股股东，公司正式更名为浙文互联集团股份有限公司。浙文互联连续数年营收逾百亿，位居行业三甲之列，业务涉及汽车营销、精准营销、直播电商与跨境电商、出海业务、智算业务、智慧内容生态、数字文化创新等多个领域。<br /><br />

                        在数字营销领域，浙文互联长期稳居汽车营销行业NO.1，全面推进“AI+营销”智慧营销业务实践，深入品牌战略咨询与管理、营销与传播策略制定、执行，媒介投放、公关策略制定与传播管理、品牌与领导人声誉管理、精准营销与效果投放、直播电商与跨境电商、社群营销、体验营销等多个核心板块；在数字文化领域，浙文互联积极响应国家文化数字化战略，持续建设“AI+文化”，探索技术路线和实施路径，推进垂直领域AIGC应用开发、星声代数字人智枢平台、轻量数字人应用—数眸、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断积累文化领域数字资产，构建AI智慧内容生态；在算力运营领域，浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座与汽车品牌智算中心，充分发挥算力生产力的重要作用。<br /><br />

                        目前，浙文互联旗下拥有百孚思、浙文天杰、派瑞威行、同立传播、智阅网络、华邑、好奇莫斯、浙文智算、浙文米塔、浙安果合、开心宇宙等多个子品牌。战略投资LGD电竞俱乐部、豆神教育、数文交所等数字文化创新领域。职场覆盖北京、杭州、上海、广州、重庆，全国员工规模逾千人。
                    </p>
                </div>
            </div>
        </Row>
        <Row height="845" bg="assets/pc/about/p3_bg.jpg" bgPos="bottom center">
            <div class="pos3">
                <ul class="tab">
                    <li :class="pos3TabIdx == 0 ? 'active' : ''" @click="pos3TabIdx = 0">
                        <div></div>
                        行业地位
                    </li>
                    <li :class="pos3TabIdx == 1 ? 'active' : ''" @click="pos3TabIdx = 1">
                        <div></div>
                        荣誉奖项
                    </li>
                </ul>
                <div class="wrap">
                    <div class="status" v-show="pos3TabIdx == 0">
                        <!-- <div><img src="@/assets/pc/about/status/1.jpg" /></div>
                        <div><img src="@/assets/pc/about/status/2.jpg" /></div>
                        <div><img src="@/assets/pc/about/status/3.jpg" /></div>
                        <div><img src="@/assets/pc/about/status/4.jpg" /></div> -->
                        <swiper :options="pos3StandingSwiperOption" ref="pos3StandingSwiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" v-if="standing && standing.length > 0">
                            <template v-for="page in Math.ceil(standing.length / 4)">
                                <swiper-slide :key="page">
                                    <ul class="slide-item">
                                        <template v-for="idx in 4">
                                            <li :key="idx">
                                                <template v-if="(page - 1) * 4 + idx - 1 < standing.length">
                                                    <img :data-idx="(page - 1) * 4 + idx - 1" :src="resourceBaseUrl + standing[(page - 1) * 4 + idx - 1].cover" />
                                                    <!-- <div>
                                                        <h3>{{ standing[(page - 1) * 4 + idx - 1].title }}</h3>
                                                        <p v-html="standing[(page - 1) * 4 + idx - 1].desc"></p>
                                                    </div> -->
                                                </template>
                                            </li>
                                        </template>
                                    </ul>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                    <div class="honor" v-show="pos3TabIdx == 1">
                        <swiper :options="pos3SwiperOption" ref="pos3HonorSwiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" v-if="honor && honor.length > 0">
                            <template v-for="page in Math.ceil(honor.length / 6)">
                                <swiper-slide :key="page">
                                    <ul class="slide-item">
                                        <template v-for="idx in 6">
                                            <li :key="idx">
                                                <template v-if="(page - 1) * 6 + idx - 1 < honor.length">
                                                    <img :data-idx="(page - 1) * 6 + idx - 1" :src="resourceBaseUrl + honor[(page - 1) * 6 + idx - 1].cover" />
                                                    <div>
                                                        <h3>{{ honor[(page - 1) * 6 + idx - 1].title }}</h3>
                                                        <p v-html="honor[(page - 1) * 6 + idx - 1].desc"></p>
                                                    </div>
                                                </template>
                                            </li>
                                        </template>
                                    </ul>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                    <div v-if="pos3TabIdx == 0 && Math.ceil(standing.length / 4) > 1" class="pager">
                        <div class="iconfont icon-back" @click="StandingPrePage"></div>
                        <div class="iconfont icon-back" @click="StandingNextPage"></div>
                    </div>
                    <div v-if="pos3TabIdx == 1 && Math.ceil(honor.length / 6) > 1" class="pager">
                        <div class="iconfont icon-back" @click="HonorPrePage"></div>
                        <div class="iconfont icon-back" @click="HonorNextPage"></div>
                    </div>
                </div>
            </div>
        </Row>
        <Row height="1800" bg="assets/pc/about/p4_bg.jpg" bgPos="center">
            <div class="pos4">
                <div>
                    <!-- <img src="@/assets/pc/about/pos4_banner.png" /> -->
                    <ul>
                        <li>
                            <a href="http://www.iforce-media.com/" target="_blank"><img src="@/assets/pc/about/brand/iforce.jpg"/></a>
                        </li>
                        <li>
                            <a href="http://www.amaxz.com.cn/" target="_blank"><img src="@/assets/pc/about/brand/amaxz.jpg"/></a>
                        </li>
                        <li>
                            <a href="http://www.aspiration-cn.com" target="_blank"><img src="@/assets/pc/about/brand/pairui.jpg"/></a>
                        </li>
                        <li>
                            <router-link to="/about/tongli"><img src="@/assets/pc/about/brand/tongli.jpg"/></router-link>
                        </li>

                        <li>
                            <a target="_blank" href="https://www.qctt.cn"><img src="@/assets/pc/about/brand/zywl.jpg"/></a>
                        </li>
                        <li>
                            <router-link to="/about/huayi"><img src="@/assets/pc/about/brand/huayi.jpg"/></router-link>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.hqmoss.com"><img src="@/assets/pc/about/brand/hqms.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/about/brand/zwzs.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://mita.com"><img src="@/assets/pc/about/brand/mtszys.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://guohekeji.xinpianchang.com"><img src="@/assets/pc/about/brand/zagh.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/about/brand/ss.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/about/brand/ldg.jpg"/></a>
                        </li>
                        <li>
                            <a target="_blank"><img src="@/assets/pc/about/brand/kxyz.jpg"/></a>
                        </li>
                    </ul>
                </div>
            </div>
        </Row>
        <Row height="1687" bgColor="#fff">
            <div class="pos5">
                <img class="title" src="@/assets/pc/about/pos5_banner.png" />
                <ul class="tab">
                    <li :class="pos5TabIdx == 1 ? 'active' : ''" @click="Pos5TabHandle(1)">
                        <div>汽车行业</div>
                        <span class="iconfont icon-triangle"></span>
                    </li>
                    <li :class="pos5TabIdx == 2 ? 'active' : ''" @click="Pos5TabHandle(2)">
                        <div>快消及金融行业</div>
                        <span class="iconfont icon-triangle"></span>
                    </li>
                    <li :class="pos5TabIdx == 3 ? 'active' : ''" @click="Pos5TabHandle(3)">
                        <div>互联网IT及电商</div>
                        <span class="iconfont icon-triangle"></span>
                    </li>
                    <li :class="pos5TabIdx == 4 ? 'active' : ''" @click="Pos5TabHandle(4)">
                        <div>休闲娱乐</div>
                        <span class="iconfont icon-triangle"></span>
                    </li>
                </ul>
                <div class="wrap">
                    <swiper :options="pos5SwiperOption" ref="pos5Swiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" @slideChangeTransitionStart="pos5SwiperChanged" v-if="customer">
                        <swiper-slide>
                            <div class="slide-item"><img :src="resourceBaseUrl + customer.pcAuto" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slide-item"><img :src="resourceBaseUrl + customer.pcFmcg" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slide-item"><img :src="resourceBaseUrl + customer.pcIt" /></div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="slide-item"><img :src="resourceBaseUrl + customer.pcAmusement" /></div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="pager">
                    <div class="iconfont icon-back" @click="Pos5PreHandle"></div>
                    <div class="iconfont icon-back" @click="Pos5NextHandle"></div>
                </div>
            </div>
        </Row>
        <!-- <Row height="2448" bg="assets/pc/about/p6_bg.jpg" bgRepeat="repeat-x" bgPos="top">
            <div class="pos6">
                <img src="@/assets/pc/about/pos6_banner1.png" />
                <img src="@/assets/pc/about/pos6_banner2.jpg" />
            </div>
        </Row> -->
        <Footer></Footer>
        <Video :src="resourceBaseUrl + '/media/intro.mp4'" v-if="showVideo" @closed="showVideo = false"></Video>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
import Row from "@/components/pc/Row.vue";
import Video from "@/components/pc/Video.vue";
export default {
    name: "pc-about",
    components: { Nav, Footer, Row, Video },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            pos3TabIdx: 0,
            pos3StandingSwiperOption: {
                // width: 835,
                // height: 694,
                // autoplay: true,
                // direction: "vertical",
                loop: true,
            },
            pos3SwiperOption: {
                width: 1070,
                height: 694,
                // autoplay: true,
                // direction: "vertical",
                loop: true,
            },

            honorSwiperIdx: 0,
            pos5TabIdx: 1,
            pos5SwiperOption: {
                // width: 1600,
                // height: 800,
                // autoplay: true,
                // direction: "vertical",
                loop: false,
            },
            customer: null,
            honor: [],
            standing: [],
            showVideo: false,
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.ChangePos();
            },
        },
    },
    mounted() {
        // this.loadHonor();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadingHonor();
        this.LoadCustomer();
        this.ChangePos();
    },
    activated() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        ChangePos() {
            this.$nextTick(() => {
                if (this.$route.query.p) document.querySelector(`.${this.$route.query.p}`).scrollIntoView();
            });
        },
        Pos5PreHandle() {
            if (this.pos5TabIdx <= 1) return;
            this.Pos5TabHandle(this.pos5TabIdx - 1);
        },
        Pos5NextHandle() {
            if (this.pos5TabIdx >= 4) return;
            this.Pos5TabHandle(this.pos5TabIdx + 1);
        },
        pos5SwiperChanged() {
            this.pos5TabIdx = this.$refs.pos5Swiper.$swiper.realIndex + 1;
        },
        Pos5TabHandle(idx) {
            this.pos5TabIdx = idx;
            this.$refs.pos5Swiper.$swiper.slideTo(idx - 1, 500, false);
        },
        HonorPrePage() {
            const swiper = this.$refs.pos3HonorSwiper.$swiper;
            if (swiper.realIndex <= 0) return;
            swiper.slideTo(swiper.realIndex, 500, false);
        },
        HonorNextPage() {
            var swiper = this.$refs.pos3HonorSwiper.$swiper;
            var pages = swiper.slides.length / 2;
            if (swiper.realIndex >= pages - 1) return;
            swiper.slideTo(swiper.realIndex + 2, 500, false);
        },

        StandingPrePage() {
            const swiper = this.$refs.pos3StandingSwiper.$swiper;
            if (swiper.realIndex <= 0) return;
            swiper.slideTo(swiper.realIndex, 500, false);
        },
        StandingNextPage() {
            var swiper = this.$refs.pos3StandingSwiper.$swiper;
            var pages = swiper.slides.length / 2;
            if (swiper.realIndex >= pages - 1) return;
            swiper.slideTo(swiper.realIndex + 2, 500, false);
        },
        LoadingHonor() {
            this.$http.get("/api/honor/list").then((res) => {
                this.honor = res.data.filter((item) => item.type == 2);
                this.standing = res.data.filter((item) => item.type == 1);
            });
        },
        LoadCustomer() {
            this.$http.get("/api/customer/config").then((res) => {
                this.customer = res.data;
            });
        },
    },
};
</script>
<style scoped>
/* @import url("../../../common/css/pc.css"); */
.pos6 {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.pos5 .pager div:last-child {
    transform: rotate(180deg);
}
.pos5 .pager div:hover {
    color: #fff;
    background: #eeb32b;
}

.pos5 .wrap .slide-item img {
    max-width: 100%;
}

.pos5 .tab .active {
    color: #f9b924;
    font-weight: bold;
}
.pos5 .tab .active div {
    border-color: #f9b924;
}
.pos5 .tab .active span {
    opacity: 1;
}
.pos4 {
    text-align: center;
}
.pos4 > div ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto, auto);
    grid-row-gap: 25px;
    grid-column-gap: 25px;
}
.pos4 > div ul li {
    overflow: hidden;
}
.pos4 > div ul li img {
    transition: all 0.5s;
}
.pos4 > div ul li:hover img {
    transform: scale(1.2);
    transition: all 0.5s;
}

.pos3 .pager {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.pos3 .pager div:hover {
    background: #063a8a;
    color: #fff;
}
.pos3 .pager div:last-child {
    transform: rotate(180deg);
}

.pos3 .honor .slide-item li:hover img {
    transform: scale(1.1);
    transition: all 0.5s;
}
.pos3 .honor .slide-item li:hover div {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s;
}
.pos3 .honor .slide-item li div {
    background: rgba(0, 46, 145, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.8s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0.5);
}

.pos3 .status .slide-item li img {
    width: 100%;
    height: 100%;
}
.pos3 .status .slide-item li:hover img {
    transform: scale(1.1);
    transition: all 0.8s;
}
.pos3 .status .slide-item li:hover div {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s;
}
.pos3 .status .slide-item li div {
    background: rgba(0, 46, 145, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 0.8s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0.5);
}
.pos3 .status .slide-item li div h3 {
    font-size: 2rem;
    font-weight: normal;
    width: 100%;
    text-align: center;
    margin: 0 0 40px 0;
}
.pos3 .status .slide-item li div p {
    font-size: 1.6rem;
    text-align: center;
    margin: 0 40px;
    line-height: 3rem;
}
.pos3 {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}

.pos3 .tab .active {
    font-weight: bold;
}
.pos3 .tab .active div {
    background: #fff;
}
.pos3 .tab li div {
    width: 12px;
    height: 12px;
    border: 1px #fff solid;
    border-radius: 50%;
    margin-right: 14px;
}

.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
    display: inline-block;
}

@media only screen and (max-width: 2048px) {
    .pos6 img:first-child {
        width: 100%;
        margin: 160px 0 0 0;
    }
    .pos6 img:last-child {
        width: 100%;
        margin: 120px 0 0 0;
    }
    .pos5 .pager {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: calc(100px * var(--scale));
    }
    .pos5 .pager div {
        background: #edeceb;
        width: 40px;
        height: 40px;
        color: #eeb32b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: bold;
        margin: 0 20px;
        cursor: pointer;
    }
    .pos5 .wrap {
        width: 100%;
        height: calc(800px * var(--scale));
    }
    .pos5 .wrap .slide-item img {
        max-width: 100%;
    }
    .pos5 .title {
        margin: 100px 0 0 0;
        width: 380px;
    }
    .pos5 .tab {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 100px 0 50px 0;
    }
    .pos5 .tab li {
        font-size: 1.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .pos5 .tab li span {
        color: #f9b924;
        opacity: 0;
        margin-top: -3px;
        font-size: 1rem;
        line-height: 1rem;
    }
    .pos5 .tab li div {
        border-bottom: 3px #fff solid;
        padding-bottom: 10px;
        line-height: 1.7rem;
    }
    /* .pos4 img {
        margin-top: calc(600px * var(--scale));
        width: calc(1243px * var(--scale));
    } */

    .pos4 > div {
        margin-top: calc(848px * var(--scale));
        width: 100%;
        position: relative;
        display: inline-block;
    }

    .pos4 > div ul li a {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .pos1 {
        margin: calc(800px * var(--scale)) 0 0 0;
        width: calc(40px * var(--scale));
        cursor: pointer;
    }
    .pos2 .banner1 {
        margin: calc(74px * var(--scale)) auto;
    }
    .pos2 .desc {
        width: 100%;
        text-align: justify;
        font-size: 1.5rem;
        line-height: 3.3rem;
        margin-top: calc(80px * var(--scale));
        display: inline-block;
    }
    .pos2 .desc .banner {
        margin-bottom: calc(70px * var(--scale));
        width: calc(800px * var(--scale));
    }
    .pos3 .wrap {
        position: absolute;
        right: 0;
        top: calc(150px * var(--scale));
        width: calc(1070px * var(--scale));
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .pos3 .status {
        width: calc(630px * var(--scale));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .status .slide-item {
        width: calc(630px * var(--scale));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .status .slide-item li {
        width: calc(295px * var(--scale));
        height: calc(247px * var(--scale));
        margin-bottom: calc(30px * var(--scale));
        position: relative;
        overflow: hidden;
    }
    .pos3 .honor {
        width: calc(810px * var(--scale));
        text-align: right;
    }
    .pos3 .honor .slide-item {
        width: calc(810px * var(--scale));
        height: calc(520px * var(--scale));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .honor .slide-item li {
        width: calc(248px * var(--scale));
        height: calc(248px * var(--scale));
        margin-bottom: calc(30px * var(--scale));
        position: relative;
        overflow: hidden;
    }
    .pos3 .honor .slide-item li div h3 {
        font-size: 1.5rem;
        font-weight: normal;
        width: 100%;
        text-align: center;
        margin: 0 0 15px 0;
    }
    .pos3 .honor .slide-item li div p {
        font-size: 1.2rem;
        text-align: center;
        margin: 0 20px;
        line-height: 2rem;
    }
    .pos3 .pager div {
        display: inline-block;
        width: calc(40px * var(--scale));
        height: calc(40px * var(--scale));
        background: #fff;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #063a8a;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        margin-left: 30px;
    }
    .pos3 .tab {
        margin: calc(400px * var(--scale)) 0 0 0;
        width: calc(530px * var(--scale));
        font-size: 2rem;
        color: #fff;
    }
    .pos3 .tab li {
        margin: 40px 0;
        display: flex;
        cursor: pointer;
        align-items: center;
    }
}

@media only screen and (min-width: 2049px) {
    .pos6 img:first-child {
        width: 100%;
        margin: 180px 0 0 0;
    }
    .pos6 img:last-child {
        width: 100%;
        margin: 150px 0 0 0;
    }
    .pos5 .pager {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }
    .pos5 .pager div {
        background: #edeceb;
        width: 45px;
        height: 45px;
        color: #eeb32b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
        margin: 0 20px;
        cursor: pointer;
    }
    .pos5 .wrap {
        width: 100%;
        height: 800px;
    }
    .pos5 .title {
        margin: 120px 0 0 0;
        width: 480px;
    }
    .pos5 .tab {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 120px 0 90px 0;
    }
    .pos5 .tab li {
        font-size: 2.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .pos5 .tab li span {
        color: #f9b924;
        opacity: 0;
        margin-top: -3px;
        font-size: 1rem;
        line-height: 1rem;
    }
    .pos5 .tab li div {
        border-bottom: 3px #fff solid;
        padding-bottom: 10px;
        line-height: 2rem;
    }
    .pos4 > div {
        margin-top: 848px;
        display: inline-block;
        position: relative;
    }

    .pos4 > div ul li a {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .pos1 {
        margin: 800px 0 0 0;
        width: 40px;
        cursor: pointer;
    }
    .pos2 .banner1 {
        margin: 74px auto;
    }
    .pos2 .desc {
        width: 100%;
        text-align: justify;
        font-size: 1.8rem;
        line-height: 4rem;
        margin-top: 80px;
        display: inline-block;
    }
    .pos2 .desc .banner {
        margin-bottom: 70px;
        width: 800px;
    }

    .pos3 .wrap {
        position: absolute;
        right: 0;
        top: 150px;
        width: 1070px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .pos3 .status {
        width: 630px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .status .slide-item {
        width: 630px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .status .slide-item li {
        width: 295px;
        height: 247px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
    }
    .pos3 .honor {
        width: 810px;
        text-align: right;
    }
    .pos3 .honor .slide-item {
        width: 810px;
        height: 520px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pos3 .honor .slide-item li {
        width: 248px;
        height: 248px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
    }
    .pos3 .honor .slide-item li div h3 {
        font-size: 1.8rem;
        font-weight: normal;
        width: 100%;
        text-align: center;
        margin: 0 0 30px 0;
    }
    .pos3 .honor .slide-item li div p {
        font-size: 1.4rem;
        text-align: center;
        margin: 0 40px;
        line-height: 2.7rem;
    }
    .pos3 .pager div {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #063a8a;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        margin-left: 30px;
    }
    .pos3 .tab {
        margin: 400px 0 0 0;
        width: 530px;
        font-size: 2rem;
        color: #fff;
    }
    .pos3 .tab li {
        margin: 40px 0;
        display: flex;
        cursor: pointer;
        align-items: center;
    }
}
</style>
