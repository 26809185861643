<template>
    <div>
        <Header>
            <template v-slot:left>
                <img class="logo" src="@/assets/logo.png" />
            </template>
        </Header>
        <Row bg="assets/mobile/home/pos1-bg.jpg" className="pos1">
            <img src="@/assets/mobile/home/pos1-txt.png" class="pos1-txt" />
            <div class="more"><MoreBtn bg="#007aff" color="#fff" url="/m/about">MORE</MoreBtn></div>
            <!-- <img src="@/assets/pc/home/pos1/huan.png" class="huan" />
            <img src="@/assets/pc/home/pos1/ball1.png" class="ball1" />
            <img src="@/assets/pc/home/pos1/ball2.png" class="ball2" /> -->
        </Row>
        <Row bg="assets/mobile/home/pos2-bg.jpg" className="pos2">
            <div class="more"><MoreBtn url="/service?p=pos1" :width="130">智慧营销</MoreBtn></div>
        </Row>
        <Row bg="assets/mobile/home/pos3-bg.jpg" className="pos3">
            <div class="more"><MoreBtn url="/service?p=pos2" :width="130">公关传播与品牌管理</MoreBtn></div>
            <!-- <ul>
                <li>品牌管理<br />与咨询</li>
                <li>公关传播</li>
                <li>社会化<br />媒体传播</li>
                <li>事件营销</li>
            </ul> -->
        </Row>
        <Row bg="assets/mobile/home/pos8-bg.jpg" className="pos4">
            <div class="more"><MoreBtn url="/service?p=pos5" :width="130">数字文化</MoreBtn></div>
        </Row>
        <Row bg="assets/mobile/home/pos4-bg.jpg" className="pos4">
            <div class="more"><MoreBtn url="/service?p=pos3" :width="130">算力运营</MoreBtn></div>
        </Row>
        <Row bg="assets/mobile/home/pos5-bg.jpg" className="pos5">
            <div class="more"><MoreBtn url="/service?p=pos4" :width="130">文化创新</MoreBtn></div>
        </Row>
        <Row className="pos6" bgColor="#f3f3f3">
            <h1>党的建设</h1>
            <p class="info">CPC AFFAIRS</p>

            <ul>
                <li>
                    <router-link :to="'/m/jiandang/detail?id=' + jiandang.id">
                        <img src="@/assets/mobile/home/jiandang-cover.jpg" />
                        <div style="background: rgba(151, 12, 9,0.6);">{{ jiandang.title }}</div>
                    </router-link>
                </li>
            </ul>
            <router-link to="/m/jiandang" class="more" style="background: #d2d2d2;color: #000;">
                查看更多
            </router-link>
        </Row>
        <Row className="pos6">
            <h1>行业洞察</h1>
            <p class="info">Industry Insights</p>
            <p class="info">洞悉行业前沿趋势 探索行业发展新机遇</p>
            <ul>
                <li v-for="item in news" :key="item.id">
                    <router-link :to="'/m/news/detail?id=' + item.id">
                        <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_601,h_383'" />
                        <div>{{ item.title }}</div>
                    </router-link>
                </li>
            </ul>
            <router-link to="/m/news" class="more" style="background: #d2d2d2;color: #000;">
                查看更多
            </router-link>
        </Row>

        <Row className="pos7">
            <div class="bg">
                <h1>关于浙文</h1>
                <p class="info">About Zhewen Interactive</p>
                <p class="desc">
                    浙文互联集团股份有限公司于2004年在上海证券交易所上市，股票代码600986.SH，2021年浙江省文化产业投资集团有限公司成为控股股东，公司正式更名为浙文互联集团股份有限公司。浙文互联连续数年营收逾百亿，位居行业三甲之列，业务涉及汽车营销、精准营销、直播电商与跨境电商、出海业务、智算业务、智慧内容生态、数字文化创新等多个领域。<br />
                    在数字营销领域，浙文互联长期稳居汽车营销行业NO.1，全面推进“AI+营销”智慧营销业务实践，深入品牌战略咨询与管理、营销与传播策略制定、执行，媒介投放、公关策略制定与传播管理、品牌与领导人声誉管理、精准营销与效果投放、直播电商与跨境电商、社群营销、体验营销等多个核心板块；在数字文化领域，浙文互联积极响应国家文化数字化战略，持续建设“AI+文化”，探索技术路线和实施路径，推进垂直领域AIGC应用开发、星声代数字人智枢平台、轻量数字人应用—数眸、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断积累文化领域数字资产，构建AI智慧内容生态；在算力运营领域，浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座与汽车品牌智算中心，充分发挥算力生产力的重要作用。<br />
                    目前，浙文互联旗下拥有百孚思、浙文天杰、派瑞威行、同立传播、智阅网络、华邑、好奇莫斯、浙文智算、浙文米塔、浙安果合、开心宇宙等多个子品牌。战略投资LGD电竞俱乐部、豆神教育、数文交所等数字文化创新领域。职场覆盖北京、杭州、上海、广州、重庆，全国员工规模逾千人。
                </p>
                <img class="icon" src="@/assets/mobile/home/pos7-icon.png" />
                <div class="brand-tit">
                    <h3>旗下品牌</h3>
                </div>
                <ul>
                    <li>
                        <a target="_blank" href="http://www.iforce-media.com/"><img src="@/assets/mobile/home/brand/iforce.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank" href="http://www.amaxz.com.cn/"><img src="@/assets/mobile/home/brand/amaxz.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank" href="http://www.aspiration-cn.com"><img src="@/assets/mobile/home/brand/pairui.jpg"/></a>
                    </li>

                    <li>
                        <router-link to="/about/tongli"><img src="@/assets/mobile/home/brand/tongli.jpg" /> </router-link>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.qctt.cn"><img src="@/assets/mobile/home/brand/zywl.jpg"/></a>
                    </li>
                    <li>
                        <router-link to="/about/huayi"><img src="@/assets/mobile/home/brand/huayi.jpg"/></router-link>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.hqmoss.com"><img src="@/assets/mobile/home/brand/hqms.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank"><img src="@/assets/mobile/home/brand/zwzs.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://mita.com"><img src="@/assets/mobile/home/brand/mt.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://guohekeji.xinpianchang.com"><img src="@/assets/mobile/home/brand/zagh.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank"><img src="@/assets/mobile/home/brand/ss.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank"><img src="@/assets/mobile/home/brand/ldg.jpg"/></a>
                    </li>
                    <li>
                        <a target="_blank"><img src="@/assets/mobile/home/brand/kxyz.jpg"/></a>
                    </li>
                </ul>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "../../components/mobile/Row.vue";
import MoreBtn from "../../components/mobile/MoreBtn.vue";
import settings from "@/settings";

export default {
    name: "moble-home",
    components: { Header, Footer, Row, MoreBtn },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            news: [],
            jiandang: {},
        };
    },

    mounted() {
        this.LoadNews();
        this.loadJiandang();
    },
    methods: {
        LoadNews() {
            this.$http.get("/api/news/mobile/home").then((res) => {
                this.news = res.data;
            });
        },
        loadJiandang() {
            this.$http.get("/api/index/jiandang").then((res) => {
                this.jiandang = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
.pos7 .bg {
    display: inline-block;
    width: 100%;
    background: url("~@/assets/mobile/home/pos7-bg.jpg") no-repeat top center;
    background-size: 100% auto;
}
.pos7 h1 {
    font-size: 2.7rem;
    margin: 30px 0 5px 0;
}
.pos7 .info {
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.pos7 .desc {
    font-size: 1.3rem;
    color: #838587;
    margin: 5% 7% 5% 7%;
    text-align: justify;
    line-height: 2.2rem;
    word-break: break-all;
}
.pos7 .icon {
    width: 75%;
}
.pos7 .brand-tit {
    margin: 5% 0 5% 0;
}
.pos7 .brand-tit h3 {
    font-size: 1.8rem;
    font-weight: normal;
    color: #333333;
    border-bottom: 2px #ffbd1d solid;
    display: inline-block;
    padding-bottom: 3px;
}
.pos7 ul {
    margin: 5% 5% 10% 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto, auto);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}
.pos7 ul li {
    width: 100%;
}

.pos6 h1 {
    font-size: 2.7rem;
    margin: 30px 0 5px 0;
}
.pos6 .info {
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.pos6 ul {
    margin: 20px 5%;
}
.pos6 ul li {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
}
.pos6 ul li div {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    background: rgba(31, 31, 31, 0.4);
    text-align: left;
}
.pos6 .more {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.pos5 .more {
    margin-top: 51%;
}
.pos4 .more {
    margin-top: 51%;
}
.pos3 ul {
    display: flex;
    justify-content: space-between;
    margin: 13% 2%;
    height: 15%;
}
.pos3 ul li {
    flex: 1;
    background: #f8c54d;
    margin: 0 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}
.pos3 .more {
    margin-top: 50%;
}
.pos2 .more {
    margin-top: 50%;
}
.pos1 {
    position: relative;
}
.pos1-txt {
    width: 55%;
    margin-top: 37%;
}
.pos1 .huan {
    position: absolute;
    left: 8%;
    right: 8%;
    top: 47%;
    width: 84%;
    animation: bounce 3s linear forwards infinite;
}
.pos1 .ball1 {
    position: absolute;
    top: 43%;
    right: 15%;
    width: 12%;
    animation: bounce-2 3s linear forwards infinite;
}

.pos1 .ball2 {
    position: absolute;
    top: 83%;
    left: 3%;
    width: 18%;
    animation: bounce-2 3s linear forwards infinite;
}
.pos1 .more {
    margin-top: 6%;
}
@keyframes hand_anim {
    100% {
        transform: translate(0, 0);
    }
}
</style>
