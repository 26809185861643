<template>
    <div>
        <Header> </Header>
        <Row className="pos1">
            <img src="@/assets/mobile/service/pos1-bg.jpg" />
            <h4>AI+营销&nbsp;&nbsp;&nbsp;构建全链路智慧营销矩阵</h4>
            <p>
                浙文互联凭借数十年营销行业服务经验、数据积累，推进AI技术与业务实践深度融合，打造行业垂类应用模型，实现内容智能化生产，赋能数字营销、数字广告、社群营销、电商运营等业务板块，全面提升业务效能，构建全链路智慧营销矩阵，为客户提供跨平台、多场景的智慧营销解决方案，助力品牌价值与市场影响力的深度拓展。
            </p>
        </Row>
        <Row className="pos2">
            <img src="@/assets/mobile/service/pos2-bg.jpg" />
            <h4>全方位构建品牌传播体系<br />提升品牌商业价值</h4>
            <p>
                浙文互联专注于为客户构建一个全面而深入的品牌生态系统，通过整合品牌策略与咨询服务、公关传播、品牌声誉管理、危机管理、社交媒体推广传播、事件营销等多个增长维度，助力客户精炼品牌战略、定位品牌价值基石，推动品牌在全渠道的影响力与价值跃升。
            </p>
        </Row>
        <Row className="pos2 pos5">
            <img src="@/assets/mobile/service/pos5-bg.jpg" />
            <h4>AI与文化碰撞&nbsp;&nbsp;&nbsp;引领数字文化新生态</h4>
            <p>
                浙文互联作为浙江省文投集团旗下的「数字文化主平台」，积极响应国家文化数字化战略，持续探索技术路线和实施路径，推进垂直领域AIGC应用开发、AI数字人生产平台、轻量数字人应用、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断发展AI+文化，积累文化领域数字资产，构建AI智慧内容生态。
            </p>
        </Row>
        <Row className="pos3">
            <img src="@/assets/mobile/service/pos3-bg.jpg" />
            <h4>激活算力潜能&nbsp;&nbsp;&nbsp;筑造数字经济新基石</h4>
            <p>
                浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座，充分发挥算力生产力在文化数字化领域的重要作用。
            </p>
        </Row>
        <Row className="pos4">
            <img src="@/assets/mobile/service/pos4-bg.jpg" />
            <h4>多元业态布局&nbsp;&nbsp;&nbsp;开辟文化产业新篇章</h4>
            <p>
                浙文互联积极进行多元业务布局，拓展电子竞技、元宇宙虚拟资产、数字资产、AI教育等领域，开辟文化产业创新发展的新篇章。在文化教育领域，浙文互联入股中国大语文教育体系的开创企业——豆神教育，共同构建一个开放、共享、多元的教育生态，为中华文化在全球范围内的传播与发扬贡献力量。
            </p>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "../../components/mobile/Row.vue";
export default {
    name: "mobile-service",
    components: { Header, Footer, Row },
    data() {
        return {};
    },
    mounted() {
        var pos = this.$route.query.p;
        if (!pos) {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            return;
        } else {
            this.$nextTick(() => {
                document.querySelector("." + this.$route.query.p).scrollIntoView();
            });
        }
    },
};
</script>
<style scoped>
.pos4 h4 {
    font-size: 1.5rem;
    margin: 30px 15px;
    font-weight: normal;
}
.pos4 p {
    color: #666666;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin: 0 15px 40px 15px;
    text-align: justify;
}
.pos3 h4 {
    font-size: 1.5rem;
    margin: 30px 15px;
    font-weight: normal;
}
.pos3 p {
    color: #666666;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin: 0 15px 40px 15px;
    text-align: justify;
}
.pos2 h4 {
    font-size: 1.5rem;
    margin: 30px 15px;
    font-weight: normal;
}
.pos2 p {
    color: #666666;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin: 0 15px 40px 15px;
    text-align: justify;
}
.pos1 h4 {
    font-size: 1.5rem;
    margin: 30px 0;
    font-weight: normal;
}
.pos1 p {
    color: #666666;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin: 0 15px 40px 15px;
    text-align: justify;
}
</style>
