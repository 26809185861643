<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/service/digital_culture/p1_bg.jpg"> </Row>
        <Row height="900" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/service/digital_culture/pos2_banner.png" />
                <h1>AI与文化碰撞&nbsp;&nbsp;&nbsp;引领数字文化新生态</h1>
                <p>浙文互联作为浙江省文投集团旗下的「数字文化主平台」，积极响应国家文化数字化战略，持续探索技术路线和实施路径，推进垂直领域AIGC应用开发、AI数字人生产平台、轻量数字人应用、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断发展AI+文化，积累文化领域数字资产，构建AI智慧内容生态。</p>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-service-spread",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
/* .pos2 p {
    font-size: 1.8rem;
    line-height: 5rem;
} */

@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 110px;
        /* width: calc(900px * var(--scale)); */
        height: 200px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 100px 0 60px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: left;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 130px;
        /* width: 900px; */
        height: 250px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 160px 0 80px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: center;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
