<template>
    <div class="mobile-row" :style="`height:${height};${bgColor ? 'background:' + bgColor : ''}`">
        <template v-if="bg">
            <img :src="require('@/' + bg)" />
            <div class="row-wrap">
                <div class="wrap" :class="className">
                    <slot></slot>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="wrap" :class="className">
                <slot></slot>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: "mobile-row",
    props: {
        height: {
            type: String,
            default: "auto",
        },
        bg: {
            type: String,
            default: "",
        },
        bgColor: { type: String, default: "" },
        className: {
            type: String,
            default: "",
        },
    },
};
</script>
<style scoped>
.mobile-row {
    width: 100%;
    position: relative;
}
.mobile-row .row-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}
.mobile-row .wrap {
    width: 100%;
    height: 100%;
    text-align: center;
    display: inline-block;
}
</style>
