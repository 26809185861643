<template>
    <div class="container notice">
        <Header :showBack="true"></Header>

        <InvestRow>
            <template v-slot:title>公司公告</template>
            <div class="content" v-html="notice"></div>
            <div class="top-btn-wrap">
                <div @click="backtop" class="top-btn">回到顶部</div>
            </div>
        </InvestRow>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/mobile/header.vue";
import Footer from "@/components/mobile/footer.vue";
import InvestRow from "@/components/mobile/InvestRow.vue";
export default {
    name: "pc-invest-notice",
    components: { Header, Footer, InvestRow },
    data() {
        return {
            notice: "",
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData(1);
    },
    methods: {
        backtop() {
            var timer = setInterval(function() {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 10);
        },
        LoadData() {
            this.$http.get(`/api/invest/notice`).then((res) => {
                this.notice = res.data.notice;
            });
        },
    },
};
</script>
<style scoped>
.notice {
    background: #f5f6f8;
}
.content {
    margin: 20px 15px;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #666666;
}
.top-btn-wrap {
    text-align: center;
}
.top-btn {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px 0;
}
</style>
