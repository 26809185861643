<template>
    <div class="invest-row-wrap" :style="'background:' + bg">
        <div class="tit">
            <div>
                <slot name="title"></slot>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <!-- <router-link v-if="showMore" class="more" :to="url"><img class="pc-more" src="@/assets/pc/home/more-blue2.png"/></router-link> -->
    </div>
</template>
<script>
export default {
    name: "mobile_invest_row",
    props: {
        bg: {
            type: String,
            default: "#fff",
        },
        title: {
            type: String,
            default: "",
        },
        showMore: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
            default: "",
        },
    },
};
</script>
<style scoped>
.invest-row-wrap {
    margin: 15px 15px;
}

.invest-row-wrap .tit {
    width: 100%;
    height: 35px;
    background: #dfe8f3;
    display: flex;
    align-items: center;
    color: #222;
    font-size: 1.4rem;
    font-weight: normal;
    text-indent: 1.5rem;
}

.invest-row-wrap .content {
    min-height: 100px;
}

@media only screen and (max-width: 2048px) {
    .more {
        position: absolute;
        right: 145px;
        height: 40px;
        bottom: -20px;
    }
}

@media only screen and (min-width: 2049px) {
    .more {
        position: absolute;
        right: 145px;
        height: 50px;
        bottom: -25px;
    }
}
</style>
