<template>
    <div class="container">
        <Header></Header>
        <Row bg="assets/mobile/contact/pos1-bg.jpg"> </Row>
        <div class="map pos2">
            <div class="tab">
                <div class="item" :class="map == 0 ? 'active' : ''" @click="tabHandle(0)">
                    <div>北京</div>
                    <span></span>
                </div>
                <div class="item" :class="map == 1 ? 'active' : ''" @click="tabHandle(1)">
                    <div>杭州</div>
                    <span></span>
                </div>
                <div class="item" :class="map == 2 ? 'active' : ''" @click="tabHandle(2)">
                    <div>上海</div>
                    <span></span>
                </div>
                <div class="item" :class="map == 3 ? 'active' : ''" @click="tabHandle(3)">
                    <div>广州</div>
                    <span></span>
                </div>
                <div class="item" :class="map == 4 ? 'active' : ''" @click="tabHandle(4)">
                    <div>重庆</div>
                    <span></span>
                </div>
            </div>
            <div class="map-img">
                <img :src="require('@/assets/mobile/contact/map' + map + '.jpg')" />
            </div>

            <!-- <div class="tab">
                <p v-html="contact[map]"></p>
            </div> -->
        </div>
        <ul class="tel">
            <li>
                <div>商务合作</div>
                <p>010-87835788</p>
                <p>info@zwhlgroup.com</p>
            </li>
            <li>
                <div>媒体垂询</div>
                <p>010-87835799</p>
                <p>info@zwhlgroup.com</p>
            </li>
            <li>
                <div>投资者关系</div>
                <p>010-87835799</p>
                <p>info@zwhlgroup.com</p>
            </li>
            <li>
                <div>人才招聘</div>
                <p>010-87835788</p>
                <p>info@zwhlgroup.com</p>
            </li>
        </ul>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/mobile/header.vue";
import Footer from "@/components/mobile/footer.vue";
import Row from "@/components/mobile/Row.vue";
export default {
    name: "mobile-contact",
    components: { Header, Footer, Row },
    data() {
        return {
            map: 0,
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        tabHandle(idx) {
            this.map = idx;
        },
        OnChangePageHandle() {},
    },
};
</script>
<style scoped>
.tel {
    margin: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tel li {
    font-size: 1.4rem;
    text-align: left;
    background: #f9b924;
    width: 47%;
    margin: 5px 0;
    padding-bottom: 15px;
}
.tel li div {
    font-size: 1.2rem;
    font-weight: normal;
    border-left: 3px #222 solid;
    line-height: 1.2rem;
    padding-left: 5px;
    width: 100%;
    margin: 15px 15px;
    display: inline-block;
}
.tel li p {
    text-indent: 24px;
    margin: 0px 0 0 0;
    font-size: 1.2rem;
}
.map .tab p {
    font-size: 1.2rem;
    color: #333;
    line-height: 3rem;
    text-align: center;
    margin-top: 40px 40px 0 40px;
}
.map .tab ul li {
    font-size: 1.5rem;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
    border-bottom: 3px #cacaca solid;
    text-align: center;
    padding-bottom: 5px;
}

.map-img {
    margin: 0 15px;
}
.pos2 .tab {
    margin: 30px 15px 20px 15px;
    display: flex;
    border-bottom: 1px #c1c1c1 solid;
}
.pos2 .tab .item {
    display: inline-flex;
    color: #333333;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
}
.pos2 .tab .item div {
    width: 100%;
    border-bottom: 2px transparent solid;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 0;
}
.pos2 .tab .item span {
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 3px;
    height: 3px;
    background: #333333;
    transform: rotate(45deg);
    margin-left: -2px;
    display: none;
}

.pos2 .tab .active div {
    font-weight: bold;
    border-bottom: 2px #333333 solid;
}
.pos2 .tab .active span {
    display: inline-block;
}
</style>
