<template>
    <div>
        <Header> </Header>
        <Row>
            <img src="@/assets/mobile/about/pos1-bg.jpg" />
        </Row>
        <Row className="pos2">
            <img class="tit" src="@/assets/mobile/about/pos2-tit.png" />
            <p>
                浙文互联集团股份有限公司2004年在上海证券交易所上市，股票代码600986.SH，2021年浙江省文化产业投资集团有限公司成为控股股东，公司正式更名为浙文互联集团股份有限公司。浙文互联连续数年营收逾百亿，位居行业三甲之列，业务涉及汽车营销、精准营销、直播电商与跨境电商、出海业务、智算业务、智慧内容生态、数字文化创新等多个领域。<br />
                在数字营销领域，浙文互联长期稳居汽车营销行业NO.1，全面推进“AI+营销”智慧营销业务实践，深入品牌战略咨询与管理、营销与传播策略制定、执行，媒介投放、公关策略制定与传播管理、品牌与领导人声誉管理、精准营销与效果投放、直播电商与跨境电商、社群营销、体验营销等多个核心板块；在数字文化领域，浙文互联积极响应国家文化数字化战略，持续建设“AI+文化”，探索技术路线和实施路径，推进垂直领域AIGC应用开发、星声代数字人智枢平台、轻量数字人应用—数眸、汽车元宇宙、文旅元宇宙、MR星计划等业务新蓝海，不断积累文化领域数字资产，构建AI智慧内容生态；在算力运营领域，浙文互联依托浙江文投集团国资背景和投融资优势，借力浙江省数字经济与数字文化的先发优势，聚焦算力贸易、算力租赁、算力调度、算力运维等业务，重点建设运营国内数字文化算力底座与汽车品牌智算中心，充分发挥算力生产力的重要作用。<br />
                目前，浙文互联旗下拥有百孚思、浙文天杰、派瑞威行、同立传播、智阅网络、华邑、好奇莫斯、浙文智算、浙文米塔、浙安果合、开心宇宙等多个子品牌。战略投资LGD电竞俱乐部、豆神教育、数文交所等数字文化创新领域。职场覆盖北京、杭州、上海、广州、重庆，全国员工规模逾千人。
            </p>
        </Row>
        <div class="pos3">
            <div class="tab">
                <div class="item" :class="honorTabIdx == 1 ? 'active' : ''" @click="HonorTabHandle(1)">
                    <div>行业地位</div>
                    <span></span>
                </div>
                <div class="item" :class="honorTabIdx == 2 ? 'active' : ''" @click="HonorTabHandle(2)">
                    <div>荣誉奖项</div>
                    <span></span>
                </div>
            </div>
            <ul v-if="honorTabIdx == 1" class="standing">
                <li v-for="item in standing" :key="item.id">
                    <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_300,h_250'" />
                </li>
            </ul>
            <ul v-if="honorTabIdx == 2" class="honor">
                <li v-for="item in honor" :key="item.id">
                    <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_300,h_300'" />
                </li>
            </ul>
        </div>
        <Row bg="assets/mobile/about/pos4-bg.jpg" className="pos4">
            <ul>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="http://www.iforce-media.com/"><img src="@/assets/mobile/home/brand/iforce.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="http://www.amaxz.com.cn/"><img src="@/assets/mobile/home/brand/amaxz.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="http://www.aspiration-cn.com"><img src="@/assets/mobile/home/brand/pairui.jpg"/></a>
                        </div>
                    </div>
                </li>

                <li>
                    <div>
                        <div>
                            <router-link to="/m/about/tongli"><img src="@/assets/mobile/home/brand/tongli.jpg"/></router-link>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="https://www.qctt.cn"><img src="@/assets/mobile/home/brand/zywl.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <router-link to="/m/about/huayi"><img src="@/assets/mobile/home/brand/huayi.jpg"/></router-link>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="https://www.hqmoss.com"><img src="@/assets/mobile/home/brand/hqms.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank"><img src="@/assets/mobile/home/brand/zwzs.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="https://mita.com"><img src="@/assets/mobile/home/brand/mt.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank" href="https://guohekeji.xinpianchang.com"><img src="@/assets/mobile/home/brand/zagh.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank"><img src="@/assets/mobile/home/brand/ss.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank"><img src="@/assets/mobile/home/brand/ldg.jpg"/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div>
                            <a target="_blank"><img src="@/assets/mobile/home/brand/kxyz.jpg"/></a>
                        </div>
                    </div>
                </li>
            </ul>
        </Row>
        <Row className="pos5">
            <img src="@/assets/mobile/about/pos5-tit.png" class="tit" />
            <ul class="tab">
                <li :class="pos5TabIdx == 1 ? 'active' : ''" @click="Pos5TabHandle(1)">
                    <div>汽车行业</div>
                    <span class="iconfont icon-triangle"></span>
                </li>
                <li :class="pos5TabIdx == 2 ? 'active' : ''" @click="Pos5TabHandle(2)">
                    <div>快消及金融行业</div>
                    <span class="iconfont icon-triangle"></span>
                </li>
                <li :class="pos5TabIdx == 3 ? 'active' : ''" @click="Pos5TabHandle(3)">
                    <div>互联网IT及电商</div>
                    <span class="iconfont icon-triangle"></span>
                </li>
                <li :class="pos5TabIdx == 4 ? 'active' : ''" @click="Pos5TabHandle(4)">
                    <div>休闲娱乐</div>
                    <span class="iconfont icon-triangle"></span>
                </li>
            </ul>
            <div class="detail" v-if="customer">
                <img v-show="pos5TabIdx == 1" :src="resourceBaseUrl + customer.mobileAuto" />
                <img v-show="pos5TabIdx == 2" :src="resourceBaseUrl + customer.mobileFmcg" />
                <img v-show="pos5TabIdx == 3" :src="resourceBaseUrl + customer.mobileIt" />
                <img v-show="pos5TabIdx == 4" :src="resourceBaseUrl + customer.mobileAmusement" />
            </div>
        </Row>

        <Row className="pos6" v-if="false">
            <div class="wrap">
                <img src="@/assets/mobile/about/pos6-tit.png" class="tit" />
                <div class="swiper">
                    <carousel-3d :perspective="0" :autoplayHoverPause="true" :width="clientWidth * 0.4" :data-w="clientWidth * 0.4" :height="(clientWidth * 0.4 * 421) / 296" :data-h="(clientWidth * 0.4 * 421) / 296" :space="clientWidth * 0.45" :border="0" :display="3" :controlsVisible="true" controlsNextHtml="" controlsPrevHtml="" @after-slide-change="onSlideChange">
                        <slide :index="idx" v-for="(item, idx) in leader" :key="idx">
                            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                                <div class="swiper-item">
                                    <img :data-index="index" :class="{ current: isCurrent, onLeft: leftIndex >= 0, onRight: rightIndex >= 0 }" :src="require('@/assets/mobile/about/leader/' + (idx + 1) + '.jpg')" />
                                </div>
                            </template>
                        </slide>
                    </carousel-3d>
                    <h5>{{ leader[leaderSwiperIdx].name }}</h5>
                    <p v-html="leader[leaderSwiperIdx].desc"></p>
                </div>
                <ul class="pager">
                    <li v-for="(item, idx) in leader" :key="idx" :class="idx == leaderSwiperIdx ? 'active' : ''"></li>
                </ul>
            </div>
        </Row>

        <Footer></Footer>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "../../components/mobile/Row.vue";
// import MoreBtn from "../../components/mobile/MoreBtn.vue";
import settings from "@/settings";
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
    name: "mobile-about",
    components: { Header, Footer, Row, Carousel3d, Slide },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            standing: [],
            honor: [],
            honorTabIdx: 1,
            pos5TabIdx: 1,
            customer: null,
            clientWidth: document.body.clientWidth,
            leader: [
                {
                    name: "唐  颖",
                    desc: "浙文互联集团董事长兼CEO、<br>百孚思创始人",
                },
                {
                    name: "张  磊",
                    desc: "浙文互联集团总经理、<br> 百孚思CEO ",
                },
                {
                    name: "易  星",
                    desc: "浙文互联集团联席总经理、<br> 派瑞威行CEO",
                },
                {
                    name: "吴瑞敏",
                    desc: "浙文互联集团联席总经理、<br> 爱创营销与传播CEO",
                },
                {
                    name: "王颖轶",
                    desc: "浙文互联集团副经理、<br> 董事会秘书 ",
                },
                {
                    name: "李  磊",
                    desc: "浙文互联集团副总经理、 <br> 汽车业务部总经理",
                },
                {
                    name: "孟  娜",
                    desc: "浙文互联集团副总经理",
                },
            ],
            leaderSwiperIdx: 0,
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.loadHonor();
        this.LoadCustomer();
    },
    methods: {
        onSlideChange(e) {
            this.leaderSwiperIdx = e;
        },
        Pos5TabHandle(idx) {
            this.pos5TabIdx = idx;
        },
        HonorTabHandle(idx) {
            this.honorTabIdx = idx;
        },
        loadHonor() {
            this.$http.get("/api/honor/list").then((res) => {
                this.honor = res.data.filter((item) => item.type == 2);
                this.standing = res.data.filter((item) => item.type == 1);
            });
        },
        LoadCustomer() {
            this.$http.get("/api/customer/config").then((res) => {
                this.customer = res.data;
            });
        },
    },
};
</script>

<style scoped>
.pos6 .pager {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.pos6 .pager li {
    width: 15px;
    height: 2px;
    background: #939393;
    margin: 0 3px;
    border-radius: 1px;
}
.pos6 .pager .active {
    background: #333333;
}
.pos6 .swiper {
    margin: 30px 15px 0 15px;
}
.pos6 h5 {
    font-size: 1.6rem;
    text-align: center;
    margin: 10px 0;
}
.pos6 p {
    text-align: center;
    font-size: 1.2rem;
    white-space: nowrap;
    margin-bottom: 30px;
}
.pos6 .tit {
    width: 64%;
    margin-top: 35px;
}
.pos6 .wrap {
    background: #f3f3f3;
    width: 100%;
}

.pos5 .detail {
    margin: 0 15px 30px 15px;
}
.pos5 .tit {
    width: 31%;
    margin: 40px auto 30px auto;
}
.pos5 .tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 15px 10px 15px;
}
.pos5 .tab li {
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.pos5 .tab li span {
    color: #f9b924;
    opacity: 0;
    margin-top: -5px;
    font-size: 0.6rem;
    line-height: 1.2rem;
}
.pos5 .tab li div {
    border-bottom: 2px #fff solid;
    padding-bottom: 3px;
    line-height: 1.7rem;
}
.pos5 .tab .active {
    color: #f9b924;
    font-weight: bold;
}
.pos5 .tab .active div {
    border-color: #f9b924;
}
.pos5 .tab .active span {
    opacity: 1;
}
.pos4 ul {
    margin: 35% 15px 0 15px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto, auto);
    grid-row-gap: 7px;
    grid-column-gap: 7px;
}
.pos4 ul li {
    width: 100%;
    background: #fff;
    display: inline-block;
}
.pos4 ul li > div {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}
.pos4 ul li > div > div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pos3 .honor,
.pos3 .standing {
    margin: 10px 15px 30px 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.pos3 .honor li {
    width: 22%;
    margin: 6px 0;
}
.pos3 .standing li {
    width: 48.5%;
    margin: 6px 0;
}
.pos3 .tab {
    margin: 140px 15px 20px 15px;
    display: block;
    border-bottom: 1px #a3c6ec solid;
}
.pos3 .tab .item {
    width: 50%;
    display: inline-flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pos3 .tab .item div {
    width: 70%;
    border-bottom: 2px transparent solid;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 0;
}
.pos3 .tab .item span {
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 3px;
    height: 3px;
    background: #fff;
    transform: rotate(45deg);
    margin-left: -2px;
    display: none;
}

.pos3 .tab .active div {
    font-weight: bold;
    border-bottom: 2px #fff solid;
}
.pos3 .tab .active span {
    display: inline-block;
}
.pos3 {
    background-image: url("~@/assets/mobile/about/pos3-bg.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    background-color: #5485c4;
    min-height: 500px;
    display: inline-block;
    width: 100%;
}
.pos2 .tit {
    width: 50%;
    margin: 30px 0;
}
.pos2 p {
    margin: 0 25px 30px 25px;
    text-align: justify;
    color: #838587;
    font-size: 1.2rem;
    line-height: 2rem;
}
</style>
