<template>
    <div class="pc-video-wrap">
        <div>
            <i class="iconfont icon-close" @click="$emit('closed')"></i>
            <video class="video-js" ref="videoPlayer"></video>
        </div>
    </div>
</template>
<script>
import videojs from "video.js";
export default {
    name: "pc-video",
    props: {
        src: {
            type: String,
            default: "",
        },
    },
    components: {},
    data() {
        return {
            videoOptions: {
                autoplay: true,
                controls: true,
                liveui: false,
                controlBar: {
                    // liveDisplay: true,
                    progressControl: true,
                    remainingTimeDisplay: false,
                    pictureInPictureToggle: false,
                },
                sources: [
                    {
                        src: this.src,
                        type: "video/mp4",
                    },
                ],
            },
        };
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {});
    },
};
</script>
<style scoped>
@import url("../../../node_modules/video.js/dist/video-js.min.css");
.pc-video-wrap {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    animation: showVideo 0.3s ease-in forwards;
    transform: scale(0.3);
    opacity: 0;
}
.pc-video-wrap > div {
    position: relative;
    width: 100%;
    height: 100%;
}
.icon-close {
    font-size: 4rem;
    color: #ccc;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999999;
    cursor: pointer;
}
@keyframes showVideo {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
