<template>
    <div class="container pc-news">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="900" bg="assets/pc/jiandang/bg.jpg" :bgScale="1"> </Row>
        <Row bgColor="#f5f6f8">
            <div class="pos2" v-if="detail">
                <div class="head">
                    <div @click="$router.back()"><i class="iconfont icon-back1"></i>返回</div>
                    <div>
                        <!-- <i class="iconfont icon-share"></i>分享 -->
                    </div>
                    <!-- 党的建设 » {{ detail.detail.title }} -->
                </div>
                <div class="content">
                    <h1>{{ detail.detail.title }}</h1>
                    <div class="content-wrap" v-html="detail.detail.content"></div>
                </div>
                <div class="tools">
                    <router-link v-if="detail.prev" :to="'/jiandang/detail?id=' + detail.prev.id">上一篇<i class="iconfont icon-back"></i></router-link>
                    <a v-else></a>
                    <router-link v-if="detail.next" :to="'/jiandang/detail?id=' + detail.next.id"><i class="iconfont icon-back"></i>下一篇</router-link>
                    <a v-else></a>
                </div>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-jiandang-detail",
    components: { Nav, Footer, Row },
    data() {
        return { loading: false, resourceBaseUrl: settings.resourceBaseUrl, detail: null };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.loadDetail();
    },
    watch: {
        $route() {
            this.loadDetail();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
    },
    methods: {
        loadDetail() {
            var id = this.$route.query.id;
            this.$showLoading();
            this.$http.get(`/api/jiandang/detail/${id}`).then((res) => {
                this.detail = res.data;
                this.$forceUpdate();
            });
        },
    },
};
</script>
<style scoped>
/* @import url("../../../common/css/pc.css"); */

.tools a:hover {
    text-decoration: underline;
}
.tools a i {
    margin: 0 5px;
    font-weight: bold;
    display: inline-block;
}
.tools a:last-child i {
    transform: rotate(180deg);
}

.pos2 {
    width: 100%;
    display: inline-block;
}

@media only screen and (max-width: 2048px) {
    .content {
        width: 100%;
        color: #000;
        margin: 40px 0;
        display: block;
        text-align: justify;
    }
    .content h1 {
        font-size: 2.7rem;
        font-weight: normal;
        margin-bottom: 40px;
    }
    .content .content-wrap {
        font-size: 1.4rem;
        line-height: 3rem;
    }
    .pos2 .head {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin: 70px 0;
    }
    .pos2 .head div {
        display: flex;
        align-items: center;
        color: #686868;
        font-size: 1.6rem;
        cursor: pointer;
        line-height: 1.6rem;
    }
    .pos2 .head div i {
        font-size: 1.6rem;
        margin-right: 3px;
    }
    .tools {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;
    }
    .tools a {
        cursor: pointer;
        font-size: 1.6rem;
        color: #222;
    }
}

@media only screen and (min-width: 2049px) {
    .content {
        width: 100%;
        color: #000;
        margin: 50px 0;
        display: block;
        text-align: justify;
    }
    .content h1 {
        font-size: 3rem;
        font-weight: normal;
        margin-bottom: 40px;
    }
    .content .content-wrap {
        font-size: 1.5rem;
        line-height: 3rem;
    }
    .pos2 .head {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin: 70px 0;
    }
    .pos2 .head div {
        display: flex;
        align-items: center;
        color: #686868;
        font-size: 1.6rem;
        cursor: pointer;
        line-height: 1.6rem;
    }
    .pos2 .head div i {
        font-size: 1.6rem;
        margin-right: 3px;
    }
    .tools {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;
    }
    .tools a {
        cursor: pointer;
        font-size: 1.6rem;
        color: #222;
    }
}
</style>
