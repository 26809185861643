<template>
    <div class="footer-container">
        <div class="logo">
            <img src="@/assets/logo-white.png" />
        </div>
        <ul>
            <li>
                <router-link to="/m/about">关于浙文</router-link>
            </li>
            <li class="line"></li>
            <li>
                <router-link to="/m/service">核心业务</router-link>
            </li>
            <li class="line"></li>
            <li>
                <router-link to="/m/news">动态与洞察</router-link>
            </li>
            <li class="line"></li>
            <li>
                <router-link to="/m/invest">投资者关系</router-link>
            </li>
            <li class="line"></li>
            <li>
                <router-link to="/m/job">职在浙里</router-link>
            </li>
            <li class="line"></li>
            <li>
                <router-link to="/m/contact">联系我们</router-link>
            </li>
        </ul>
        <div class="cr">浙文互联集团股份有限公司版权所有 <br />COPYRIGHT ZWHL-GROUP <a href="http://beian.miit.gov.cn" target="_blank">浙ICP备2024088348号</a></div>
    </div>
</template>

<script>
export default {
    name: "pc-footer",
    components: {},

    data() {
        return {};
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");

.footer-container {
    background: #1fb1f1;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 0;
}
.footer-container .logo {
    margin: 15px 15px;
}
.footer-container .logo img {
    width: 30%;
}
.footer-container ul {
    margin: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.footer-container ul li {
    line-height: 1.2rem;
}
.footer-container ul a {
    font-size: 1.2rem;
    line-height: 1.2rem;
}
.footer-container ul .line {
    border-right: 1px #fff solid;
    height: 1rem;
    margin-top: 1px;
}
.footer-container .cr {
    margin: 30px 15px 20px 15px;
    color: #fff;
    font-size: 1.2rem;
    line-height: 2.2rem;
}
</style>
