<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/service/spread/p1_bg.jpg"> </Row>
        <Row height="950" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/service/spread/pos2_banner.png" />
                <h1>全方位构建品牌传播体系 提升品牌商业价值</h1>
                <p>浙文互联专注于为客户构建一个全面而深入的品牌生态系统，通过整合品牌策略与咨询服务、公关传播、品牌声誉管理、危机管理、社交媒体推广传播、事件营销等多个增长维度，助力客户精炼品牌战略、定位品牌价值基石，推动品牌在全渠道的影响力与价值跃升。</p>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-service-spread",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}

@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 110px;
        /* width: calc(1000px * var(--scale)); */
        height: 200px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 100px 0 60px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: left;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 130px;
        /* width: 1000px; */
        height: 250px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 160px 0 80px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: center;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
