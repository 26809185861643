<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/invest/p1_bg.jpg" :bgScale="1"> </Row>
        <Row bgColor="#f5f6f8">
            <div class="pos2">
                <div style="width:100%; height:100px;"></div>
                <InvestRow v-if="report && report.notice" url="/invest/notice">
                    <template v-slot:title>公司公告</template>
                    <div class="notice" v-html="report.notice.desc"></div>
                </InvestRow>
                <InvestRow url="/invest/list?t=1" v-if="report && report.periodicReport">
                    <template v-slot:title>定期报告</template>
                    <ul class="list">
                        <li v-for="(item, idx) in report.periodicReport" :key="idx">
                            <a :href="resourceBaseUrl + item.pdf" target="_blank">{{ item.title }}</a>
                            <div></div>
                            <span>{{ item.date }}</span>
                        </li>
                    </ul>
                </InvestRow>
                <InvestRow url="/invest/list?t=2" v-if="report && report.researchReport">
                    <template v-slot:title>公司研报</template>
                    <ul class="list">
                        <li v-for="(item, idx) in report.researchReport" :key="idx">
                            <a :href="resourceBaseUrl + item.pdf" target="_blank">{{ item.title }}</a>
                            <div></div>
                            <span>{{ item.date }}</span>
                        </li>
                    </ul>
                </InvestRow>
                <InvestRow :showMore="false">
                    <template v-slot:title>股票行情</template>
                    <div class="kline">
                        <KLine></KLine>
                    </div>
                </InvestRow>
                <InvestRow :showMore="false" margin="50px 60px">
                    <template v-slot:title>投资者联系方式</template>
                    <ul class="contact">
                        <li>证券部联系电话<br />010-87835799</li>
                        <li>证券部电子邮箱<br />info@zwhlgroup.com</li>
                        <li>
                            <a href="http://sns.sseinfo.com/company.do?uid=986" target="_blank">投资者互动<br />在线互动</a>
                        </li>
                    </ul>
                </InvestRow>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
import InvestRow from "@/components/pc/InvestRow.vue";
import KLine from "@/components/KLine.vue";
import settings from "@/settings";
export default {
    name: "pc-invest",
    components: { Nav, Footer, Row, InvestRow, KLine },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            report: null,
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$http.get("/api/invest/newest").then((res) => {
                this.report = res.data;
            });
        },
    },
};
</script>
<style scoped>
.contact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact li {
    width: calc(403px * var(--scale));
    height: calc(168px * var(--scale));
    background: #1a3a85;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: #fff;
    line-height: 4rem;
    font-weight: normal;
}
.kline {
    height: 500px;
}
.list {
    width: 100%;
}
.list li {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: normal;
    color: #4d4d4d;
    margin: 20px 0;
}
.list li div {
    flex: 1;
    border-bottom: 1px #ccc dashed;
    margin: 0 10px;
}
.list li a {
    font-weight: normal;
    font-size: 1.5rem;
    color: #4d4d4d;
}
.notice {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    line-height: 3.5rem;
    text-align: left;
}
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
.pos2 img {
    margin-top: 130px;
}
.pos2 h1 {
    font-size: 3rem;
    margin: 200px 0 80px 0;
}
.pos2 p {
    font-size: 2.3rem;
    line-height: 5rem;
}
</style>
