<template>
    <div class="container">
        <Header :showBack="true"></Header>
        <div class="pos2">
            <InvestRow bg="#f5f6f8" :showMore="false">
                <template v-slot:title>{{ titles[$route.query.t] }}</template>
                <div class="list">
                    <li v-for="item in data" :key="item.pdf">
                        <a :href="resourceBaseUrl + item.pdf" target="_blank">
                            <div>{{ item.title }}</div>
                            <span>{{ item.date }}</span></a
                        >
                    </li>
                </div>
                <div class="more-wrap">
                    <div to="/m/news" class="more-btn" v-if="!ended" @click="NextPageHandle">
                        查看更多
                    </div>
                </div>
            </InvestRow>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/mobile/header.vue";
import Footer from "@/components/mobile/footer.vue";
import InvestRow from "@/components/mobile/InvestRow.vue";
import settings from "@/settings";
export default {
    name: "pc-invest-list",
    components: { Header, Footer, InvestRow },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            titles: ["", "定期报告", "公司研报"],
            page: 0,
            pages: 0,
            total: 0,
            data: [],
            ended: false,
        };
    },

    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.LoadData();
    },

    methods: {
        backtop() {
            var timer = setInterval(function() {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 10);
        },
        NextPageHandle() {
            this.LoadData();
        },
        LoadData() {
            if (this.ended) return;
            this.$showLoading("加载更多...");
            this.$http.get(`/api/invest/list?type=${this.$route.query.t}&page=${this.page + 1}`).then((res) => {
                this.page = this.page + 1;
                this.pages = parseInt(res.data.totalPage);
                this.total = parseInt(res.data.totalCount);
                this.data = this.data.concat(res.data.contents);
                this.ended = res.data.contents.length < 10;
            });
        },
    },
};
</script>
<style scoped>
.more-wrap {
    text-align: center;
}
.container {
    background: #f5f6f8;
    display: inline-block;
    width: 100%;
}

.pos2 {
    margin: 20px 15px;
}
.list {
    width: 100%;
}
.list li {
    font-size: 1.5rem;
    font-weight: normal;
    color: #4d4d4d;
    margin: 20px 15px;
    border-bottom: 1px #eaeaea solid;
    padding-bottom: 20px;
}
.list li a {
    display: inline-block;
    width: 100%;
    text-align: left;
}
.list li a div {
    margin: 10 0;
    color: #666666;
    font-size: 1.2rem;
}
.list li a span {
    width: 100%;
    display: inline-block;
    font-weight: normal;
    font-size: 1.2rem;
    color: #666666;
}

.pos2 .more-btn {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px 0;
}
</style>
