<template>
    <footer>
        <div class="footer-nav-wrap">
            <div class="footer-nav">
                <div class="col">
                    <router-link to="/about">关于浙文</router-link>
                    <ul>
                        <li><router-link to="/about?p=pos2">· 浙文简介</router-link></li>
                        <li><router-link to="/about?p=pos3">· 行业荣誉</router-link></li>
                        <li><router-link to="/about?p=pos4">· 分子公司</router-link></li>
                        <li><router-link to="/about?p=pos5">· 服务客户</router-link></li>
                        <!-- <li><router-link to="/about?p=pos6">· 管理团队</router-link></li> -->
                    </ul>
                </div>
                <div class="col">
                    <a href="javascript:;">核心业务</a>
                    <ul>
                        <li><router-link to="/service/marketing">· 智慧营销</router-link></li>
                        <li><router-link to="/service/spread">· 公关传播与品牌管理</router-link></li>
                        <li><router-link to="/service/digital_culture">· 数字文化</router-link></li>
                        <li><router-link to="/service/content">· 算力运营</router-link></li>
                        <li><router-link to="/service/innovation">· 文化创新</router-link></li>
                    </ul>
                </div>
                <div class="col">
                    <router-link to="/news">动态与洞察</router-link>
                    <ul>
                        <li><router-link to="/news?t=1">· 集团动态</router-link></li>
                        <li><router-link to="/news?t=0">· 行业洞察</router-link></li>
                    </ul>
                </div>
                <div class="col">
                    <router-link to="/invest">投资者关系</router-link>
                    <ul>
                        <li><router-link to="/invest/notice">· 公司公告</router-link></li>
                        <li><router-link to="/invest/list?t=1">· 定期报告</router-link></li>
                        <li><router-link to="/invest/list?t=2">· 公司研报</router-link></li>
                    </ul>
                </div>
                <div class="col">
                    <router-link to="/job">职在浙里</router-link>
                    <ul>
                        <li><router-link to="/job?t=1">· 社会招聘</router-link></li>
                        <li><router-link to="/job?t=2">· 校园招聘</router-link></li>
                    </ul>
                </div>
                <div class="col">
                    <router-link to="/contact">联系我们</router-link>
                    <ul></ul>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="wrap">
                <div>
                    <router-link to="/?fm=1"><img src="@/assets/logo-white.png" style="width:140px;"/></router-link>
                    <span>浙文互联集团股份有限公司版权所有 Copyright Zwhl-Group <a href="http://beian.miit.gov.cn" target="_blank">浙ICP备2024088348号</a></span>
                </div>
                <div>
                    <a href="javascript:;"
                        ><img src="@/assets/weixin.png"/>
                        <div><img src="@/assets/pc/mp.png" /></div
                    ></a>
                    <a href="https://weibo.com/kdg600986" target="_blank"><img src="@/assets/weibo.png"/></a>
                    <a href="mailto:business@zwhlgroup.com"><img src="@/assets/email.png"/></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "pc-footer",
    components: {},
    data() {
        return {};
    },
};
</script>
<style scoped>
@media only screen and (max-width: 2048px) {
    .copyright .wrap {
        width: calc(1600px * var(--scale));
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0 auto;
    }
    footer {
        width: 100%;
        min-width: calc(1920px * var(--scale));
        display: inline-block;
    }
    .footer-nav {
        margin: 0 auto;
        display: flex;
        width: calc(1600px * var(--scale));
        align-items: flex-start;
        justify-content: center;
        padding: 60px 0;
    }
    .footer-nav .col ul li {
        margin: 15px 0;
        text-align: left;
        text-indent: 70px;
    }
}

@media only screen and (min-width: 2049px) {
    .copyright .wrap {
        width: 1600px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0 auto;
    }
    footer {
        width: 100%;
        min-width: 1920px;
        display: inline-block;
    }
    .footer-nav {
        margin: 0 auto;
        display: flex;
        width: 1600px;
        align-items: flex-start;
        justify-content: center;
        padding: 60px 0;
    }
    .footer-nav .col ul li {
        margin: 15px 0;
        text-align: left;
        text-indent: 90px;
    }
}
.copyright {
    width: 100%;
    height: 140px;
    background: #2d2d2d;
}

.copyright .wrap div:first-child span {
    color: #bababa;
    font-size: 1.7rem;
    margin-left: 100px;
}
.copyright .wrap div:first-child span a {
    color: #bababa;
    font-size: 1.7rem;
}
.copyright .wrap div:first-child span a:hover {
    text-decoration: underline;
}
.copyright .wrap div:last-child a {
    margin-left: 15px;
    position: relative;
}
.copyright .wrap div:last-child a div {
    position: absolute;
    width: 118px !important;
    height: 126px;
    left: -50%;
    transform: translateX(-20px);
    bottom: 30px;
    display: none;
}
.copyright .wrap div:last-child a:hover div {
    display: block;
}
.copyright .wrap div a > img {
    width: 40px;
}
.footer-nav-wrap {
    width: 100%;
    background: #22b2f4;
    text-align: center;
}

.footer-nav .col {
    width: calc(1600px / 6.5);
}
.footer-nav .col > a {
    font-size: 1.6rem;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 8px;
    display: inline-block;
}
.footer-nav .col > a::before {
    content: "";
    height: 2px;
    background: #fff;
    width: 4rem;
    position: absolute;
    bottom: 0;
    left: calc((100% - 4rem) / 2);
}

.footer-nav .col ul li a {
    font-size: 1.3rem;
}
</style>
