<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="900" bg="assets/pc/jiandang/bg.jpg" :bgScale="1"> </Row>
        <Row bgColor="#f5f6f8">
            <div class="pos2">
                <div class="title">
                    党的建设
                </div>
                <ul class="list">
                    <li v-for="item in list.data" :key="item.id">
                        <div class="cover">
                            <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_600,h_255'" />
                        </div>
                        <div class="desc">
                            <h2>{{ item.title }}</h2>
                            <!-- <p v-html="item.desc"></p> -->
                            <router-link class="pc-red-button" :to="'/jiandang/detail?id=' + item.id">MORE</router-link>
                        </div>
                    </li>
                </ul>

                <div class="pager">
                    <Pager :pageSize="5" :pageNumber="list.page" :totalCount="list.total" @changePage="OnChangePageHandle"></Pager>
                </div>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
import Row from "@/components/pc/Row.vue";
import Pager from "@/components/pc/Pager.vue";

export default {
    name: "pc-jiandang",
    components: { Nav, Footer, Row, Pager },
    data() {
        return {
            loading: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            list: {
                page: 0,
                pages: 0,
                total: 0,
                data: [],
            },
        };
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.loadData();
    },

    activated() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (this.$route.query.t) {
            this.$nextTick(() => {
                this.TabHandle(parseInt(this.$route.query.t));
            });
        }
        // console.log("activated");
        // if (this.$route.query.refresh) {
        //     console.log("refresh");
        //     this.$vnode.parent.componentInstance.cache = [];
        //     this.$router.push("/news");
        //     console.log("beforeRouteEnter", this.$vnode.parent.componentInstance.cache);
        // }
    },

    methods: {
        OnChangePageHandle(e) {
            this.loadData(e.page);
        },

        loadData(page) {
            // this.$showLoading("加载中...");
            this.$http.get(`/api/jiandang/list?pageSize=5&page=${page ? page : this.list.page + 1}`).then((res) => {
                this.list.page = page ? page : this.list.page + 1;
                this.list.pages = parseInt(res.data.totalPage);
                this.list.total = parseInt(res.data.totalCount);
                this.list.data = res.data.contents;
                this.$forceUpdate();
            });
        },
    },
};
</script>
<style scoped>
/* @import url("../../../common/css/pc.css"); */
@import url("../../../common/css/icon.css");

.list {
    width: 100%;
}

.pos2 {
    width: 100%;
    text-align: left;
}

.tab li:first-child {
    border-right: 1px #000 solid;
    padding-right: 20px;
    margin-right: 20px;
}
.tab .active {
    color: #222;
}

.title {
    height: 80px;
    background: #b21703;
    margin: 80px 0;
    display: inline-flex;
    width: 100%;
    font-size: 3rem;
    color: #fff;
    align-items: center;
    text-indent: 20px;
    font-weight: normal;
}
@media only screen and (max-width: 2048px) {
    .pager {
        margin-top: calc(100px * var(--scale));
        display: inline-block;
        width: 100%;
        margin-bottom: 150px;
    }
    .list li {
        display: flex;
        align-items: center;
        margin-bottom: calc(103px * var(--scale));
    }
    .list li .cover {
        width: calc(600px * var(--scale));
        height: calc(300px * var(--scale));
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
    }

    .list li .desc {
        flex: 1;
        height: calc(300px * var(--scale));
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .list li .desc h2 {
        font-size: 2.2rem;
        margin: 20px 30px 0 30px;
        font-weight: normal;
    }
    .list li .desc p {
        font-size: 1.5rem;
        margin: 20px 30px;
        font-weight: normal;
        line-height: 2.5rem;
    }
    .list li .desc a {
        position: absolute;
        right: 15px;
        bottom: -20px;
        /* height: 40px; */
        cursor: pointer;
    }

    .tab {
        color: #9a9a9a;
        font-size: 2.8rem;
        display: inline-flex;
        margin: 80px 0 60px 0;
    }
    .tab li {
        cursor: pointer;
        line-height: 2.8rem;
    }
}

@media only screen and (min-width: 2049px) {
    .pager {
        margin-top: 100px;
        display: inline-block;
        width: 100%;
        margin-bottom: 150px;
    }
    .list li {
        display: flex;
        align-items: center;
        margin-bottom: 103px;
    }
    .list li .cover {
        width: 600px;
        height: 300px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
    }
    .list li .desc {
        flex: 1;
        height: 300px;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .list li .desc h2 {
        font-size: 2.2rem;
        margin: 20px 30px;
        font-weight: normal;
    }
    .list li .desc p {
        font-size: 1.5rem;
        margin: 20px 30px;
        font-weight: normal;
        line-height: 3rem;
    }
    .list li .desc a {
        position: absolute;
        right: 15px;
        bottom: -20px;
        /* height: 50px; */
        cursor: pointer;
    }

    .tab {
        color: #9a9a9a;
        font-size: 3rem;
        display: inline-flex;
        margin: 80px 0 60px 0;
    }
    .tab li {
        cursor: pointer;
        line-height: 3rem;
    }
}
</style>
