<template>
    <div class="home-text-wrap" :style="`color:${color}`">
        <h1>{{ title }}</h1>
        <div :class="{ borderTitle: border }" :style="`border-color:${color}`">{{ subTitle }}</div>
        <p v-html="desc"></p>
    </div>
</template>
<script>
export default {
    name: "home-text",
    props: {
        title: String,
        subTitle: String,
        desc: String,
        border: { type: Boolean, default: true },
        color: {
            type: String,
            default: "#fff",
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style scoped>
.home-text-wrap {
    width: 100%;
}
@media only screen and (max-width: 2048px) {
    .home-text-wrap h1 {
        font-size: 4rem;
        font-weight: 500;
        line-height: 4rem;
    }
    .home-text-wrap div {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2rem;

        display: inline-block;
        padding: 5px 0;
        margin-top: 15px;
        margin-bottom: 40px;
        font-weight: normal;
    }
    .home-text-wrap .borderTitle {
        border: 1px solid #fff;
        padding: 5px 10px;
    }
    .home-text-wrap p {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.8rem;
    }
}

@media only screen and (min-width: 2049px) {
    .home-text-wrap h1 {
        font-size: 4.8rem;
        font-weight: 500;
    }
    .home-text-wrap div {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2rem;
        display: inline-block;
        margin-top: 15px;
        padding: 5px 0;
        margin-bottom: 40px;
        font-weight: normal;
    }
    .home-text-wrap p {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.8rem;
    }
}
.home-text-wrap .borderTitle {
    border: 1px solid #fff;
    padding: 5px 10px;
}
</style>
