<template>
    <div>
        <Header> </Header>
        <Row bg="assets/mobile/job/pos1-bg.jpg"></Row>
        <div class="job pos2">
            <h3>敬请期待</h3>
            <a href="javascript:;" @click="$router.back()">返回</a>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "@/components/mobile/Row.vue";
export default {
    name: "mobile-recruitment",
    components: { Header, Footer, Row },
    data() {
        return {
            tabIdx: 1,
        };
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
        },
    },
};
</script>
<style scoped>
.pos2 {
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 100px 0;
}
.pos2 h3 {
    margin: 40px 0;
    font-size: 1.8rem;
    display: block;
}
.pos2 a {
    border: 1px #ccc solid;
    font-size: 1.4rem;
    color: #222;
    padding: 6px 40px;
    border-radius: 2rem;
    display: inline-block;
    margin-bottom: 40px;
}
</style>
