<template>
    <div>
        <Header :showBack="true" :backUrl="prePage"> </Header>

        <div class="col-wrap detial">
            <div class="content" v-if="detail">
                <h3>{{ detail.title }}</h3>
                <div class="wrap" v-html="detail.content"></div>
            </div>
            <div class="top-btn-wrap">
                <div @click="backtop" class="top-btn">回到顶部</div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
export default {
    name: "mobile-news-detail",
    components: { Header, Footer },
    data() {
        return { loading: false, detail: null, prePage: localStorage.getItem("pre_page") };
    },
    mounted() {
        this.loadDetail();
    },
    methods: {
        backHandle() {
            this.$router.back(-1);
        },
        backtop() {
            var timer = setInterval(function() {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 10);
        },
        loadDetail() {
            var id = this.$route.query.id;
            this.$showLoading();
            this.$http
                .get(`/api/news/detail/${id}`)
                .then((res) => {
                    this.detail = res.data.detail;
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
.detial .content {
    margin: 20px 15px;
    text-align: left;
    color: #333;
}
.detial .content .wrap {
    font-size: 13px;
    line-height: 26px;
}
.detial .content h3 {
    font-size: 16px;
    margin: 30px 0;
}
.top-btn-wrap {
    text-align: center;
}
.top-btn {
    display: inline-flex;
    height: 32px;
    width: 150px;
    background: #007aff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px 0;
}
</style>
