<template>
    <div id="app">
        <!-- <transition name="fade"> -->
        <keep-alive> <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" /> </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />

        <!-- </transition> -->

        <vue-ui-loading v-if="showLoading" :msg="msg"></vue-ui-loading>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    data() {
        return {
            showLoading: false,
            dialog: {},
            msg: "加载中...",
            showSubNav: false,
        };
    },
    components: {},
    beforeCreate() {
        Vue.prototype.$showLoading = (_msg) => {
            if (_msg) this.msg = _msg;
            this.showLoading = true;
        };
        Vue.prototype.$hideLoading = () => {
            this.showLoading = false;
        };
        Vue.prototype.$alert = (arg) => {
            this.dialog = arg;
        };
        this.$http.defaults({
            $400: (err) => {
                this.$alert({
                    title: "提示",
                    content: err.response.data ? err.response.data : err.response.statusText,
                });
            },
            $finally: () => {
                this.$hideLoading();
            },
        });
    },
    mounted() {
        if (this.$route.params.column) this.columnId = this.$route.params.column;
        var mediaQuery = window.matchMedia("only screen and (max-width: 600px)");
        var self = this;
        mediaQuery.addEventListener("change", function(e) {
            self.Responsive(e.matches);
        });
        this.disable_weixin_browser_font_size_scale();
    },
    watch: {
        $route(to) {
            this.columnId = to.params.column;
        },
    },
    methods: {
        Responsive(m) {
            var to = this.$route;
            var startWith = to.path.substr(0, 2);
            // console.log("mediaQuery change", to);
            // console.log("响应式", m, this.$route, startWith);
            if (m && startWith != "/m") {
                // console.log("重定向<=600", to.meta.responsive);
                this.$router.push({ path: to.meta.responsive, params: to.params, query: to.query });
                return;
            }

            if (!m && startWith == "/m") {
                // console.log("重定向>600", to.meta.responsive);
                this.$router.push({ path: to.meta.responsive, params: to.params, query: to.query });
                return;
            }
        },
        handelMenu(idx) {
            this.navIdx = idx;
        },
        handleNav() {
            this.showSubNav = !this.showSubNav;
        },
        /*eslint-disable */
        __handleFontSize() {
            if (!WeixinJSBridge) return;
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke("setFontSizeCallback", {
                fontSize: 0,
            });
            // 重写设置网页字体大小的事件
            WeixinJSBridge.on("menu:setfont", function() {
                WeixinJSBridge.invoke("setFontSizeCallback", {
                    fontSize: 0,
                });
            });
        },
        disable_weixin_browser_font_size_scale() {
            try {
                if (WeixinJSBridge && typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
                    this.__handleFontSize();
                } else {
                    if (document.addEventListener) {
                        document.addEventListener("WeixinJSBridgeReady", this.__handleFontSize, false);
                    } else if (document.attachEvent) {
                        document.attachEvent("WeixinJSBridgeReady", this.__handleFontSize);
                        document.attachEvent("onWeixinJSBridgeReady", this.__handleFontSize);
                    }
                }
            } catch {}
        },
    },
};
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* transform: scale(); */
}
body {
    background: #fff;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #fff;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

img {
    vertical-align: middle;
    max-width: 100%;
}

.fade-enter-active {
    animation: fade-in 0.5s ease;
}
.fade-leave-active {
    animation: fade-in 0.5s ease reverse;
}
@keyframes fade-in {
    0% {
        /* transform: scale(0); */
        opacity: 0.5;
    }
    100% {
        /* transform: scale(1); */
        opacity: 1;
    }
}
</style>
