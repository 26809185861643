<template>
    <div class="nav-container" :class="color">
        <div class="wrap">
            <div>
                <router-link to="/?fm=1"> <img :src="require('@/assets/logo-' + (color == 'light' ? 'white' : 'color') + '.png')" class="logo"/></router-link>
                <nav>
                    <router-link to="/?fm=1" class="nav"><section>首页</section></router-link>
                    <router-link to="/about" class="nav" :class="$route.fullPath.substr(0, 6) == '/about' ? 'router-link-exact-active' : ''"><section>关于浙文</section></router-link>
                    <a href="javascript:;" :class="serviceNav ? 'router-link-exact-active' : ''"
                        ><section>核心业务</section>
                        <div class="sub">
                            <span @click="$router.push('/service/marketing')"><div>智慧营销</div></span>
                            <span @click="$router.push('/service/spread')"><div>公关传播与品牌管理</div></span>
                            <span @click="$router.push('/service/digital_culture')"><div>数字文化</div></span>
                            <span @click="$router.push('/service/content')"><div>算力运营</div></span>
                            <span @click="$router.push('/service/innovation')"><div>文化创新</div></span>
                        </div>
                    </a>
                    <router-link to="/jiandang?refresh=1" class="nav" :class="$route.fullPath.substr(0, 9) == '/jiandang' ? 'router-link-exact-active' : ''"><section>党的建设</section></router-link>
                    <router-link to="/news?refresh=1" class="nav" :class="$route.fullPath.substr(0, 5) == '/news' ? 'router-link-exact-active' : ''"><section>动态与洞察</section></router-link>

                    <router-link to="/invest" class="nav" :class="$route.fullPath.substr(0, 7) == '/invest' ? 'router-link-exact-active' : ''"><section>投资者关系</section></router-link>
                    <router-link to="/job" class="nav" :class="$route.fullPath.substr(0, 4) == '/job' ? 'router-link-exact-active' : ''"><section>职在浙里</section></router-link>
                </nav>
            </div>
            <span>
                <router-link to="/invest"> 浙文互联<br />600986.SH</router-link>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "pc-nav",
    components: {},
    props: {
        color: {
            type: String,
            default: "dark",
        },
    },
    data() {
        return {
            serviceNav: false,
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.OnChangePos();
            },
        },
    },
    mounted() {
        this.OnChangePos();
    },

    methods: {
        OnChangePos() {
            this.serviceNav = this.$route.fullPath.substr(0, 9) == "/service/";
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
@media only screen and (max-width: 2048px) {
    .nav-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        min-width: calc(1600px * var(--scale));
        z-index: 100;
    }
}

@media only screen and (min-width: 2049px) {
    .nav-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        min-width: 1600px;
        z-index: 100;
    }
}

.nav-container nav > .nav:hover section::before {
    width: 100%;
    left: 0;
    background-color: #222222;
}

.light nav > .nav:hover section::before {
    width: 100%;
    left: 0;
    background-color: #fff !important;
}

.nav-container .wrap {
    width: 1600px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}
.nav-container .wrap > div {
    flex: 1;
    align-items: center;
    display: flex;
    height: 100%;
}
.nav-container .wrap > span {
    text-align: center;

    margin-right: 5px;
}
.nav-container .wrap > span a {
    font-size: 1.2rem;
    color: #222;
}
.nav-container nav > a {
    display: flex;
    height: 100%;
    align-items: center;
}
.nav-container nav > a section {
    position: relative;
    cursor: pointer;
    text-align: center;
    padding-bottom: 5px;
}
.nav-container nav > a section::before {
    content: "";
    width: 0;
    position: absolute;
    height: 1.5px;
    bottom: 0;
    transition: all 0.4s;
    text-align: center;
    left: 50%;
}
.nav-container nav > .nav {
    position: relative;
}
.nav-container nav > a > div {
    position: absolute;
    top: 100%;
    width: 140px;
    background: #cbdeec;
    transition: all 0.2s;
    height: 0;
    overflow: hidden;
    border-radius: 6px;
}
.nav-container nav > a:hover .sub {
    height: calc(3.2rem * 5);
}

.nav-container nav > a > .sub span {
    color: #326cac;
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2rem;
    overflow: hidden;
    font-family: "PingFangSC-Light";
}

.nav-container nav > a > .sub span div {
    width: 110px;
    /* text-align-last: justify;
    -moz-text-align-last: justify;
    text-align: justify !important;
    text-justify: distribute-all-lines; */
    display: block;
    font-style: normal;
    /* white-space: unset; */
    padding: 1rem 0;
    height: 1.2rem;
}
.nav-container nav > a > .sub span div:after {
    content: "";
    width: 100%;
    display: inline-block;
}

.nav-container nav > a > .sub span:hover {
    background: #fff;
}

.nav-container nav .router-link-exact-active section::before {
    background-color: #222222;
    width: 100%;
    left: 0;
}

.light nav .router-link-exact-active section::before {
    background-color: #fff !important;
    width: 100%;
    left: 0;
}

.nav-container .logo {
    margin-right: 60px;
    width: 115px;
    margin-left: 5px;
}
.nav-container nav {
    display: flex;
    height: 100%;
    align-items: center;
}
.nav-container nav > a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: #222222;
    font-size: 1.4rem;
    margin: 0 22px;
    position: relative;
    cursor: pointer;
    text-align: center;
    justify-content: center;
}
.light nav > a {
    color: #fff !important;
}
</style>
