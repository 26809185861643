<template>
    <div>
        <Header> </Header>
        <Row bg="assets/mobile/job/pos1-bg.jpg"></Row>
        <div class="job pos2">
            <div class="tab">
                <div class="item" :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">
                    <div>社会招聘</div>
                    <span></span>
                </div>
                <div class="item" :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">
                    <div>校园招聘</div>
                    <span></span>
                </div>
            </div>

            <ul class="list" v-if="tabIdx == 1">
                <li>
                    <a target="_blank" href="http://www.aspiration-cn.com/talents"><span>派瑞威行</span><i class="iconfont icon-back"></i></a>
                </li>
                <li>
                    <a target="_blank" href="http://www.iforce-media.com/careers.html"><span>百孚思</span><i class="iconfont icon-back"></i></a>
                </li>
                <li>
                    <a target="_blank" href="http://www.itrax.cn/recruitment"><span>爱&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创</span><i class="iconfont icon-back"></i></a>
                </li>
                <li>
                    <router-link to="/m/job/none"><span>同立传播</span><i class="iconfont icon-back"></i></router-link>
                </li>
                <li>
                    <router-link to="/m/job/none"><span>华&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邑</span><i class="iconfont icon-back"></i></router-link>
                </li>
                <li>
                    <router-link to="/m/job/none"><span>雨林木风</span><i class="iconfont icon-back"></i></router-link>
                </li>
                <li>
                    <router-link to="/m/job/none"><span>智阅网络</span><i class="iconfont icon-back"></i></router-link>
                </li>
                <li>
                    <router-link to="/m/job/none"><span>杭州浙文科技</span><i class="iconfont icon-back"></i></router-link>
                </li>
            </ul>
            <ul class="list" v-if="tabIdx == 2">
                <li>
                    <a href="javascript:;"><span>敬请期待</span><i class="iconfont icon-back"></i></a>
                </li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import Row from "@/components/mobile/Row.vue";
export default {
    name: "mobile-recruitment",
    components: { Header, Footer, Row },
    data() {
        return {
            tabIdx: 1,
        };
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");

.list {
    margin: 0px 15px 40px 15px;
    display: flex;
    flex-direction: column;
}
.list li .detail {
    color: #333;
    text-align: left;
    margin: 5px 10px 30px 10px;
    line-height: 22px;
}
.list li {
    border-bottom: 1px #eaeaea solid;
}
.list li a {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
}
.list li a span {
    flex: 1;
    text-align: left;
    color: #333333;
    margin: 0 10px;
}
.list li a i {
    color: #333333;
    transform: rotate(180deg);
    margin: 0 10px;
}
.pos2 .tab {
    margin: 30px 15px 20px 15px;
    display: block;
    border-bottom: 1px #c1c1c1 solid;
}
.pos2 .tab .item {
    width: 50%;
    display: inline-flex;
    color: #333333;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pos2 .tab .item div {
    width: 70%;
    border-bottom: 2px transparent solid;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 0;
}
.pos2 .tab .item span {
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 3px;
    height: 3px;
    background: #333333;
    transform: rotate(45deg);
    margin-left: -2px;
    display: none;
}

.pos2 .tab .active div {
    font-weight: bold;
    border-bottom: 2px #333333 solid;
}
.pos2 .tab .active span {
    display: inline-block;
}
</style>
