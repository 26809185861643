<template>
    <div class="wrap" :style="'background:' + bg">
        <div class="tit">
            <div>
                <slot name="title"></slot>
            </div>
        </div>
        <div class="content" :style="'margin:' + margin">
            <slot></slot>
        </div>
        <router-link v-if="showMore" class="pc-blue-button more" :to="url">MORE</router-link>
    </div>
</template>
<script>
export default {
    name: "pc_invest_row",
    props: {
        bg: {
            type: String,
            default: "#fff",
        },
        title: {
            type: String,
            default: "",
        },
        showMore: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
            default: "",
        },
        margin: {
            type: String,
            default: "70px 145px",
        },
    },
};
</script>
<style scoped>
.wrap {
    width: 100%;
    display: inline-block;
    margin-bottom: 70px;
    position: relative;
}

.tit {
    width: 100%;
    height: 50px;
    background: #dfe8f3;
    display: flex;
    align-items: center;
    color: #222;
    font-size: 2rem;
    font-weight: normal;
    text-indent: 30px;
}

.content {
    min-height: 100px;
}
.more {
    position: absolute;
    right: 145px;
    /* height: 40px; */
    bottom: -20px;
}

/* @media only screen and (max-width: 2048px) {
    .more {
        position: absolute;
        right: 145px;
        height: 40px;
        bottom: -20px;
    }
}

@media only screen and (min-width: 2049px) {
    .more {
        position: absolute;
        right: 145px;
        height: 50px;
        bottom: -25px;
    }
} */
</style>
