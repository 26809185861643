import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/pc/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "浙文互联-智能营销集团",
            keepAlive: true,
            responsive: "/m",
        },
    },
    {
        path: "/m",
        name: "MobileHome",
        component: function() {
            return import("../views/mobile/Home.vue");
        },
        meta: {
            title: "浙文互联-智能营销集团",
            responsive: "/",
        },
    },
    {
        path: "/about",
        name: "PC-Abount",
        component: function() {
            return import("../views/pc/About.vue");
        },
        meta: {
            title: "关于浙文",
            keepAlive: true,
            responsive: "/m/about",
        },
    },
    {
        path: "/about/hzzw",
        name: "pc-about-hzzw",
        component: function() {
            return import("../views/pc/About/Hzzw.vue");
        },
        meta: {
            title: "杭州浙文科技",
            responsive: "/m/about/hzzw",
        },
    },
    {
        path: "/about/huayi",
        name: "pc-about-huayi",
        component: function() {
            return import("../views/pc/About/HuaYi.vue");
        },
        meta: {
            title: "华邑",
            responsive: "/m/about/huayi",
        },
    },
    {
        path: "/about/tongli",
        name: "pc-about-huayi",
        component: function() {
            return import("../views/pc/About/TongLi.vue");
        },
        meta: {
            title: "同立",
            responsive: "/m/about/tongli",
        },
    },
    {
        path: "/about/ylmf",
        name: "pc-about-ylmf",
        component: function() {
            return import("../views/pc/About/Ylmf.vue");
        },
        meta: {
            title: "雨林木风",
            responsive: "/m/about/ylmf",
        },
    },
    {
        path: "/m/about",
        name: "Mobile-About",
        component: function() {
            return import("../views/mobile/About.vue");
        },
        meta: {
            title: "关于浙文",
            responsive: "/about",
        },
    },
    {
        path: "/m/about/hzzw",
        name: "Mobile-About-Hzzw",
        component: function() {
            return import("../views/mobile/About/Hzzw.vue");
        },
        meta: {
            title: "杭州浙文科技",
            responsive: "/about/hzzw",
        },
    },
    {
        path: "/m/about/tongli",
        name: "Mobile-About-TongLi",
        component: function() {
            return import("../views/mobile/About/TongLi.vue");
        },
        meta: {
            title: "同立",
            responsive: "/about/tongli",
        },
    },
    {
        path: "/m/about/huayi",
        name: "Mobile-About-HuaYi",
        component: function() {
            return import("../views/mobile/About/HuaYi.vue");
        },
        meta: {
            title: "华邑",
            responsive: "/about/huayi",
        },
    },
    {
        path: "/m/about/ylmf",
        name: "Mobile-About-Ylmf",
        component: function() {
            return import("../views/mobile/About/Ylmf.vue");
        },
        meta: {
            title: "雨林木风",
            responsive: "/about/ylmf",
        },
    },
    {
        path: "/service/marketing",
        name: "PC-Service-Marketing",
        component: function() {
            return import("../views/pc/Service/Marketing.vue");
        },
        meta: {
            title: "智慧营销",
            responsive: "/m/service",
        },
    },
    {
        path: "/service/spread",
        name: "PC-Service-Spread",
        component: function() {
            return import("../views/pc/Service/Spread.vue");
        },
        meta: {
            title: "公关传播与品牌管理",
            responsive: "/m/service",
        },
    },
    {
        path: "/service/digital_culture",
        name: "PC-Service-digital_culture",
        component: function() {
            return import("../views/pc/Service/DigitalCulture.vue");
        },
        meta: {
            title: "数字文化",
            responsive: "/m/service",
        },
    },
    {
        path: "/service/content",
        name: "PC-Service-Content",
        component: function() {
            return import("../views/pc/Service/Content.vue");
        },
        meta: {
            title: "算力运营",
            responsive: "/m/service",
        },
    },
    {
        path: "/service/innovation",
        name: "PC-Service-Innovation",
        component: function() {
            return import("../views/pc/Service/Innovation.vue");
        },
        meta: {
            title: "文化创新",
            responsive: "/m/service",
        },
    },
    {
        path: "/m/service",
        name: "Mobile-Service",
        component: function() {
            return import("../views/mobile/service.vue");
        },
        meta: {
            title: "核心业务",
            responsive: "/service/spread",
        },
    },
    {
        path: "/invest",
        name: "PC-Invest",
        component: function() {
            return import("../views/pc/Invest.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/m/invest",
        },
    },
    {
        path: "/invest/list",
        name: "PC-Invest-List",
        component: function() {
            return import("../views/pc/InvestList.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/m/invest/list",
        },
    },
    {
        path: "/invest/notice",
        name: "PC-Invest-notice",
        component: function() {
            return import("../views/pc/Notice.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/m/invest/notice",
        },
    },
    {
        path: "/m/invest",
        name: "Mobile-Invest",
        component: function() {
            return import("../views/mobile/Invest.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/invest",
        },
    },
    {
        path: "/m/invest/list",
        name: "Mobile-Invest-List",
        component: function() {
            return import("../views/mobile/InvestList.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/invest/list",
        },
    },
    {
        path: "/m/invest/notice",
        name: "Mobile-Invest-notice",
        component: function() {
            return import("../views/mobile/Notice.vue");
        },
        meta: {
            title: "投资者关系",
            responsive: "/invest/notice",
        },
    },
    {
        path: "/news",
        name: "PC-News",
        component: function() {
            return import("../views/pc/news.vue");
        },
        meta: {
            title: "动态与洞察",
            keepAlive: true,
            responsive: "/m/news",
        },
    },
    {
        path: "/news/detail",
        name: "PC-News-Detail",
        component: function() {
            return import("../views/pc/newsDetail.vue");
        },
        meta: {
            title: "动态与洞察",
            responsive: "/m/news/detail",
        },
    },
    {
        path: "/jiandang",
        name: "PC-Jiandang",
        component: function() {
            return import("../views/pc/Jiandang.vue");
        },
        meta: {
            title: "党的建设",
            // keepAlive: true,
            responsive: "/m/jiandang",
        },
    },
    {
        path: "/jiandang/detail",
        name: "PC-Jiandang-Detail",
        component: function() {
            return import("../views/pc/JiandangDetail.vue");
        },
        meta: {
            title: "党的建设",
            responsive: "/m/news/detail",
        },
    },
    {
        path: "/m/news",
        name: "Mobile-News",
        component: function() {
            return import("../views/mobile/news.vue");
        },
        meta: {
            title: "动态与洞察",
            responsive: "/news",
        },
    },
    {
        path: "/m/news/detail",
        name: "Mobile-News-Detail",
        component: function() {
            return import("../views/mobile/newsDetail.vue");
        },
        meta: {
            title: "动态与洞察",
            responsive: "/news/detail",
        },
    },
    {
        path: "/m/jiandang",
        name: "Mobile-jiandang",
        component: function() {
            return import("../views/mobile/Jiandang.vue");
        },
        meta: {
            title: "党的建设",
            // keepAlive: true,
            responsive: "/jiandang",
        },
    },
    {
        path: "/m/jiandang/detail",
        name: "Mobile-jiandang-Detail",
        component: function() {
            return import("../views/mobile/JiandangDetail.vue");
        },
        meta: {
            title: "党的建设",
            responsive: "/jiandang/detail",
        },
    },
    {
        path: "/job",
        name: "PC-Job",
        component: function() {
            return import("../views/pc/Job.vue");
        },
        meta: {
            title: "职在浙里",
            responsive: "/m/job",
        },
    },
    {
        path: "/job/none",
        name: "PC-Job-None",
        component: function() {
            return import("../views/pc/JobNone.vue");
        },
        meta: {
            title: "职在浙里",
            responsive: "/m/job",
        },
    },
    {
        path: "/m/job",
        name: "Mobile-Job",
        component: function() {
            return import("../views/mobile/Job.vue");
        },
        meta: {
            title: "职在浙里",
            responsive: "/job",
        },
    },
    {
        path: "/m/job/none",
        name: "Mobile-Job-None",
        component: function() {
            return import("../views/mobile/JobNone.vue");
        },
        meta: {
            title: "职在浙里",
            responsive: "/job",
        },
    },
    {
        path: "/contact",
        name: "PC-Contact",
        component: function() {
            return import("../views/pc/Contact.vue");
        },
        meta: {
            title: "联系我们",
            responsive: "/m/contact",
        },
    },
    {
        path: "/m/contact",
        name: "Mobile-Contact",
        component: function() {
            return import("../views/mobile/Contact.vue");
        },
        meta: {
            title: "联系我们",
            responsive: "/contact",
        },
    },
];
const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        // savedPosition is only available for popstate navigations.
        return savedPosition;
    } else {
        const position = {};
        // new navigation.
        // scroll to anchor by returning the selector
        if (to.hash) {
            position.selector = to.hash;
        }
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some((m) => m.meta.scrollToTop)) {
            // cords will be used if no selector is provided,
            // or if the selector didn't match any element.
            position.x = 0;
            position.y = 0;
        }
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return position;
    }
};
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
});

router.beforeEach((to, from, next) => {
    // console.log("路由", from, to);

    var w = document.body.clientWidth;
    //console.warn(w, to, to.fullPath.substr(0, 2));
    if (w <= 600 && !to.fullPath.startsWith("/m")) {
        //console.log("r");
        router.push({ path: "/m" + to.path, params: to.params, query: to.query });
        return;
    }

    if (w > 600 && to.fullPath.startsWith("/m")) {
        router.push({ path: to.path.substr(2), params: to.params, query: to.query });
        return;
    }

    localStorage.setItem("pre_page", from.fullPath);
    // if (to.path == '/news' && to.query.refresh) {

    // }
    // if (w > 600 && to.path == "/") {
    //     document.querySelector("#app").style.overflow = "hidden";
    //     document.querySelector("#app").style.height = "100%";
    // } else {
    //     document.querySelector("#app").style.overflow = "";
    //     document.querySelector("#app").style.height = "";
    // }
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    next();
});

export default router;
