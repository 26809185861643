<template>
    <div class="wrap" ref="chart-wrap"></div>
</template>
<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent, LegendComponent, DataZoomComponent } from "echarts/components";
import { CandlestickChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, GridComponent, LegendComponent, DataZoomComponent, CandlestickChart, LineChart, CanvasRenderer, UniversalTransition]);
export default {
    name: "KLine",
    data() {
        return {
            rawData: [
                ["2021-07-09", "4.040", "4.090", "4.090", "4.020", "83105.000"],
                ["2021-07-12", "4.100", "4.100", "4.130", "4.080", "94561.000"],
                ["2021-07-13", "4.110", "4.130", "4.130", "4.060", "94740.000"],
                ["2021-07-14", "4.100", "4.060", "4.130", "4.060", "102477.000"],
                ["2021-07-15", "4.070", "4.100", "4.120", "4.030", "92880.000"],
                ["2021-07-16", "4.140", "4.240", "4.260", "4.130", "218929.000"],
                ["2021-07-19", "4.240", "4.180", "4.240", "4.140", "125102.000"],
                ["2021-07-20", "4.150", "4.180", "4.200", "4.140", "63934.000"],
                ["2021-07-21", "4.190", "4.190", "4.200", "4.130", "101011.000"],
                ["2021-07-22", "4.180", "4.180", "4.230", "4.140", "102054.000"],
                ["2021-07-23", "4.180", "4.110", "4.180", "4.100", "112629.000"],
                ["2021-07-26", "4.080", "4.050", "4.140", "4.030", "106057.000"],
                ["2021-07-27", "4.070", "3.980", "4.080", "3.970", "100161.000"],
                ["2021-07-28", "3.960", "3.830", "3.970", "3.780", "142229.000"],
                ["2021-07-29", "3.860", "3.870", "3.940", "3.860", "103618.000"],
                ["2021-07-30", "3.880", "3.910", "3.940", "3.820", "103853.000"],
                ["2021-08-02", "3.910", "4.020", "4.030", "3.890", "109189.000"],
                ["2021-08-03", "3.990", "4.030", "4.090", "3.990", "118665.000"],
                ["2021-08-04", "4.010", "4.060", "4.070", "4.010", "92433.000"],
                ["2021-08-05", "4.060", "4.020", "4.090", "4.000", "87077.000"],
                ["2021-08-06", "4.020", "4.050", "4.050", "3.990", "69490.000"],
                ["2021-08-09", "4.010", "4.080", "4.110", "4.010", "82561.000"],
                ["2021-08-10", "4.080", "4.100", "4.110", "4.060", "87404.000"],
                ["2021-08-11", "4.100", "4.100", "4.130", "4.080", "100389.000"],
                ["2021-08-12", "4.100", "4.140", "4.190", "4.100", "171069.000"],
                ["2021-08-13", "4.120", "4.120", "4.140", "4.090", "115542.000"],
                ["2021-08-16", "4.140", "4.100", "4.150", "4.080", "113274.000"],
                ["2021-08-17", "4.110", "3.980", "4.120", "3.950", "160358.000"],
                ["2021-08-18", "3.960", "4.070", "4.070", "3.950", "120834.000"],
                ["2021-08-19", "4.080", "4.080", "4.110", "4.050", "105872.000"],
                ["2021-08-20", "4.060", "4.040", "4.070", "4.000", "118946.000"],
                ["2021-08-23", "4.040", "4.100", "4.110", "4.030", "108320.000"],
                ["2021-08-24", "4.120", "4.070", "4.130", "4.050", "129215.000"],
                ["2021-08-25", "4.080", "4.130", "4.150", "4.050", "155034.000"],
                ["2021-08-26", "4.110", "4.200", "4.280", "4.100", "276694.000"],
                ["2021-08-27", "4.190", "4.180", "4.200", "4.140", "132927.000"],
                ["2021-08-30", "4.160", "4.230", "4.270", "4.160", "158153.000"],
                ["2021-08-31", "4.210", "4.290", "4.310", "4.190", "211954.000"],
                ["2021-09-01", "4.300", "4.290", "4.330", "4.250", "177680.000"],
                ["2021-09-02", "4.260", "4.330", "4.330", "4.250", "173505.000"],
                ["2021-09-03", "4.340", "4.350", "4.370", "4.310", "144026.000"],
                ["2021-09-06", "4.360", "4.330", "4.380", "4.320", "167939.000"],
                ["2021-09-07", "4.330", "4.470", "4.490", "4.310", "229450.000"],
                ["2021-09-08", "4.480", "4.550", "4.570", "4.450", "244402.000"],
                ["2021-09-09", "4.510", "4.560", "4.610", "4.480", "189908.000"],
                ["2021-09-10", "4.560", "4.520", "4.600", "4.480", "175491.000"],
                ["2021-09-13", "4.540", "4.640", "4.650", "4.500", "186018.000"],
                ["2021-09-14", "4.640", "4.500", "4.710", "4.490", "283221.000"],
                ["2021-09-15", "4.500", "4.650", "4.810", "4.490", "331004.000"],
                ["2021-09-16", "4.780", "4.540", "4.810", "4.540", "356328.000"],
                ["2021-09-17", "4.550", "4.530", "4.690", "4.500", "297015.000"],
                ["2021-09-22", "4.460", "4.470", "4.520", "4.350", "314129.000"],
                ["2021-09-23", "4.480", "4.600", "4.760", "4.480", "395615.000"],
                ["2021-09-24", "4.640", "4.530", "4.670", "4.510", "231853.000"],
                ["2021-09-27", "4.540", "4.380", "4.570", "4.320", "208422.000"],
                ["2021-09-28", "4.360", "4.470", "4.500", "4.360", "165683.000"],
                ["2021-09-29", "4.450", "4.330", "4.460", "4.320", "193407.000"],
                ["2021-09-30", "4.330", "4.270", "4.410", "4.250", "186636.000"],
                ["2021-10-08", "4.320", "4.700", "4.700", "4.320", "405008.000"],
                ["2021-10-11", "4.740", "4.630", "4.740", "4.590", "474045.000"],
                ["2021-10-12", "4.610", "4.590", "4.700", "4.530", "266309.000"],
                ["2021-10-13", "4.560", "4.470", "4.630", "4.380", "261554.000"],
                ["2021-10-14", "4.470", "4.490", "4.550", "4.380", "203767.000"],
                ["2021-10-15", "4.480", "4.390", "4.540", "4.370", "226466.000"],
                ["2021-10-18", "4.380", "4.400", "4.430", "4.350", "158227.000"],
                ["2021-10-19", "4.400", "4.360", "4.450", "4.320", "183052.000"],
                ["2021-10-20", "4.390", "4.470", "4.540", "4.330", "275770.000"],
                ["2021-10-21", "4.490", "4.500", "4.560", "4.400", "244521.000"],
                ["2021-10-22", "4.470", "4.400", "4.520", "4.360", "180995.000"],
                ["2021-10-25", "4.370", "4.360", "4.430", "4.320", "153177.000"],
                ["2021-10-26", "4.370", "4.300", "4.410", "4.270", "175968.000"],
                ["2021-10-27", "4.300", "4.180", "4.360", "4.130", "166899.000"],
                ["2021-10-28", "4.190", "4.130", "4.210", "4.070", "119497.000"],
                ["2021-10-29", "4.150", "4.230", "4.250", "4.110", "125016.000"],
                ["2021-11-01", "4.280", "4.550", "4.590", "4.190", "372500.000"],
                ["2021-11-02", "4.590", "4.520", "4.620", "4.470", "296419.000"],
                ["2021-11-03", "4.530", "4.460", "4.540", "4.380", "202581.000"],
                ["2021-11-04", "4.520", "4.550", "4.580", "4.440", "220948.000"],
                ["2021-11-05", "4.520", "4.660", "4.730", "4.520", "367733.000"],
                ["2021-11-08", "4.660", "4.700", "4.730", "4.620", "236454.000"],
                ["2021-11-09", "4.700", "4.680", "4.740", "4.650", "163752.000"],
                ["2021-11-10", "4.690", "4.730", "4.820", "4.670", "266382.000"],
                ["2021-11-11", "4.710", "4.970", "5.020", "4.700", "478231.000"],
                ["2021-11-12", "4.980", "4.910", "5.010", "4.890", "312451.000"],
                ["2021-11-15", "4.890", "4.880", "4.930", "4.800", "271761.000"],
                ["2021-11-16", "4.900", "4.830", "5.010", "4.800", "322839.000"],
                ["2021-11-17", "4.800", "4.860", "4.890", "4.790", "230727.000"],
                ["2021-11-18", "4.850", "4.680", "4.900", "4.660", "299185.000"],
                ["2021-11-19", "4.780", "4.700", "4.910", "4.690", "450609.000"],
                ["2021-11-22", "4.730", "4.670", "4.770", "4.640", "284407.000"],
                ["2021-11-23", "4.670", "4.690", "4.730", "4.600", "251592.000"],
                ["2021-11-24", "4.690", "4.690", "4.800", "4.640", "289349.000"],
                ["2021-11-25", "4.690", "4.700", "4.790", "4.640", "246583.000"],
                ["2021-11-26", "4.860", "4.590", "4.880", "4.550", "514113.000"],
                ["2021-11-29", "4.500", "4.490", "4.550", "4.450", "307047.000"],
                ["2021-11-30", "4.520", "4.520", "4.600", "4.490", "211248.000"],
                ["2021-12-01", "4.520", "4.670", "4.780", "4.510", "366581.000"],
                ["2021-12-02", "4.670", "4.580", "4.690", "4.550", "233325.000"],
                ["2021-12-03", "4.560", "4.550", "4.620", "4.550", "163266.000"],
                ["2021-12-06", "4.530", "4.540", "4.630", "4.470", "234840.000"],
                ["2021-12-07", "4.570", "4.540", "4.630", "4.520", "191200.000"],
                ["2021-12-08", "4.550", "4.570", "4.580", "4.500", "193041.000"],
                ["2021-12-09", "4.570", "4.610", "4.690", "4.560", "361997.000"],
                ["2021-12-10", "4.600", "4.870", "5.070", "4.590", "1609663.000"],
                ["2021-12-13", "4.950", "5.360", "5.360", "4.930", "728206.000"],
                ["2021-12-14", "5.900", "5.890", "5.900", "5.700", "2837232.000"],
                ["2021-12-15", "5.730", "6.480", "6.480", "5.670", "1811574.000"],
                ["2021-12-16", "6.660", "6.360", "7.000", "6.300", "3403234.000"],
                ["2021-12-17", "6.490", "6.290", "6.570", "5.900", "2350504.000"],
                ["2021-12-20", "6.190", "6.110", "6.370", "5.990", "1629182.000"],
                ["2021-12-21", "6.080", "6.190", "6.360", "6.050", "1326181.000"],
                ["2021-12-22", "6.210", "6.310", "6.460", "6.140", "1378158.000"],
                ["2021-12-23", "6.300", "6.600", "6.940", "6.220", "2252027.000"],
                ["2021-12-24", "6.450", "6.200", "6.830", "6.200", "1602896.000"],
                ["2021-12-27", "6.120", "6.000", "6.560", "6.000", "1262117.000"],
                ["2021-12-28", "6.200", "6.330", "6.520", "5.990", "1321011.000"],
                ["2021-12-29", "6.960", "6.960", "6.960", "6.960", "215548.000"],
                ["2021-12-30", "7.660", "7.660", "7.660", "7.660", "67665.000"],
                ["2021-12-31", "8.430", "8.430", "8.430", "8.430", "140330.000"],
                ["2022-01-04", "9.050", "8.880", "9.270", "8.430", "3854230.000"],
                ["2022-01-05", "8.970", "8.850", "9.720", "8.580", "3519373.000"],
                ["2022-01-06", "7.990", "8.020", "8.360", "7.970", "2582780.000"],
                ["2022-01-07", "8.820", "8.370", "8.820", "8.220", "3254382.000"],
                ["2022-01-10", "8.250", "8.710", "8.740", "7.860", "2493738.000"],
                ["2022-01-11", "8.450", "7.960", "8.450", "7.840", "2545797.000"],
                ["2022-01-12", "8.200", "8.760", "8.760", "8.040", "2077575.000"],
                ["2022-01-13", "9.000", "8.400", "9.270", "8.380", "2930310.000"],
                ["2022-01-14", "8.100", "8.130", "8.610", "7.950", "2098370.000"],
            ],
            dates: [],
            data: [],
        };
    },
    mounted() {
        this.InitChart();
    },
    methods: {
        calculateMA(dayCount, data) {
            var result = [];
            for (var i = 0, len = data.length; i < len; i++) {
                if (i < dayCount) {
                    result.push("-");
                    continue;
                }
                var sum = 0;
                for (var j = 0; j < dayCount; j++) {
                    sum += +data[i - j][1];
                }
                result.push(sum / dayCount);
            }
            return result;
        },

        InitChart() {
            this.$http.get("/api/invest/kline").then((res) => {
                this.rawData = res.data;
                this.dates = this.rawData.map(function(item) {
                    return item[0];
                });
                this.data = this.rawData.map(function(item) {
                    return [+item[1], +item[2], +item[4], +item[3]];
                });
                var chartDom = this.$refs["chart-wrap"];
                var myChart = echarts.init(chartDom, "light");
                var option = {
                    // legend: {
                    //     data: ["日K", "MA5", "MA10", "MA20", "MA30"],
                    //     inactiveColor: "#777",
                    // },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            animation: true,
                            type: "cross",
                            lineStyle: {
                                color: "#376df4",
                                width: 1,
                                opacity: 1,
                            },
                        },
                        formatter: function(param) {
                            param = param[0];

                            return [param.name + '<hr size=1 style="margin: 3px 0">', "开盘：" + param.data[1] + "<br/>", "收盘：" + param.data[2] + "<br/>", "最低：" + param.data[4] + "<br/>", "最高：" + param.data[4] + "</h1>"].join("");
                        },
                    },
                    xAxis: {
                        type: "category",
                        data: this.dates,
                        axisLine: { lineStyle: { color: "#8392A5" } },
                    },
                    yAxis: {
                        scale: true,
                        axisLine: { lineStyle: { color: "#8392A5" } },
                        splitLine: { show: true },
                    },
                    grid: {
                        bottom: 80,
                    },
                    dataZoom: [
                        {
                            textStyle: {
                                color: "#8392A5",
                            },
                            handleIcon: "path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
                            dataBackground: {
                                areaStyle: {
                                    color: "#8392A5",
                                },
                                lineStyle: {
                                    opacity: 0.8,
                                    color: "#8392A5",
                                },
                            },
                            brushSelect: true,
                        },
                        {
                            type: "inside",
                        },
                    ],
                    series: [
                        {
                            type: "candlestick",
                            name: "日K",
                            data: this.data,
                            itemStyle: {
                                color: "#FD1050",
                                color0: "#0CF49B",
                                borderColor: "#FD1050",
                                borderColor0: "#0CF49B",
                            },
                        },
                        // {
                        //     name: "MA5",
                        //     type: "line",
                        //     data: this.calculateMA(5, this.data),
                        //     smooth: true,
                        //     showSymbol: false,
                        //     lineStyle: {
                        //         width: 1,
                        //     },
                        // },
                        // {
                        //     name: "MA10",
                        //     type: "line",
                        //     data: this.calculateMA(10, this.data),
                        //     smooth: true,
                        //     showSymbol: false,
                        //     lineStyle: {
                        //         width: 1,
                        //     },
                        // },
                        // {
                        //     name: "MA20",
                        //     type: "line",
                        //     data: this.calculateMA(20, this.data),
                        //     smooth: true,
                        //     showSymbol: false,
                        //     lineStyle: {
                        //         width: 1,
                        //     },
                        // },
                        // {
                        //     name: "MA30",
                        //     type: "line",
                        //     data: this.calculateMA(30, this.data),
                        //     smooth: true,
                        //     showSymbol: false,
                        //     lineStyle: {
                        //         width: 1,
                        //     },
                        // },
                    ],
                };

                option && myChart.setOption(option);
            });
        },
    },
};
</script>

<style scoped>
.wrap {
    width: 100%;
    height: 100%;
}

</style>
