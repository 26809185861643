<template>
    <div class="pager-wrap" v-if="totalCount">
        <!-- <span class="u-text">共{{ totalPage }}页 / {{ totalCount }}条</span> -->
        <!-- <span class="u-item txt" :class="{ disabled: currentPage === 1 }" @click="changePage(1)">首页</span> -->
        <span class="pager-btn prev-btn iconfont icon-back" :class="{ disabled: currentPage == 1 }" @click="changePage(currentPage - 1)"></span>
        <span class="u-ellipsis" ref="forward" v-show="forwardMore" @click="onForward" @mouseenter="onEnterForward" @mouseleave="onLeaveForward">&middot;&middot;&middot;</span>
        <span :class="['u-item', { active: currentPage === num }]" v-for="num in pageList" :key="num" @click="changePage(num)">{{ num }}</span>
        <span class="u-ellipsis" ref="backward" v-show="backwardMore" @click="onBackward" @mouseenter="onEnterBackward" @mouseleave="onLeaveBackward">&middot;&middot;&middot;</span>
        <span class="pager-btn next-btn iconfont icon-back" :class="{ disabled: currentPage == totalPage }" @click="changePage(currentPage + 1)"></span>
        <!-- <span class="u-item txt" :class="{ disabled: currentPage === totalPage }" @click="changePage(totalPage)">尾页</span>
        <span class="u-jump-page">跳至<input type="text" v-model="jumpNumber" />页</span>
        <span class="u-item txt" @click="jumpPage(jumpNumber)">确定</span> -->
    </div>
</template>
<script>
export default {
    name: "Pager",
    data() {
        return {
            currentPage: this.pageNumber, // 当前页码
            currentSize: this.pageSize, // 分页数
            jumpNumber: "", // 跳转的页码
            forwardMore: false, // 左箭头展示
            backwardMore: false, // 右箭头展示
        };
    },
    props: {
        pageNumber: {
            // 当前页面
            type: Number,
            default: 1,
        },
        pageSize: {
            // 每页显示数量 [10条/页 20条/页 30条/页 40条/页]
            type: Number,
            default: 10,
        },
        totalCount: {
            // 总条数
            type: Number,
            default: 0,
        },
        pageListNum: {
            // 显示的页码数组长度
            type: Number,
            default: 10,
        },
    },
    computed: {
        totalPage() {
            // 总页数
            return Math.ceil(this.totalCount / this.currentSize); // 向上取整
        },
        pageList() {
            // 获取显示的页码数组
            return this.dealPageList(this.currentPage);
        },
    },
    watch: {
        currentPage(to, o) {
            if (o <= 0) return;
            // 通过更改当前页码，修改分页数据
            this.$emit("changePage", { page: to, currentSize: this.currentSize });
        },
        pageNumber(v) {
            this.currentPage = v;
        },
        currentSize(v) {
            this.currentSize = v;
        },
    },

    methods: {
        dealPageList(curPage) {
            var resList = [];
            var offset = Math.floor(this.pageListNum / 2); // 向下取整
            var pager = {
                start: curPage - offset,
                end: curPage + offset,
            };
            if (pager.start < 1) {
                pager.end = pager.end + (1 - pager.start);
                pager.start = 1;
            }
            if (pager.end > this.totalPage) {
                pager.start = pager.start - (pager.end - this.totalPage);
                pager.end = this.totalPage;
            }
            if (pager.start < 1) {
                pager.start = 1;
            }
            if (pager.start > 1) {
                this.forwardMore = true;
            } else {
                this.forwardMore = false;
            }
            if (pager.end < this.totalPage) {
                this.backwardMore = true;
            } else {
                this.backwardMore = false;
            }
            // 生成要显示的页码数组
            for (let i = pager.start; i <= pager.end; i++) {
                resList.push(i);
            }
            return resList;
        },
        onEnterForward() {
            this.$refs.forward.innerHTML = "&laquo;";
        },
        onLeaveForward() {
            this.$refs.forward.innerHTML = "&middot;&middot;&middot;";
        },
        onEnterBackward() {
            this.$refs.backward.innerHTML = "&raquo;";
        },
        onLeaveBackward() {
            this.$refs.backward.innerHTML = "&middot;&middot;&middot;";
        },
        onForward() {
            this.currentPage = this.currentPage - this.pageListNum > 0 ? this.currentPage - this.pageListNum : 1;
        },
        onBackward() {
            this.currentPage = this.currentPage + this.pageListNum < this.totalPage ? this.currentPage + this.pageListNum : this.totalPage;
        },
        jumpPage(jumpNum) {
            if (Number(jumpNum)) {
                if (Number(jumpNum) < 1) {
                    jumpNum = 1;
                }
                if (Number(jumpNum) > this.totalPage) {
                    jumpNum = this.totalPage;
                }
                this.changePage(Number(jumpNum));
            }
            this.jumpNumber = ""; // 清空跳转输入框
        },
        changePage(pageNum) {
            if (this.currentPage !== pageNum) {
                // 点击的页码不是当前页码
                this.currentPage = pageNum;
            }
        },
    },
};
</script>
<style scoped>
.pager-wrap .pager-btn:hover {
    background: #56a5db;
    color: #fff;
}
.pager-wrap .next-btn {
    transform: rotate(180deg);
}
.pager-wrap .u-item {
    margin: 0 10px;
    cursor: pointer;
    font-weight: normal;
}
.pager-wrap .active {
    color: #56a5db;
    font-weight: bold;
}
.pager-wrap .u-ellipsis {
    margin: 0 10px;
    width: 20px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
}
.pager-wrap .disabled {
    pointer-events: none;
    cursor: default;
}

@media only screen and (max-width: 2048px) {
    .pager-wrap {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
    }
    .pager-wrap .pager-btn {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #56a5db;
        font-weight: bold;
        margin: 0 10px;
    }
}

@media only screen and (min-width: 2049px) {
    .pager-wrap {
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
    }
    .pager-wrap .pager-btn {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #56a5db;
        font-weight: bold;
        margin: 0 10px;
    }
}
</style>
