<template>
    <div class="container marketing">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/service/marketing/p1_bg.jpg"> </Row>
        <Row height="950" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/service/marketing/pos2_banner.png" />
                <h1>AI+营销&nbsp;&nbsp;&nbsp;构建全链路智慧营销矩阵</h1>
                <p>浙文互联凭借数十年营销行业服务经验、数据积累，推进AI技术与业务实践深度融合，打造行业垂类应用模型，实现内容智能化生产，赋能数字营销、数字广告、社群营销、电商运营等业务板块，全面提升业务效能，构建全链路智慧营销矩阵，为客户提供跨平台、多场景的智慧营销解决方案，助力品牌价值与市场影响力的深度拓展。</p>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-service-marketing",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}

@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 100px;
        /* width: calc(700px * var(--scale)); */
        height: 200px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 100px 0 60px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: left;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 120px;
        /* width: 900px; */
        height: 250px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 200px 0 80px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: center;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
