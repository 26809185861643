<template>
    <div class="container">
        <Nav></Nav>
        <div style="width:100%; height:70px;"></div>
        <Row height="720" bg="assets/pc/service/innovation/p1_bg.jpg"> </Row>
        <Row height="1050" bgColor="#fff">
            <div class="pos2">
                <img src="@/assets/pc/service/innovation/pos2_banner.png" />
                <h1>多元业态布局&nbsp;&nbsp;&nbsp;开辟文化产业新篇章</h1>
                <p>浙文互联积极进行多元业务布局，拓展电子竞技、元宇宙虚拟资产、数字资产、AI教育等领域，开辟文化产业创新发展的新篇章。在文化教育领域，浙文互联入股中国大语文教育体系的开创企业——豆神教育，共同构建一个开放、共享、多元的教育生态，为中华文化在全球范围内的传播与发扬贡献力量。</p>
            </div>
        </Row>
        <Footer></Footer>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import Row from "@/components/pc/Row.vue";
export default {
    name: "pc-service-innovation",
    components: { Nav, Footer, Row },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>
<style scoped>
.pos2 {
    width: 100%;
    height: 100%;
    text-align: center;
}
.pos2 p {
    font-size: 1.8rem;
    line-height: 4.5rem;
}

@media only screen and (max-width: 2048px) {
    .pos2 img {
        margin-top: 100px;
        /* width: calc(1000px * var(--scale)); */
        height: 200px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 100px 0 60px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: left;
    }
}

@media only screen and (min-width: 2049px) {
    .pos2 img {
        margin-top: 120px;
        /* width: 1000px; */
        height: 250px;
    }
    .pos2 h1 {
        font-size: 2.5rem;
        margin: 200px 0 80px 0;
    }
    .pos2 p {
        width: 60%;
        font-size: 1.8rem;
        line-height: 4.5rem;
        margin: 0 auto;
        text-align: center;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
