<template>
    <div>
        <Header> </Header>
        <Row>
            <img src="@/assets/mobile/about/comp/ylmf.jpg" />
        </Row>
        <Row className="pos2">
            <img src="@/assets/mobile/about/comp/ylmf-content.png" />
        </Row>

        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/mobile/header.vue";
import Footer from "@/components/mobile/footer.vue";
import Row from "@/components/mobile/Row.vue";
// import MoreBtn from "../../components/mobile/MoreBtn.vue";

export default {
    name: "mobile-about-ylmf",
    components: { Header, Footer, Row },
    data() {
        return {};
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
};
</script>

<style scoped>
.pos2 img {
    width: 80%;
    margin: 70px 0;
}
</style>
